import React from "react";
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import {
    Button,
    DatePicker,
    Progress,
    Popconfirm,
    Table,
    Input,
    Icon,
    Select,
    Upload,
    Form,
    message
} from 'antd';
import moment from 'moment';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid } from "@utils/NHCore";
import { FormRules } from "@utils/RegExp";
import './index.css';
import NHFormItem from '@components/common/NHFormItem/index';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD ';
class JyjlForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jyjl: false,
            jyjlAdd: false,
            jyjlData: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('jyjlInitData' in nextProps && nextProps.jyjlInitData) {
            if (nextProps.jyjlInitData instanceof Array) {
                let temp = [...nextProps.jyjlInitData];
                this.setState({ jyjlData: temp });
            }
        }
    }

    cacheJyjlData = (cb) => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                message.info('请检查教育经历是否正确填写')
                cb(false)
                return;
            }
            //判断数据是否全部填写
            let errorPhone = "";
            let errorList = [];
            let dataList = [];
            if (this.state.jyjlData && this.state.jyjlData.length > 0) {
                for (let i = 0; i < this.state.jyjlData.length; i++) {
                    let data = this.state.jyjlData[i];
                    let pattern = /^1\d{10}$/;
                    if(data.zmrlxfs){
                        if(!pattern.test(data.zmrlxfs)){
                            if(errorList.length == 0){
                                errorPhone += data.zmrlxfs
                            }else{
                                errorPhone += "," + data.zmrlxfs;
                            }
                            errorList.push(data);
                        }
                    }
                    if (!data.qsny && !data.xxjl && !data.zw && !data.zmr && !data.zmrlxfs && !data.operation) {

                    } else {
                        dataList.push(data);
                    }
                }
            }
            if(errorList && errorList.length > 0){
                message.error(`${errorPhone}号码填写错误`);
                return ;
            }
            this.setState({
                jyjl: false,
                jyjlAdd: false
            });
            const { cacheFormData, getChildComponent } = this.props;
            getChildComponent(4, true);
            cacheFormData('jyjl', dataList, true);
            cb(true);
        });
    }

    validateFields = (func) => {
        let err = false;
        let formData = this.state.jyjlData;
        func(err, formData);
    }

    onjyjlAdd = () => {
        let { jyjlAdd, jyjlData } = this.state;
        if (!jyjlData) {
            jyjlData = [];
        }
        if (jyjlAdd) {
            jyjlData.push({
                key: createUuid(),
                qsny: '',
                xxjl: '',
                zw: '',
                zmr: '',
                zmrlxfs: '',
                operation: ''
            });
            this.setState({
                jyjlData
            });
        }
        this.setState({
            jyjlAdd: true
        });
    };

    onjyjlSave = () => {
        let { jyjlData } = this.state;
        jyjlData.push();
        this.setState({
            jyjlData: jyjlData,
            jyjlAdd: false
        });
    };

    onjyjlClose = (index) => {
        let { jyjlData } = this.state;
        jyjlData.splice(index, 1);
        this.setState({
            jyjlData: jyjlData,
            jyjlAdd: true
        });
    };

    handleChange = (value, index, key) => {
        let jyjlData = this.state.jyjlData;
        jyjlData[index][key] = value;
        this.setState({ jyjlData });
    }

    handleDateChange = (value, index) => {

        let jyjlData = this.state.jyjlData;
        let kssj = value[0] ? moment(value[0]).format(dateFormat) : undefined;
        let jssj = value[1] ? moment(value[1]).format(dateFormat) : undefined;
        jyjlData[index]['kssj'] = kssj;
        jyjlData[index]['jssj'] = jssj;
        this.setState({ jyjlData });
    }

    disabledDate(current) {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    }

    deepCopy = (obj) => {
        if (typeof obj != 'object') {
            return obj;
        }

        if (this.isArrayFn(obj)) {
            let newobj = [];
            for (let i = 0; i < obj.length; i++) {
                newobj.push(this.deepCopy(obj[i]));
            }
            return newobj;
        } else {
            let newobj = {};
            for (var attr in obj) {
                newobj[attr] = this.deepCopy(obj[attr]);
            }
            return newobj;
        }
    }


    isArrayFn = (value) => {
        if (typeof Array.isArray === "function") {
            return Array.isArray(value);
        } else {
            return Object.prototype.toString.call(value) === "[object Array]";
        }
    }

    /** 验证必填项 */
    validateCustom = () => {
        const { jtcy, formInitData } = this.state;
        if (jtcy) {
            // 若是编辑模式 使用表单验证
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return false
                }
                return true
            })
        } else {
            return true
        }
    }

    validateFields = (func) => {
        let err = false;
        let formData = this.state.data;
        func(err, formData);
    }

    render() {


        const { initJtcyData, getChildComponent } = this.props;
        const { jyjl, jyjlData, jyjlAdd } = this.state;
        const formStyle = {
            margin: 0
        }
        const { getFieldDecorator } = this.props.form;
        const jyjlColumns = [
            {
                title: '起始年月',
                dataIndex: 'qsny',
                render: (text, record, index) => {
                    let kssj = record.kssj;
                    let jssj = record.jssj;
                    return jyjl ? (
                        <RangePicker
                            // disabledDate={this.disabledDate}
                            value={(kssj && jssj) ? [moment(kssj, dateFormat), moment(jssj, dateFormat)] : undefined}
                            format={dateFormat}
                            onChange={(value) => { this.handleDateChange(value, index) }}
                        />
                    ) : (
                        (kssj ? kssj : '') + ' ~ ' + (jssj ? jssj : '')
                    );
                }
            },
            {
                title: '在何地何学校',
                dataIndex: 'xxjl',
                render: (text, record, index) => {
                    return jyjl ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'xxjl') }}
                            style={{ width: 140 }}
                            placeholder="请填写学校"
                        />
                    ) : (
                        text
                    );
                }
            },
            // {
            //     title: '任何职',
            //     dataIndex: 'zw',
            //     render: (text, record,index) => {
            //         return jyjl ? (
            //             <Input     
            //                 value={text}
            //                 onChange={(e) =>{this.handleChange(e.target.value,index,'zw')}}         
            //                 style={{ width: 100 }}
            //                 placeholder="请填写职位"
            //             />
            //         ) : (
            //             text
            //         );
            //     }
            // },
            {
                title: '证明人',
                dataIndex: 'zmr',
                render: (text, record, index) => {
                    return jyjl ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'zmr') }}
                            style={{ width: 100 }}
                            placeholder="请填写证明人"
                        />
                    ) : (
                        text
                    );
                }
            },
            // {
            //     title: '证明人联系电话',
            //     dataIndex: 'zmrlxfs',
            //     render: (text, record,index) => {
            //         return jyjl ? (
            //             <Input     
            //                 value={text}
            //                 onChange={(e) =>{this.handleChange(e.target.value,index,'zmrlxfs')}}         
            //                 style={{ width: 100 }}
            //                 placeholder="请填写电话"
            //             />
            //         ) : (
            //             text
            //         );
            //     }
            // }, 
            {
                title: '证明人联系电话',
                dataIndex: 'zmrlxfs',
                render: (text, record, index) => {
                    return jyjl ? (
                        // <Form.Item style={formStyle}>
                        //     {getFieldDecorator(`list.${index}.zmrlxfs`, {
                        //         initialValue: text,
                        //         rules: [
                        //             { required: true, message: '请输入联系电话' },
                        //             FormRules.MOBILE_PHONE_NUMBER
                        //         ]
                        //     })(
                                <Input
                                    value={text}
                                    maxLength={11}
                                    placeholder="请填写电话"
                                    onChange={(e) => { this.handleChange(e.target.value, index, 'zmrlxfs') }}
                                />
                        /*     )}
                        </Form.Item> */

                    ) : (
                        text
                    );
                }
            },
            {
                title: '删除',
                dataIndex: 'operation',
                width: 80,
                render: (text, record, index) => {
                    if (jyjl) {
                        return (
                            <div className="editable-row-operations">

                                <a
                                    href="javascript:;"
                                    onClick={() => this.onjyjlClose(index)}
                                >
                                    <Icon
                                        type="close"
                                        style={{ color: 'red' }}
                                    />
                                </a>
                            </div>
                        );
                    }
                    return (
                        <a href="#">
                            <Icon type="delete" style={{ color: 'red' }} />
                        </a>
                    );
                }
            }
        ];

        if (!jyjl) {
            jyjlColumns.pop();
        }
        return (
            <div>
                <TitleTop
                    title="教育经历信息"
                    onEdit={type => {
                        this.setState({
                            jyjl: true,
                            jyjlAdd: true,
                            cacheData: this.deepCopy(this.state.jyjlData)
                        });
                        getChildComponent(4, false);
                    }}
                    onSave={cb => {
                        this.cacheJyjlData(cb);
                    }}
                    onClose={() => {
                        this.setState({
                            jyjl: false,
                            jyjlAdd: false,
                            jyjlData: this.deepCopy(this.state.cacheData)
                        });
                    }}
                />
                <div className="p-sw">
                    <Table
                        bordered
                        size="middle"
                        pagination={false}
                        dataSource={jyjlData}
                        columns={jyjlColumns}
                    />
                    {jyjlAdd && (
                        <div
                            className="mt-10"
                            styleName="btn_add"
                            onClick={() => {

                                this.onjyjlAdd();
                            }}
                        >
                            添加教育经历
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default Form.create()(JyjlForm);