import React from 'react';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import CommonTip from "../common";
import { Flex } from '@components';
import NHFetch from "@utils/NHFetch";
import Hint from "@pages/SelfHelp/common/hint";
import { getLoginUser } from "@utils/NHCore";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import NextButton from '@pages/SelfHelp/common/nextIndex'




import createHistory from 'history/createHashHistory';



const history = createHistory();

export default class FinancialPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        mkbz: 'GD015_2',
        hjmc: '',
        data: [],
        loading: false,
        nextUrl: '',//左侧菜单加载完后传进来
        search: '',
        operable: true,//是否显示环节
        nonOperationalData: {},//不可操作的数据
        blztFlag: "0",
    };
  }

  componentDidMount() {
    this.queryQztjAndYlhj();
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    if (!xsid) {
        this.setState({
            nonOperationalData: { nologin: true },
            operable: false,
        });
        return;
    }
    let zzbdhjid = this.getQueryString('pkid');
    NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
        .then(res => {
            if (res && res.data) {
                let kfsj = res.data['kfsj'];
                let ylhj = res.data['ylhj'];
                let qzhj = res.data['qzhj'];
                let nohj = res.data['nohj']; 
                this.setState({ nonOperationalData: res.data });
                if (kfsj && kfsj['YXSJ'] != '1') {
                    this.setState({ operable: false });
                }else if (ylhj && ylhj.length > 0) {
                    this.setState({ operable: false });
                }else if (qzhj && qzhj.length > 0) {
                    this.setState({ operable: false });
                }else if (nohj) {
                    this.setState({ operable: false });
                }
            }
        });
  }

  setMenuList = (menuList) => {
    let nextCount = 0;
    let bz = this.state.mkbz;
    menuList.map((elem, index) => {
        if (elem.HJDM == bz) {
            let hjmc = elem.HJMC;
            let blztFlag = elem.BLZT;
            this.setState({ hjmc, blztFlag })
            nextCount = index + 1;
            return;
        }
    });
    let menu = menuList[nextCount];
    if (menu) {
        let hjdm = menu.HJDM;
        if (hjdm.indexOf('GD') == 0) {
            let gdUrl = mkbz[hjdm];
            this.setState({
                nextUrl: gdUrl,
                search: 'pkid=' + menu.PKID,
            })
        } else if (hjdm.indexOf('ZDY') == 0) {
            let zdyUrl = "/customLink";
            this.setState({
                nextUrl: zdyUrl,
                search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
            })
        }
    } else {
        this.setState({
            nextUrl: '',
            search: '',
        })
    }
}

getQueryString = (name) => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

render() {
    const { blztFlag } = this.state;
    return (
      <div>{this.state.operable ?
        <Flex className="w1180 mt-20" align="start">
            <SiderMenu
              ref={"siderMenu"}
              setMenuList={this.setMenuList}
              title="自助报到"
              /* active={3}*/
              mkbz={this.state.mkbz}
              menuType='自助报到'
            />
            <Flex.Item className="page_con_r" styleName="">
                <Breadcrumb
                  noLine
                  list={[
                      {name: '首页', url: '/index'},
                      {name: '自助报到', url: '/register'},
                      {name: this.state.hjmc}
                  ]}
                />
                <div className="box">
                    <Hint/>
                    {/* <TitleTop title={this.state.hjmc} extra={false}/> */}
                    {/* 下一步按钮 */}
                    {
                        blztFlag == "1" &&
                        <div className="p-sw text-center mt-30">
                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                        </div>
                    }
                </div>
            </Flex.Item>
        </Flex>
        :
        <CommonTip hjmc={"身份核验(吉首)"} key={'GD015_2'} mkbz='GD015_2' nonOperationalData={this.state.nonOperationalData}/>
      }
      </div>
    );
}


}  


