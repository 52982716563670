import React from 'react';
import {Flex} from '@components';
import {Form, Input, Button,message} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import NHSelect from "@components/common/NHSelect";
import {getLoginUser} from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";

const history = createHistory();


const basicUrl = "api/zhxg-yxxt/yxwz";
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16}
};

@Form.create()
export default class antiepidemic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD024_1',
            hjmc: '',
            editable: false,
            update: false,
            initData: {
                sfjnylbx:''
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            isHide: true,
        };
    }

    //渲染前获取信息
    componentDidMount() {
        this.checkIsZzbdHjLinked();
        this.queryQztjAndYlhj();
        this.queryInitData();
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.code === 200) {
                    if (res.data) {
                        this.setState({
                            editable:false
                        })
                    }else {
                        this.setState({
                            editable:true
                        })
                    }
                } else {
                    message.error("查询环节办理状态失败，请稍后再试");
                }
            });
    };

    //查询初始化数据
    queryInitData =() =>{
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        NHFetch('/medicalInsurance/queryInitData','GET',{xsid:xsid}).then(res =>{
            if (res && res.code === 200){
                if (res.data){
                    this.setState({
                        initData: res.data,
                    })
                }
            }else {
                message.error("查询初始化数据失败，请稍后再试")
            }
        });
    }


    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({nonOperationalData: res.data});
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({operable: false});
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({operable: false});
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({operable: false});
                    }

                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({hjmc: hjmc})
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }


    handleSubmit = e => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const { mkbz } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            values.xsid = xsid;
            values.hjdm = mkbz;
            NHFetch('/medicalInsurance/saveMedicalInsuranceInfo','POST',values).then(res =>{
                if (res && res.code === 200){
                    if (res.data === 1){
                        this.commonSaveLinkTransactionStatus();
                        message.success("保存成功");
                    } else {
                        message.error("保存失败，请联系管理员")
                    }
                }else {
                    message.error("网络异常，请稍后再试")
                }
            })
        });
    };

    //自动办理环节
    commonSaveLinkTransactionStatus = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : "";
        let zzbdhjid = this.getQueryString("pkid");
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
            res => {
                if (res) {
                    if (res.data === 1) {
                        this.setState({editable:false});
                        this.reloadMenu();
                    }
                } else {
                    message.error("办理环节失败，请稍后重试！");
                }
            }
        );
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {editable, initData} = this.state;
        return (
            <div>{this.state.operable ?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        setMenuList={this.setMenuList}
                        title="自助报到"
                        /* active={3}*/
                        mkbz={this.state.mkbz}
                        menuType='自助报到'
                    />
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                {name: '首页', url: '/index'},
                                {name: '自助报到', url: '/register'},
                                {name: this.state.hjmc}
                            ]}
                        />
                        <div className="box">
                            <Hint/>
                            <TitleTop title={this.state.hjmc} extra={false}/>
                            <Form onSubmit={this.handleSubmit}>
                                <div className="p-sw">
                                    <Flex align="start" wrap="wrap">

                                        <div className="form_item">
                                            <FormItem
                                                {...formItemLayout}
                                                label="是否缴纳医疗保险"
                                                labelAlign={'left'}
                                            >
                                                {getFieldDecorator('sfjnylbx', {
                                                    initialValue: initData.sfjnylbx ? initData.sfjnylbx : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                '请选择...'
                                                        }
                                                    ]
                                                })(
                                                    editable ?
                                                        <NHSelect dataSource={[{value: '1', label: '是'}, {value: '0', label: '否'}]}/>
                                                        : <span>{initData.sfjnylbx === '1' ? '是' : '否'}</span>
                                                )}
                                            </FormItem>
                                        </div>
                                    </Flex>
                                    <div className="text-center mt-50">
                                        {
                                            editable? <Button type="primary" htmlType="submit">保存</Button> : ''
                                        }
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search}/>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Flex.Item>
                </Flex>
                :
                <CommonTip hjmc={"是否缴纳医疗保险"} key={'GD024_1'} mkbz='GD024_1' nonOperationalData={this.state.nonOperationalData}/>
            }
            </div>
        );
    }
}
