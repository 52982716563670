import React from 'react';
import { Form, Row, Col, Input, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import NHFetch from "@utils/NHFetch";
import './index.css';
import NHSelect from "@components/common/NHSelect";
const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
};
@Form.create()
export default class MajorForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xsxxData: {},//学生信息,
            xyxxData: [], //学院下拉数据
            zyxxData: [], //专业下拉数据
            bjxxData: [], //班级下拉数据
        };
    }
    componentDidMount() {
        this.getXsxx();
        this.loadZyData();
    }

    componentWillReceiveProps(nextProps) {
        if ('zytzPkid' in nextProps && nextProps.zytzPkid !== this.props.zytzPkid) {
            const { getFieldValue, setFieldsValue, resetFields } = this.props.form;
            const { zytzPkid } = nextProps;
            if (zytzPkid) {
                NHFetch('/zytz/getZytzInfo', 'GET', { pkid: zytzPkid }).then(resData => {
                    if (resData && resData.data) {
                        const data = resData.data;
                        let bmid = data['tzhyxid'];
                        let zyid = data['tzhzyid'];
                        let ly = data['sqly'];
                        this.setState({ shyj: resData.data.shzt === '3' ? resData.data.shyj : null })
                        setFieldsValue({
                            bmids: bmid,
                            zyids: zyid,
                            ly: ly,
                        });
                    }
                });
            }
        }
    }

    getXsxx = () => {
        const xsid = this.props.xsid;
        NHFetch('/zytz/getXsxx', 'GET', { xsid: xsid }).then(resData => {
            if (resData && resData.data) {
                this.setState({ xsxxData: { ...this.state.xsxxData, ...resData.data } });
            }
        });
    };




    //专业信息选择回调
    handleZyxxChange = (value) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        const { zyxxData } = this.state;
        const bmids = zyxxData.filter((item) => item.value === value).map(item => item.BMID)
        if (bmids && bmids.length > 0) {
            setFieldsValue({ bmids: bmids[0] })
        } else {
            setFieldsValue({ bmids: [] });
        }
    }

    loadZyData = () => {
        let xsid = this.props.xsid
        NHFetch('/zzbd/zytzfb/AdjustableMajorInfo/get', 'GET', { xsid: xsid })
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        zyxxData: res.data
                    })
                }
            })
    }




    render() {
        const { getFieldDecorator } = this.props.form;
        const { xsxxData, zyxxData, shyj } = this.state;
        return (
            <Flex align="start">
                <div styleName="modal_l">
                    <Flex align="start">
                        <div styleName="modal_l_label">考生号：</div>
                        <Flex.Item>{xsxxData ? xsxxData['KSH'] : ''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">性别：</div>
                        <Flex.Item>{xsxxData ? xsxxData['XB'] : ''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">录取号：</div>
                        <Flex.Item>{xsxxData ? xsxxData['LQH'] : ''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">姓名：</div>
                        <Flex.Item>{xsxxData ? xsxxData['XM'] : ''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">身份证号：</div>
                        <Flex.Item>{xsxxData ? xsxxData['SFZJH'] : ''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">学号：</div>
                        <Flex.Item>{xsxxData ? xsxxData['XH'] : ''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">第一志愿：</div>
                        <Flex.Item>{this.props.xsxxData ? this.props.xsxxData['dyzymc'] : ''}</Flex.Item>
                    </Flex>
                </div>
                <Flex.Item styleName="modal_r">
                    <Row>
                        <Col styleName="text-label" span={6}>
                            学院：
                        </Col>
                        <Col styleName="text-name" span={18}>
                            {xsxxData ? xsxxData['BMMC'] : ''}
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col styleName="text-label" span={6}>
                            专业：
                        </Col>
                        <Col styleName="text-name" span={18}>
                            {xsxxData ? xsxxData['ZYMC'] : ''}
                        </Col>
                    </Row>
                    <Flex styleName="line">
                        <Flex.Item styleName="line_l" />
                        <div styleName="line_con">申请专业调整</div>
                        <Flex.Item styleName="line_r" />
                    </Flex>
                    {this.props.visible ? <Form>
                        <FormItem style={{ display: 'none' }}>
                            {getFieldDecorator('pkid', {
                                initialValue: this.props.zytzPkid,
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <div style={{ display: 'none' }}>
                            <FormItem {...formItemLayout} label="学院">
                                {getFieldDecorator('bmids', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择学院'
                                        }
                                    ]
                                })(
                                    <Input />
                                )}
                            </FormItem>
                        </div>
                        <FormItem {...formItemLayout} label="专业">
                            {getFieldDecorator('zyids', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择专业'
                                    }
                                ]
                            })(
                                <NHSelect mode="default" dataSource={zyxxData} onSelect={this.handleZyxxChange} />
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="申请理由">
                            {getFieldDecorator('ly')(
                                <TextArea
                                    autosize={{
                                        minRows: 4,
                                        maxRows: 4
                                    }}
                                />
                            )}
                        </FormItem>
                        {shyj && <FormItem {...formItemLayout} label="回退意见">
                            {shyj}
                        </FormItem>}
                    </Form> : <Empty description="您无法进行调整专业！" />}
                </Flex.Item>
            </Flex >
        )
    }

}