import React from 'react';
import { Flex } from '@components';
import { Form, Row, Col, Modal, Collapse, Icon, message, Button } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
//确认对话框
const confirm = Modal.confirm;
const Panel = Collapse.Panel;

import './index.css';
import NHFetch from "@utils/NHFetch";
import MajorForm from './form';
import { getLoginUser } from "@utils/NHCore";
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";

@Form.create()
export default class Major extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD007_2',
            hjmc: '',
            data: [],
            visible: true,
            formVisible: false,
            confirmLoading: false,
            xsid: '',
            zytzPkid: '',
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
        };
    }

    componentWillMount() {
        let xsid = getLoginUser().userId;
        this.setState({ xsid: xsid })
    }

    componentDidMount() {
        this.queryQztjAndYlhj();
        this.zytzList();
        this.getXszyxx()
    }
    getXszyxx = () => {
        let xsid = this.state.xsid
        NHFetch('/zzbd/zytzfb/majorInfo/get', 'GET', { xsid: xsid }).then(resData => {
            if (resData && resData.data) {
                this.setState({
                    xsxxData: { ...this.state.xsxxData, ...resData.data },
                    formVisible: resData.data.dyzyid !== resData.data.lqzyid && resData.data.limit
                });
            }
        });
    };




    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    zytzList = () => {
        let params = {
            xsid: this.state.xsid
        }
        NHFetch('zzbd/zytzfb/queryMajorApplyInfo', "GET", params)
            .then(res => {
                // debugger
                if (res && res.data) {
                    const zytzPkid = res.data.find(item => item.ZT === '3') ? res.data.find(item => item.ZT === '3').PKID : ''
                    this.setState({
                        data: res.data.filter(item => item.ZT !== "3"),
                        zytzPkid: zytzPkid
                    });
                    if (res.data.length === 0 || res.data.findIndex(item => item.ZT === '3') !== -1) {
                        this.setState({ visible: true });
                    } else {
                        this.setState({ visible: false });
                    }
                }
            })
    }

    deleteTip = (pkid) => {
        confirm({
            title: '提示',
            content: '是否确定删除该条申请数据',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk: () => {
                this.delete(pkid);
            },
            onCancel() {

            },
        });
    }

    delete = (pkid) => {
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch('/zytz/deleteById', "POST", { pkid: pkid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data == 1) {
                    message.success('删除成功.');
                    this.reloadMenu();
                    this.setState({ visible: true, formVisible: true });
                    this.zytzList();//重新加载
                } else {
                    message.warn('删除异常');
                }
                this.zytzList();//重新查询
            }).catch(err => {
                message.warn('删除异常');
            });
    }

    showModal = () => {
        const zytzPkid = this.state.zytzPkid;
        if (zytzPkid) {
            this.setState({
                visible: true
            });
        } else {
            const xsid = this.state.xsid;
            NHFetch('/zytz/getZytzCountByZt', "GET", { xsid: xsid })
                .then(res => {
                    if (res && res.data > 0) {
                        message.warn("已存在专业调整申请状态未完成，待完成后再申请.");
                    } else {
                        this.setState({
                            visible: true
                        });
                    }
                });
        }
    };

    query = () => {

    }
    handleCancel = () => {
        this.setState({
            visible: false
        });
    };
    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            confirmLoading: true
        });
        let zzbdhjid = this.getQueryString('pkid');
        this.form.validateFields((err, values) => {
            if (err) {
                this.setState({
                    confirmLoading: false
                });
                return false;
            }

            let bmid = values['bmids'];
            let zyid = values['zyids'];
            let pkid = values['pkid'];
            let bjid = '';
            if (values['bjids'] && values['bjids'].length > 0) {
                bjid = values['bjids'];
            }

            let sqly = values['ly'];

            let params = {
                zzbdhjid: zzbdhjid,
                zytzDTO: {

                    tzhyxid: bmid,
                    tzhzyid: zyid,
                    tzhbjid: bjid,
                    sqly: sqly,
                    pkid: pkid,
                }
            }
            if (pkid) {
                NHFetch('/zytz/update', "POST", params)
                    .then(res => {
                        if (res && res.data && res.data == 1) {
                            message.success("修改成功！");
                            this.reloadMenu();
                            this.setState({ visible: false });
                            this.zytzList();//重新加载
                        } else {
                            message.warn("修改异常！");
                            this.setState({ confirmLoading: false });
                        }
                        this.setState({ confirmLoading: false });
                    }).catch((res) => {
                        this.setState({ confirmLoading: false });
                        message.warn("修改异常！");
                        return res;
                    });
            } else {
                NHFetch('/zytz/insert', "POST", params)
                    .then(res => {
                        if (res && res.data && res.data == 1) {
                            message.success("保存成功！");
                            this.reloadMenu();
                            this.setState({ visible: false });
                            this.zytzList();//重新加载
                        } else {
                            message.warn("保存异常！");
                            this.setState({ confirmLoading: false });
                        }
                        this.setState({ confirmLoading: false });
                    }).catch((res) => {
                        this.setState({ confirmLoading: false });
                        message.warn("保存异常！");
                        return res;
                    });
            }
        });
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const { visible, formVisible } = this.state;
        return (
            <div>{this.state.operable ?
                <div>
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            title="自助报到"
                            setMenuList={this.setMenuList}
                            /*      active={6}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到' />
                        <Flex.Item className="page_con_r" styleName="">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop
                                    title={this.state.hjmc}
                                    extra={false}
                                />
                                {visible && <MajorForm xsid={this.state.xsid} zytzPkid={this.state.zytzPkid} ref={(form) => { this.form = form }} xsxxData={this.state.xsxxData} visible={this.state.formVisible} />}
                                {this.state.data.length> 0 && <div className="p-sw">
                                    <Collapse accordion defaultActiveKey={['0']}>
                                        {
                                            this.state.data.map((elem, index) => {
                                                // let tzqxx = elem.TZQYXMC + " - " + elem.TZQZYMC + " - " + elem.TZQBJMC;
                                                let tzqxx;
                                                if (elem.TZQBJMC != undefined) {
                                                    tzqxx = elem.TZQYXMC + " - " + elem.TZQZYMC + " - " + elem.TZQBJMC;
                                                } else {
                                                    tzqxx = elem.TZQYXMC + " - " + elem.TZQZYMC;
                                                }
                                                let tzhxx = `${elem.TZHYXMC}${elem.TZHZYMC ? ` - ${elem.TZHZYMC}` : ``}${elem.TZHBJMC ? ` - ${elem.TZHBJMC}` : ``}`;
                                                return (
                                                    <Panel
                                                        header={
                                                            <Flex>
                                                                <Flex.Item>
                                                                    申请人：<span className="text-blue">
                                                                        {elem['XM']}
                                                                    </span>
                                                                </Flex.Item>
                                                                <Flex.Item>性别：{elem['XB']}</Flex.Item>
                                                                {elem['ZT'] == '0' ?
                                                                    <Flex.Item className="text-right">
                                                                        <Icon
                                                                            style={{
                                                                                color: '#0273fd'
                                                                            }}
                                                                            type="edit"
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                this.setState({ zytzPkid: elem['PKID'] }, () => {
                                                                                    this.showModal();
                                                                                });
                                                                            }}
                                                                        />
                                                                        <Icon
                                                                            className="ml-10 mr-10"
                                                                            type="delete"
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                this.deleteTip(elem['PKID']);
                                                                            }}
                                                                        />
                                                                    </Flex.Item> :
                                                                    <Flex.Item className="text-right">
                                                                    </Flex.Item>
                                                                }
                                                            </Flex>
                                                        }
                                                        key={index}
                                                    >
                                                        <Row>
                                                            <Col
                                                                styleName="coll_label"
                                                                span={4}
                                                            >
                                                                调整前：
                                                            </Col>
                                                            <Col span={20}>{tzqxx}</Col>
                                                        </Row>
                                                        <Row className="mt-10">
                                                            <Col
                                                                styleName="coll_label"
                                                                span={4}
                                                            >
                                                                调整后：
                                                            </Col>
                                                            <Col span={20}>{tzhxx}</Col>
                                                        </Row>
                                                        <Row className="mt-10">
                                                            <Col
                                                                styleName="coll_label"
                                                                span={4}
                                                            >
                                                                调整理由：
                                                            </Col>
                                                            <Col span={20}>
                                                                {elem['SQLY']}
                                                            </Col>
                                                        </Row>
                                                        <Flex
                                                            justify="between"
                                                            styleName="coll_b"
                                                        >
                                                            <div>
                                                                申请状态：<span className="text-primary">
                                                                    {elem['SHZT']}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                申请时间：<span className="text-sub">
                                                                    {elem['SQSJ']}
                                                                </span>
                                                            </div>
                                                        </Flex>
                                                    </Panel>
                                                );
                                            })
                                        }
                                    </Collapse>
                                </div>}
                                <div className="text-center mt-50">
                                    {(visible && formVisible) && <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                                        保存
                                    </Button>}
                                    <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                </div> : <CommonTip hjmc={"专业调整"} key={'GD007_2'} mkbz='GD007_2' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        );
    }
}
