import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, Table, Icon, InputNumber, Pagination, message, Modal } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import './index.css';
import NHFetch from "@utils/NHFetch";
import { getLoginUser } from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import QrCode from './qrCode';
const history = createHistory();
export default class jfPayFees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_2',
            hjmc: '',
            data: [],
            loading: false,
            pageData: {//分页设置的是三个参数
                total: 0,
                pageSize: 10,//当前页默认多少条数据
                page: 1
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            visible: false,
            flage: true,                        //是否存在需要缴费的项目
            jffaData: {},       //缴费方案信息
            jccsData: {},      //基础参数信息
            qrCode:'',   //二维码链接
        };
    }

    componentDidMount() {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid){
            message.info("未获取登录信息");
            return;
        }
        this.queryPayPlan();
        this.checkIsZzbdHjLinked();
        this.queryQztjAndYlhj((operable) => {
            if (operable) {

            }
        });
    }

    //根据环节代码获取对应的缴费方案数据
    queryPayPlan =()=>{
        const { mkbz } = this.state;
        NHFetch('/jfxt/queryPayPlan','GET',{hjdm:mkbz}).then(res =>{
            if(res && res.data){
                this.setState({
                    jffaData: res.data['jffaVO'],
                    jccsData:res.data['jfdjJccsVO']
                })
            }else {
                message.error("该环节未设置缴费方案")
            }
        })
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.code == 200) {
                    if (!res.data) {
                        this.queryOrderInfo();
                    }
                } else {
                    message.error("检查环节办理接口调用失败，请稍后再试")
                }
            });
    };

    //同步学生缴费情况信息
    queryOrderInfo =()=>{
        const { mkbz } = this.state;
        NHFetch('/jfxt/queryOrderInfo','GET',{hjdm:mkbz}).then(res =>{
            if (res && res.code == 200){
                if (res.date == 1){
                    this.commonSaveLinkTransactionStatus();
                }
            }
        })
    }

    //自动办理缴费环节
    commonSaveLinkTransactionStatus = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
            res => {
                if (res && res.code == 200) {
                    if (res.data === 1) {

                    }
                } else {
                    message.error("自动办理缴费环节失败，请稍后重试！");
                }
            }
        );
    };

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }else if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }else if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }else{
                        this.getYnjtFeeDetailList();
                    }if (callback) {
                        callback(this.state.operable);
                    }
                }
            });
    }

    getYnjtFeeDetailList = () => {
        NHFetch('/jfxt/getYnjtFeeDetailList', 'GET')//所有的收费数据
            .then(resData => {
                if (resData && resData.data) {
                    this.setState({ data: resData.data }, () => {
                        let list = this.state.data.filter((item) => item.QFJE > 0 ? true : false);
                        if(resData.data.length===0){
                            this.setState({
                                flage: false
                            })
                        }else{
                            if (list.length <= 0) {
                                this.setState({ flage: false })
                            }
                        }

                    });
                }
            });
    }

    //网上支付点击事件
    showModal = () => {
        //判断是否有移动的订单存在，如果是，则需要先将移动端订单关闭，再创建pc端订单
        NHFetch('/jfxt/checkJfddType','GET',{payChn:"0702"}).then(res =>{
            if (res && res.code == 200){
                if (res.data == 1){
                    this.handleCreateOrder();
                }
                if (res.data == 0){
                    message.error("已存在移动端微信支付订单，无法创建PC端统一支付订单")
                }
                if (res.data == 2){
                    message.info("订单已支付，请勿重复创建订单")
                }
            }else {
                message.error("检查是否存在订单接口异常，请稍后再试");
            }
        })
    };

    //创建订单
    handleCreateOrder =()=> {
        const {djfsid} = this.state.jffaData;
        let params = {
            djfsid:djfsid,
            payChn:"0702"
        }
        NHFetch('/jfxt/createOrderForPC', 'POST',params).then(res => {
            if (res && res.code == 200) {
                if (!res.date) {
                    this.setState({
                        visible: true,
                        qrCode:res.date?res.date.qrCode :''
                    });
                } else {
                    message.error("创建订单失败，请稍后再试")
                }
            }
        })
    };

    //Modal关闭事件
    onHandleCancel = () => {
        this.setState({
            visible: false,
        }, () => {
            this.getYnjtFeeDetailList();
        });
    };

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    onSave = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const {  data,jccsData } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'XH'
            },
            {
                title: '收费项目',
                dataIndex: 'SFXMMC',
            },
            {
                title: '应收金额（元）',
                dataIndex: 'YSJE',
            },
            {
                title: '实收金额（元）',
                dataIndex: 'SSJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '欠费金额（元）',
                dataIndex: 'QFJE',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            }
        ];



        return (
            <div styleName="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            /*active={7}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw mt-20">
                                    <Table
                                        bordered
                                        size="middle"
                                        pagination={false}
                                        columns={columns}
                                        dataSource={data}
                                    />
                                    <div className="text-center mt-50">
                                        <div className="text-center mt-50">
                                            {this.state.flage == true && jccsData.zfd != '0' ?
                                                <Button onClick={() => this.showModal()} type="primary" htmlType="submit">网上支付</Button> : null}
                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"网上缴费"} key={'GD008_2'} mkbz='GD008_2' nonOperationalData={this.state.nonOperationalData} />}
                <Modal
                    title="二维码信息"
                    visible={this.state.visible}
                    onCancel={this.onHandleCancel}
                >
                    <QrCode qrCode={this.state.qrCode}/>
                </Modal>
            </div>
        );
    }
}
