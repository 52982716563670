import React from 'react'
import { Flex } from '@components'
import { message } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import NHFetch from '@utils/NHFetch'
import { getLoginUser } from '@utils/NHCore'
import './index.css'
import JbxxForm from './JbxxForm'
import JtcyForm from './JtcyForm'
import JyjlForm from './JyjlForm'
import KzxxForm from './KzxxForm'
import createHistory from 'history/createHashHistory'
import CommonTip from '../common'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
const history = createHistory()

export default class InfoGdnhgs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mkbz: 'GD001_5',
      hjmc: '',
      jbxxFormData: {},
      formInitData: {},
      jbxxInitData: {},
      jtcyInitData: [],
      zyshgxInitData: [],
      jyjlInitData: [],
      kzxxInitData: {},
      userBaseInfo: {},
      stuSsxx: {},
      xsid: '',
      jbxxFlag: true,
      kzxxFlag: true,
      jtcyFlag: true,
      zyshgxFlag: true,
      jyjlFlag: true,
      nextUrl: '', //左侧菜单加载完后传进来
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      isEditJbxx: false,
      isEditJtcy: false,
      isEditZyshgx: false,
      isEditJyjl: false,
      menuList: [],
      zzbdhjid: '',
    }
  }

  componentDidMount() {
    this.queryQztjAndYlhj()
    //this.getYlhjData();
    this.getData4Yxwz()
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false,
      })
      return
    }
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', {
      xsid: xsid,
      zzbdhjid: zzbdhjid,
    }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        this.setState({
          nonOperationalData: res.data,
          zzbdhjid: zzbdhjid,
        })
        if (kfsj && kfsj['YXSJ'] != '1') {
          this.setState({ operable: false })
        }

        if (ylhj && ylhj.length > 0) {
          this.setState({ operable: false })
        }

        if (qzhj && qzhj.length > 0) {
          this.setState({ operable: false })
        }

        if (nohj) {
          this.setState({ operable: false });
      }
      }
    })
  }

  getQueryString = name => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
          location.href
        ) || [, ''])[1].replace(/\+/g, '%20')
      ) || null
    )
  }

  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID,
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: '',
      })
    }
  }

  getChildComponent = (flag, mode) => {
    switch (flag) {
      case 1:
        this.setState({ jbxxFlag: mode })
        break
      case 2:
        this.setState({ kzxxFlag: mode })
        break
      case 3:
        this.setState({ jtcyFlag: mode })
        break
      case 4:
        this.setState({ zyshgxFlag: mode })
        break
      case 5:
        this.setState({ jyjlFlag: mode })
        break
      default:
        break
    }
  }

  //缓存编写的信息，缓存完之后提交
  cacheFormData = (type, values, isAdd) => {
    //const{jbxxInitData} = this.state;
    if (type == 'jbxx') {
      const { sjhm, jtszdqArray, jtjddz } = values
      if (
        sjhm !== null &&
        jtszdqArray !== null &&
        jtszdqArray.length > 0 &&
        jtjddz !== null
      ) {
        this.setState(
          {
            jbxxInitData: { ...values },
            isEditJbxx: true,
          },
          () => {
            this.insertData4Yxwz()
          }
        )
      } else {
        message.error('存在必填信息未填写，请填写后再保存！')
      }
    }
    if (type == 'jtcy' && isAdd) {
      if (!values || values.length === 0)
        return message.error('家庭成员信息不能为空！')
      this.setState(
        {
          jtcyInitData: values,
          isEditJtcy: true,
        },
        () => {
          this.insertData4Yxwz()
        }
      )
      //   const { zyshgxInitData } = this.state
      //   if (zyshgxInitData !== null && zyshgxInitData.length > 0) {
      //     this.setState(
      //       {
      //         jtcyInitData: values,
      //         isEditJtcy: true,
      //       },
      //       () => {
      //         this.insertData4Yxwz()
      //       }
      //     )
      //   } else if (values !== null && values.length > 0) {
      //     this.setState(
      //       {
      //         jtcyInitData: values,
      //         isEditJtcy: true,
      //       },
      //       () => {
      //         this.insertData4Yxwz()
      //       }
      //     )
      //   } else {
      //     message.error('家庭成员信息和主要社会关系至少要填一项！')
      //   }
      // }
      // if (type == 'zyshgx' && isAdd) {
      //   const { jtcyInitData } = this.state
      //   if (jtcyInitData !== null && jtcyInitData.length > 0) {
      //     this.setState(
      //       {
      //         zyshgxInitData: values,
      //         isEditZyshgx: true,
      //       },
      //       () => {
      //         this.insertData4Yxwz()
      //       }
      //     )
      //   } else if (values !== null && values.length > 0) {
      //     this.setState(
      //       {
      //         zyshgxInitData: values,
      //         isEditZyshgx: true,
      //       },
      //       () => {
      //         this.insertData4Yxwz()
      //       }
      //     )
      //   } else {
      //     message.error('家庭成员信息和主要社会关系至少要填一项！')
      //   }
    }
    if (type == 'jyjl') {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            jyjlInitData: values,
            isEditJyjl: true,
          },
          () => {
            this.insertData4Yxwz()
          }
        )
      } else {
        message.error('教育经历信息不能为空！')
      }
    }
  }

  getData4Yxwz = () => {
    let userId = getLoginUser() ? getLoginUser().userId : undefined
    if (userId) {
      this.setState({ xsid: userId })
      NHFetch('/newStudent/queryStuInfo', 'GET', { xsid: userId }).then(res => {
        if (res && res.data) {
          let stuInfo = res.data.stuInfo
          let stuXxcj = res.data.stuXxcj
          let stuSsxx = res.data.stuSsxx
          let editXszp = res.data.editXszp

          this.setState({
            userBaseInfo: stuInfo.userBaseInfo,
            jbxxInitData: stuInfo.xsJbxx4YxwzVO,
            jtcyInitData: stuInfo.jtcyVO,
            zyshgxInitData: stuInfo.zyshgxVO,
            jyjlInitData: stuInfo.jyjlVO,
            kzxxInitData: { ...stuXxcj },
            stuSsxx: stuSsxx,
            editXszp: editXszp,
          })
        }
      })
    } else {
      message.error('登录超时,请重新登录！')
    }
  }

  insertData4Yxwz = () => {
    let postFormData = {}
    const {
      jbxxInitData,
      jtcyInitData,
      zyshgxInitData,
      jyjlInitData,
      xsid,
      zzbdhjid,
    } = this.state
    postFormData['xsid'] = xsid
    postFormData['xsJbxx4YxwzVO'] = jbxxInitData
    postFormData['jtcyVO'] = jtcyInitData
    postFormData['zyshgxVO'] = zyshgxInitData
    postFormData['jyjlVO'] = jyjlInitData

    let postData = {
      xsInfo4YxwzVO: postFormData,
      params: { zzbdhjid: zzbdhjid, xsid: xsid },
    }

    //必填一
    const { sjhm, jtszdqArray, jtjddz } = postData.xsInfo4YxwzVO.xsJbxx4YxwzVO
    let jbxxFlag = false
    if (
      sjhm !== null &&
      jtszdqArray !== null &&
      jtszdqArray.length > 0 &&
      jtjddz !== null
    ) {
      jbxxFlag = true
    }
    //必填项二：两个填一个
    const { jtcyVO, zyshgxVO, jyjlVO } = postData.xsInfo4YxwzVO
    let jtxxFlag = false
    if (
      (jtcyVO !== null && jtcyVO.length > 0) ||
      (zyshgxVO !== null && zyshgxVO.length > 0)
    ) {
      jtxxFlag = true
    }
    //必填三
    let jyxxFlag = false
    if (jyjlVO !== null && jyjlVO.length > 0) {
      jyxxFlag = true
    }
    let hjblFlag = false
    if (jbxxFlag && jtxxFlag && jyxxFlag) {
      hjblFlag = true
    }
    postData.params.hjblFlag = hjblFlag
    NHFetch('/newStudent/updateStuInfo', 'POST', postData).then(res => {
      if (res && res.code == 200) {
        message.success('保存成功')
        this.reloadMenu()
      } else {
        message.error('保存失败')
      }
    })
  }

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList()
  }

  render() {
    const { xsid, jtcyInitData, zyshgxInitData } = this.state

    return (
      <div>
        {this.state.operable ? (
          <Flex className='w1180 mt-20' align='start'>
            <SiderMenu
              ref={'siderMenu'}
              setMenuList={this.setMenuList}
              title='自助报到'
              /*   active={0}*/
              mkbz={this.state.mkbz}
              menuType='自助报到'
            />
            <Flex.Item className='page_con_r'>
              <Breadcrumb
                noLine
                list={[
                  { name: '首页', url: '/index' },
                  { name: '自助报到', url: '/register' },
                  { name: this.state.hjmc },
                ]}
              />
              <div className='box'>
                <Hint />
                <JbxxForm
                  ref='refJbxxForm'
                  jbxxFormData={this.state.jbxxInitData}
                  getChildComponent={this.getChildComponent}
                  cacheFormData={this.cacheFormData}
                  userInfoData={this.state.userBaseInfo}
                  stuSsxx={this.state.stuSsxx}
                  editXszp={this.state.editXszp}
                />
                <KzxxForm
                  ref='refKzxxForm'
                  getChildComponent={this.getChildComponent}
                  reloadMenu={this.reloadMenu}
                  xsid={this.state.xsid}
                  zzbdhjid={this.state.zzbdhjid}
                  initData={this.state.kzxxInitData}
                />
                <JtcyForm
                  ref='refJtcyForm'
                  jtcyInitData={this.state.jtcyInitData}
                  getChildComponent={this.getChildComponent}
                  cacheFormData={this.cacheFormData}
                />
                {/* <ZyshgxForm ref="refZyshgxForm" zyshgxInitData={this.state.zyshgxInitData}
                                getChildComponent={this.getChildComponent}
                                cacheFormData={this.cacheFormData} /> */}
                <JyjlForm
                  ref='refJyjlForm'
                  jyjlInitData={this.state.jyjlInitData}
                  getChildComponent={this.getChildComponent}
                  cacheFormData={this.cacheFormData}
                />

                <div className='p-sw text-center mt-30'>
                  {/* <Button type="primary"  onClick={this.insertData4Yxwz}>保存</Button> */}
                  <NextButton
                    nextUrl={this.state.nextUrl}
                    search={this.state.search}
                  />
                </div>
              </div>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip
            hjmc={'个人信息填写'}
            key={'GD001_5'}
            mkbz='GD001_5'
            nonOperationalData={this.state.nonOperationalData}
          />
        )}
      </div>
    )
  }
}
