import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import {
    Button,
    DatePicker,
    Progress,
    Popconfirm,
    Table,
    Input,
    Icon,
    Card,
    Row,
    Col,
    Select,
    Upload,
    message
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import {getLoginUser,createUuid} from "@utils/NHCore";
import  './index.css';
import NHFetch from "../../../../utils/NHFetch";
import Common from "./common";
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const history = createHistory();
const { RangePicker } = DatePicker;
const Option = Select.Option;
export default class CustomLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xsid: '',//学生id
            xsData:{},//学生信息
            hjywxData:[],//环节业务项
            hjblztData:{},//环节办理状态
            nextUrl:'',//左侧菜单加载完后传进来
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            mkbz:'',
            hjData:{},
            menuList:[],
            ssxxDisplay:false,//宿舍信息是否显示(true显示，false不显示)
            refresh: 0, // 是否更换了pkid，由于自定义环节都是通过push /costomLink，组件不会重新加载
        };
    }

    componentWillMount(){
        let userId = getLoginUser() ? getLoginUser().userId : '';
        let mkbz = this.getQueryString('mkbz');
        let lx =  this.getQueryString('lx');
        this.setState({
            mkbz:mkbz,
            xsid:userId,
            lx:lx,
        });
    }

    componentDidMount(){
        this.getSsxxDisplay();
        let lx = this.state.lx;
        let mkbz = this.getQueryString('mkbz');
        this.queryQztjAndYlhj();
        if(lx&&lx==0){
            this.getHjData(mkbz);
        }else{
            this.getHjInfo();
        }

        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({ 
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        }  

        window.addEventListener('hashchange',this.handleUrlChange)
    }

    handleUrlChange = () => {
        console.log('hasChange')
        this.setState({
            refresh: this.state.refresh === 1 ? 0 : 1
        })
    }
    componentWillUnmount() {
        window.removeEventListener('hashchange',this.handleUrlChange)
    }

     // 获取学生头像
     getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }

    shouldComponentUpdate(nextProps,nextState){
        let mkbz = this.getQueryString('mkbz');
        let lx = this.getQueryString('lx');
        if(lx&&lx==0){//自定义环节，没关联环节
            if(this.state.mkbz != mkbz){
                this.setState({
                    mkbz:mkbz,
                    lx:lx,
                },()=>{
                    this.setNext(this.state.menuList);
                    this.getHjData(mkbz);
                    this.queryQztjAndYlhj();
                });
            }
        }else{
            if(JSON.stringify(this.state.xsData)=='{}' && JSON.stringify(nextState.xsData)!='{}'){
                let hjid = this.getQueryString('pkid');
                let lchjid = nextState.xsData['LCID'];
                let xsid = this.state.xsid;
                this.getHjblxx(hjid,lchjid,xsid);
            }
            if(this.state.mkbz != mkbz){
                this.setState({
                    mkbz:mkbz,
                    lx:lx,
                },()=>{
                    this.setNext(this.state.menuList);
                    let hjid = this.getQueryString('pkid');
                    let lchjid = nextState.xsData['LCID'];
                    let xsid = this.state.xsid;
                    this.getHjblxx(hjid,lchjid,xsid);
                    this.queryQztjAndYlhj();
                });
            }
        }
        return true;
    }

    //获取是否展示宿舍信息参数设置
        getSsxxDisplay = () => {
            NHFetch('/zzbdCommon/getCssz', 'GET', { csbz: 'YXWZ_ZZBD_GRXXYY_SFZSZSXX' }).then(res => {
                if (res ) {
                    if(res.code==200){
                        this.setState({
                            ssxxDisplay:res.data == "false" ? false : true
                        })
                    }else{
                        message.error("获取是否展示宿舍信息信息参数失败！");
                     }
                }else{
                   message.error("获取是否展示宿舍信息信息参数失败！");
                }
            })
        }
    

    reload=()=>{
        let mkbz = this.getQueryString('mkbz');
        if(this.state.mkbz != mkbz){
            this.setState({
                mkbz:mkbz,
            },()=>{
                let hjid = this.getQueryString('pkid');
                let lchjid = this.state.xsData['LCID'];
                let xsid = this.state.xsid;
                this.getHjblxx(hjid,lchjid,xsid);
                this.queryQztjAndYlhj();
                this.setNext(this.state.menuList);
                this.getSsxxDisplay();
            });
        }
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=()=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData:res.data});

                    if(kfsj && kfsj['YXSJ']!='1'){
                        this.setState({operable:false});
                    }else if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }else if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }else if (nohj) {
                        this.setState({ operable: false });
                    }else{
                        this.setState({operable:true});
                    }
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }



    setMenuList=(menuList)=>{
        this.setState({menuList:menuList});
        this.setNext(menuList);
    }

    setNext=(menuList)=>{
        if(!menuList || menuList.length==0){
            return;
        }
        let nextCount = 0;
        let bz = this.getQueryString("mkbz");
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                });
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = '/customLink';
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                });
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }



    getHjInfo=()=>{
        let userId = getLoginUser() ? getLoginUser().userId : '';
        NHFetch('/zdybl/getXsxxAndLcidByXsid', 'GET', {xsid:userId})
            .then(res => {
                if (res) {
                    let data = res.data;
                    if (data) {
                        this.setState({xsData: data},()=>{
                            this.getGyxx();
                        });
                    } else {
                        this.setState({xsData: {}});
                    }
                }
            });
    }

    getHjData=(mkbz)=>{
        NHFetch('/zzbd/getHjData', 'GET', {hjdm:mkbz})
            .then(res => {
                if (res) {
                    let data = res.data;
                    if (data) {
                        this.setState({hjData: data});
                    } else {
                        this.setState({hjData: {}});
                    }
                }
            });
    }


    getGyxx=()=>{
        NHFetch('/gyxtUnauth/cwfp/getXsssrzYwxxByXsid', 'GET')
            .then(res => {
                if (res) {
                    let data = res.data;
                    if (data) {
                        let xqmc = data['xqmc'];
                        let ldmc = data['ldmc'];
                        let fj = data['fj'];
                       /* let ysje = data['ysje'];*/
                        let cwh = data['cwh'];
                        let ssxx = '';
                        if(xqmc){
                            ssxx = xqmc+"-";
                        }
                        if(ldmc){
                            ssxx +=ldmc+"-";
                        }
                        if(fj){
                            ssxx +=fj+"-";
                        }
                        if(cwh){
                            ssxx +=cwh+"号床";
                        }

                        let xsData = this.state.xsData;
                        xsData['SSXX']=ssxx;
                        this.setState({xsData:xsData});
                    }
                }
            });
    }

    //获取环节办理信息
    getHjblxx = (hjid, lchjid,xsid) => {
        let hjblxxFilter = {};
        hjblxxFilter.xsid = xsid;
        hjblxxFilter.hjid = hjid;
        hjblxxFilter.lchjid = lchjid;
        this.getHjblztData(hjblxxFilter);
        this.getHjywxData(hjblxxFilter);
    }

    //获取环节办理状态信息
    getHjblztData = (params) => {
        NHFetch('/zdybl/getZzbdHjblztByFilter', 'GET', params)
            .then(res => {
                if (res) {
                    let data = res.data;
                    if (data) {
                        this.setState({hjblztData: data});
                    } else {
                        this.setState({hjblztData: {}});
                    }
                }
            });
    }

    //获取环节业务项信息
    getHjywxData = (params) => {
        NHFetch('/zdybl/getHjywxByFilter', 'GET', params)
            .then(res => {
                if (res) {
                    let data = res.data;
                    if (data) {
                        this.setState({hjywxData: data});
                    } else {
                        this.setState({hjywxData: []});
                    }
                }
            });
    }

    finish=()=>{
        let hjid = this.getQueryString('pkid');
        let lchjid = this.state.xsData['LCID'];
        let xsid = this.state.xsid;
        this.reloadMenu();
        this.getHjblxx(hjid,lchjid,xsid);
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    getBlzt=()=>{
        let xsid = this.state.xsid;
        let zzbdhjid = this.getQueryString('pkid');
        let params = {
            'zzbdhjid':zzbdhjid,
            'xsid':xsid
        }
        NHFetch('/zzbdCommon/getBlzt', 'GET', params)
        .then(res => {
            if (res) {
                //let blzt = res.data.blzt;
                if(res.data && res.data.blzt && res.data.blzt == '1'){
                    history.push({
                        pathname:this.state.nextUrl,
                        search:this.state.search,
                    })
                }else{
                    message.warning("请先确认知悉，再点下一步");
                }
            }
        });
    }

    updateStatus=()=>{
        let zzbdhjid = this.getQueryString('pkid');
        let xsid = this.state.xsid;
        let postData = {
            'zzbdhjid':zzbdhjid,
            'xsid':xsid
        }
        NHFetch('/zzbdCommon/commonSaveLinkTransactionStatus', 'POST', postData)
        .then(res => {
            if (res) {
                message.success("已确认办理");
                this.reloadMenu();
            }
        });
    }

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const xsData  = this.state.xsData;
        const hjywxData = this.state.hjywxData;
        const hjblztData = this.state.hjblztData;
        let xsid = getLoginUser()?getLoginUser().userId:'';
        this.reload();
        const {ssxxDisplay,imageUrl, refresh} = this.state;
        return (
            <div>{this.state.operable?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        title="自助报到"
                        setMenuList={this.setMenuList}
                        menuType="自助报到"
                        mkbz={this.state.mkbz}
                        lx={this.state.lx}
                    />

                    <Flex.Item className="page_con_r">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name:this.state.hjData['HJMC'] }
                            ]}
                        />
                        {this.state.lx&&this.state.lx==0?
                            <div className="box" >
                                <TitleTop title={this.state.hjData['HJMC']} extra={false} />
                                <div styleName="ueditor">
                                    <div  dangerouslySetInnerHTML={{__html: this.state.hjData['PCBLTS']}} ></div>
                                </div>
                                <div className="text-center mt-50">
                                        <Button
                                            onClick={()=>this.updateStatus()}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                           确认知悉
                                        </Button>
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>
                            </div>:
                            <div className="box">
                                {refresh === 1 ? <Hint key={1}/> : <Hint key={2}/>}
                                <TitleTop title="个人信息" extra={false} />
                                <Card style={{paddingLeft:0}} bordered={false}>
                                    <Row >
                                        <Col xl={4} lg={6} md={12} sm={24} xs={24} style={{lineHeight:'22px',paddingBottom:'12px'}}>
                                            <img
                                                src={imageUrl}
                                                onError={e => {
                                                    e.target.src = require('@images/stuDefault.png')
                                                }}
                                                style={{width: 125, height: 150}}/>
                                        </Col>
                                        <Col xl={7} lg={9} md={12} sm={24} xs={24} style={{lineHeight:'22px',paddingBottom:'12px'}}>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>姓 名：</span>{xsData.XM}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>性 别：</span>{xsData.XB}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>民 族：</span>{xsData.MZ}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>学 院：</span>{xsData.BMMC}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>班 级：</span>{xsData.BJMC}</div>
                                        </Col>
                                        <Col xl={7} lg={9} md={12} sm={24} xs={24} style={{lineHeight:'22px',paddingBottom:'12px'}}>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>身份证号：</span>{xsData.SFZJH}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>出生日期：</span>{xsData.CSRQ}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>学生类型：</span>{xsData.XSLB}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>专业：</span>{xsData.ZYMC}</div>
                                            {ssxxDisplay && <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>宿舍信息：</span>{xsData.SSXX}</div>}
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={24} xs={24} style={{lineHeight:'22px',paddingBottom:'12px'}}>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>生源地：</span>{xsData.SYD}</div>
                                            <div style={{lineHeight:'22px',paddingBottom:'12px'}}><span>联系电话：</span>{xsData.SJHM}</div>
                                        </Col>
                                    </Row>
                                    <Common key={"1"} studentData={xsData} onFinish={this.finish}
                                            hjblztData={hjblztData} hjywxData={hjywxData} />
                                </Card>
                                <div className="p-sw text-center mt-30">
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>
                            </div>}
                    </Flex.Item>
                </Flex>:<CommonTip hjmc={this.state.hjData['HJMC']} key={'mkbz'} mkbz={this.state.mkbz} nonOperationalData={this.state.nonOperationalData}/>}
            </div>
        );

    }
}
