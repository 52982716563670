import React from 'react';
import {Form, Row, Col,Input} from 'antd';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import NHFetch from "@utils/NHFetch";
import './index.css';
import NHMultiSelect from "@components/common/NHMultiSelect";
const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
};
@Form.create()
export default class MajorForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xsxxData:{},//学生信息,
            xyxxData: [], //学院下拉数据
            zyxxData: [], //专业下拉数据
            bjxxData: [], //班级下拉数据
        };
    }

    componentDidMount(){
        const { getFieldValue, setFieldsValue,resetFields } = this.props.form;
        const {zytzPkid} = this.props;
        if(zytzPkid){
            NHFetch('/zytz/getZytzInfo', 'GET',{pkid:zytzPkid}).then(resData => {
                if (resData && resData.data) {
                    const data=  resData.data;
                    let bmid = data['tzhyxid'];
                    let zyid = data['tzhzyid'];
                    let bjid = data['tzhbjid'];
                    let ly = data['sqly'];
                    setFieldsValue({
                        bmids:bmid,
                        ly:ly,
                    });
                    this.loadZyData(bmid,zyid,bjid);
                }
            });
        }

        this.getXsxx();
        this.getXyxxData();
    }

    getXsxx = () => {
        const xsid = this.props.xsid;
        NHFetch('/zytz/getXsxx', 'GET',{xsid:xsid}).then(resData => {
            if (resData && resData.data) {
                this.setState({ xsxxData: resData.data });
            }
        });
    };

    //加载学院数据
    getXyxxData = () => {
        NHFetch('/zytz/getSelectOptionDataByFilter', 'GET', { tag: 'xy' })
            .then(res => {
                if (res) {
                    this.setState({ xyxxData: res.data ? res.data : [] });
                }
            });
    }

    //部门信息选择回调
    handleXyxxChange = (value) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        if (value && value.length > 0) {
            NHFetch('/zytz/getSelectOptionDataByFilter', 'GET', { tag: 'zy', filter: value.toString() })
                .then(res => {
                    if (res) {
                        if (res.data) {
                            let newData = res.data.map(item => item.VALUE);
                            newData = new Set(newData);
                            setFieldsValue({ zyids:[]});
                            this.handleZyxxChange(newData);
                        }
                        this.setState({ zyxxData: res.data ? res.data : [] });
                    }
                })

        } else {
            setFieldsValue({ zyids: [], bjids: [] });
            this.setState({ zyxxData: [], bjxxData: [] });
        }

    }

    //专业信息选择回调
    handleZyxxChange = (value) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        if (value && value.length > 0) {
            NHFetch('/zytz/getSelectOptionDataByFilter', 'GET', { tag: 'bj', filter: value.toString() })
                .then(res => {
                    if (res) {
                        if (res.data) {
                            setFieldsValue({ bjids:[]});
                        }
                        this.setState({ bjxxData: res.data ? res.data : [] });
                    }
                })

        } else {
            setFieldsValue({ bjids: [] });
            this.setState({ bjxxData: [] });
        }
    }

    loadZyData=(bmid,zyid,bjid)=>{
        const {setFieldsValue } = this.props.form;
        if(bmid){
            NHFetch('/zytz/getSelectOptionDataByFilter', 'GET', { tag: 'zy', filter: bmid.toString() })
                .then(res => {
                    if (res) {
                        if (res.data) {
                            let newData = res.data.map(item => item.VALUE);
                            newData = new Set(newData);
                            if(newData.has(zyid)){
                                setFieldsValue({ zyids:zyid});
                                this.loadBjData(zyid,bjid);
                            }
                        }
                        this.setState({ zyxxData: res.data ? res.data : [] });
                    }
                })
        }
    }

    loadBjData=(zyid,bjid)=>{
        const {setFieldsValue } = this.props.form;
        if(zyid){
            NHFetch('/zytz/getSelectOptionDataByFilter', 'GET', { tag: 'bj', filter: zyid.toString() })
                .then(res => {
                    if (res) {
                        if (res.data) {
                            let newData = res.data.map(item => item.VALUE);
                            newData = new Set(newData);
                            if(newData.has(bjid)){
                                setFieldsValue({bjids:bjid});
                            }
                        }
                        this.setState({ bjxxData: res.data ? res.data : [] });
                    }
                })
        }
    }



    render() {
        const { getFieldDecorator } = this.props.form;
        const {xsxxData, xyxxData, zyxxData, bjxxData } = this.state;
        return (
            <Flex align="start">
                <div styleName="modal_l">
                    <Flex align="start">
                        <div styleName="modal_l_label">考生号：</div>
                        <Flex.Item>{xsxxData?xsxxData['KSH']:''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">性别：</div>
                        <Flex.Item>{xsxxData?xsxxData['XB']:''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">录取号：</div>
                        <Flex.Item>{xsxxData?xsxxData['LQH']:''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">姓名：</div>
                        <Flex.Item>{xsxxData?xsxxData['XM']:''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">身份证号：</div>
                        <Flex.Item>{xsxxData?xsxxData['SFZJH']:''}</Flex.Item>
                    </Flex>
                    <Flex className="mt-10" align="start">
                        <div styleName="modal_l_label">学号：</div>
                        <Flex.Item>{xsxxData?xsxxData['XH']:''}</Flex.Item>
                    </Flex>
                </div>
                <Flex.Item styleName="modal_r">
                    <Row>
                        <Col styleName="text-label" span={6}>
                            学院：
                        </Col>
                        <Col styleName="text-name" span={18}>
                            {xsxxData?xsxxData['BMMC']:''}
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col styleName="text-label" span={6}>
                            专业：
                        </Col>
                        <Col styleName="text-name" span={18}>
                            {xsxxData?xsxxData['ZYMC']:''}
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col styleName="text-label" span={6}>
                            班级：
                        </Col>
                        <Col styleName="text-name" span={18}>
                            {xsxxData?xsxxData['BJMC']:''}
                        </Col>
                    </Row>
                    <Flex styleName="line">
                        <Flex.Item styleName="line_l" />
                        <div styleName="line_con">申请专业调整</div>
                        <Flex.Item styleName="line_r" />
                    </Flex>
                    <Form>
                        <FormItem style={{display:'none'}}>
                            {getFieldDecorator('pkid', {
                                initialValue:this.props.zytzPkid,
                            })(
                                <Input/>
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="学院">
                            {getFieldDecorator('bmids', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择学院'
                                    }
                                ]
                            })(
                                <NHMultiSelect mode="default" dataSource={xyxxData} onChange={this.handleXyxxChange} />
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="专业">
                            {getFieldDecorator('zyids', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择专业'
                                    }
                                ]
                            })(
                                <NHMultiSelect mode="default" dataSource={zyxxData}  onChange={this.handleZyxxChange} />
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="班级">
                            {getFieldDecorator('bjids')(
                                <NHMultiSelect mode="default" dataSource={bjxxData} />
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="申请理由">
                            {getFieldDecorator('ly')(
                                <TextArea
                                    autosize={{
                                        minRows: 4,
                                        maxRows: 4
                                    }}
                                />
                            )}
                        </FormItem>
                    </Form>
                </Flex.Item>
            </Flex>
        )
    }

}