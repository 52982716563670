import React from 'react';
import { Result,Icon,message,Button } from 'antd';
import NHFetch from "@utils/NHFetch";

export default class PayModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            code:'',
        }
    }

    componentDidMount(){
        this.QRCodeImg();
    }

    QRCodeImg =() =>{
        const { merchantInfo } = this.props;
        const { jfbh,xmmc } = this.props.params;
        const acceptAgencyCode = merchantInfo.acceptAgencyCode;
        const url = merchantInfo.url;
        NHFetch(`/jfxt/getDeimensionlCode?jfbh=${jfbh}`,'GET').then(res =>{
            if (res && res.data){
                this.setState({
                    code:res.data
                })
            }else {
                message.error("获取缴费二维码失败")
            }
        })
    }

    refreshPayInfo =() =>{
        const { jfbh } = this.props.params;
        NHFetch('/openapi/turnBack/refreshPayInfo','GET',{jfbh:jfbh}).then(res =>{
            if (res && res.data){

            } else {
                message.error("刷新失败，请稍后再试");
            }
        })
    }

    render() {
        const { qfje }= this.props.params;
        const { code } = this.state;
        const { xmmc } = this.props.params;
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                {qfje > 0 ?
                    <div className="result-example">
                        <Result
                            icon={<img src={code} className="spe" style={{ fill: '#1F90E6' }} />}
                            title={xmmc}
                            // extra={<Button type="primary" onClick={this.refreshPayInfo}>刷新</Button>}
                        />
                    </div>
                    :
                    <div className="result-example">
                        <Result
                            status="success"
                            title="已支付"
                            subTitle="该项目已经缴费完成"
                        />
                    </div>
                }
                <style jsx="true" global="true">
                    {`
                          .result-example .spe {
                            width: 140px;
                            height: 140px;
                          }
                         .am-result .am-result-pic{
                            width: 120px;
                            height: 120px;
                         }
                    `}
                </style>
            </div>
        );
    }

}