import React from "react";

import {
    Table,
    Input,
    Icon,
    Form,
    message
} from 'antd';

import TitleTop from '@pages/Assembly/titleTop';
import { createUuid } from "@utils/NHCore";
import './index.css';
import NHSelect from "@components/common/NHSelect";
import NHFetch from '@utils/NHFetch';
import { FormRules } from "@utils/RegExp";
const FormItem = Form.Item;

class JtcyForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jtcy: false,
            jtcyAdd: false,
            jtcyData: [],
            zzmmmList: [],
            tempStr: '',
            jtcyList: [],//家庭成员数据加载
        }
    }

    componentDidMount() {

        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XSXX_JTCYGX', 'GET')
            .then(res => {
                if (res && res.data) {
                    let jtcyList = [];
                    for (let obj of res.data) {
                        jtcyList.push({ 'label': obj.LABEL, 'value': obj.VALUE });
                    }
                    this.setState({ jtcyList: jtcyList });
                }
            });


    }

    componentWillReceiveProps(nextProps) {
        if ('jtcyInitData' in nextProps && nextProps.jtcyInitData) {
            if (nextProps.jtcyInitData instanceof Array) {
                let temp = [...nextProps.jtcyInitData];
                this.setState({ jtcyData: temp });
            }

        }
    }

    cacheJtcyData = (cb) => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                message.info('请检查家庭成员是否正确填写')
                cb(false)
                return;
            }
            // 判断数据是否全部填写
            let errorPhone = "";
            let errorList = [];
            let dataList = [];
            if (this.state.jtcyData && this.state.jtcyData.length > 0) {
                for (let i = 0; i < this.state.jtcyData.length; i++) {
                    let data = this.state.jtcyData[i];
                    let pattern = /^1\d{10}$/;
                    if(data.lxfs){
                        if(!pattern.test(data.lxfs)){
                            if(errorList.length == 0){
                                errorPhone += data.lxfs
                            }else{
                                errorPhone += "," + data.lxfs;
                            }
                            errorList.push(data);
                        }
                    }
                    if (!data.gx && !data.xm && !data.gzxxdw && !data.lxfs && !data.operation) {

                    } else {
                        dataList.push(data);
                    }
                }
            }
            if(errorList && errorList.length > 0){
                message.error(`${errorPhone}号码填写错误`);
                return ;
            }
            this.setState({
                jtcy: false,
                jtcyAdd: false
            });

            const { cacheFormData, getChildComponent } = this.props;
            getChildComponent(3, true);
            cacheFormData('jtcy', dataList, true);
            cb(true);
        });
    }

    // 家庭成员 添加
    onjtcyAdd = () => {
        let { jtcyAdd, jtcyData } = this.state;
        if (!jtcyData) {
            jtcyData = [];
        }
        if (jtcyAdd) {
            jtcyData.push({
                key: createUuid(),
                gx: '',
                xm: '',
                gzxxdw: '',
                zzmmm: '',
                lxfs: '',
                operation: ''
            });

            this.setState({
                jtcyData
            });
        }
        this.setState({
            jtcyAdd: true
        });


    };
    // 家庭成员 保存添加
    onjtcySave = () => {
        let { jtcyData } = this.state;
        jtcyData.push();
        this.setState({
            jtcyData: jtcyData,
            jtcyAdd: false
        });
    };
    // 家庭成员 关闭添加
    onjtcyClose = (index) => {
        let { jtcyData } = this.state;
        jtcyData.splice(index, 1);
        this.setState({
            jtcyData: jtcyData,
            jtcyAdd: true
        });
    };

    handleChange = (value, index, key) => {
        let jtcyData = this.state.jtcyData;
        jtcyData[index][key] = value;
        this.setState({ jtcyData });
    };


    /** 验证必填项 */
    validateCustom = () => {
        const { jtcy, formInitData } = this.state;
        if (jtcy) {
            // 若是编辑模式 使用表单验证
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return false
                }
                return true
            })
        } else {
            return true
        }
    }

    validateFields = (func) => {
        let err = false;
        let formData = this.state.data;
        func(err, formData);
    }


    deepCopy = (obj) => {
        if (typeof obj != 'object') {
            return obj;
        }

        if (this.isArrayFn(obj)) {
            let newobj = [];
            for (let i = 0; i < obj.length; i++) {
                newobj.push(this.deepCopy(obj[i]));
            }
            return newobj;
        } else {
            let newobj = {};
            for (var attr in obj) {
                newobj[attr] = this.deepCopy(obj[attr]);
            }
            return newobj;
        }
    }


    isArrayFn = (value) => {
        if (typeof Array.isArray === "function") {
            return Array.isArray(value);
        } else {
            return Object.prototype.toString.call(value) === "[object Array]";
        }
    }

    render() {
        const { jtcy, jtcyAdd, jtcyData, jtcyList } = this.state;
        const { getChildComponent } = this.props;
        const { getFieldDecorator, } = this.props.form;
        const formStyle = {
            margin: 0
        }
        let jtcyTransalate = {};//家庭成员翻译
        for (let i = 0; i < jtcyList.length; i++) {
            jtcyTransalate[jtcyList[i].value] = jtcyList[i].label;
        }
        const jtcyColumns = [
            {
                title: '关系',
                key: 'gx',
                dataIndex: 'gx',
                render: (text, record, index) => {
                    return jtcy ? (

                        // <Input
                        //     value={text}
                        //     required={true}
                        //     onChange={(e) =>{this.handleChange(e.target.value,index,'gx')}}
                        //     style={{ width: 100 }}
                        //     placeholder="请填写关系"
                        // />
                        <NHSelect style={{ width: 140 }} sign="DMK_XSXX_JTCYGX"
                            value={text}
                            onChange={(value) => { this.handleChange(value, index, 'gx') }}

                        />
                        // <NHFormItem id={'gx'} label={''} form={form} required={true}
                        //     pattern={{max:5, message: "关系长度不能超过5字符！"}}
                        //     initialValue={text}>
                        // <Input onChange={(e) =>{this.handleChange(e.target.value,index,'gx')}}
                        //        placeholder="请填写关系"/>
                        // </NHFormItem>
                    ) : (
                        jtcyTransalate[text]
                    );
                }
            },
            {
                title: '姓名',
                dataIndex: 'xm',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'xm') }}
                            style={{ width: 100 }}
                            placeholder="请填写姓名"
                        />
                    ) : (
                        text
                    );
                }
            },
            // {
            //     title: '政治面貌',
            //     dataIndex: 'zzmmm',
            //     render: (text, record,index) => {
            //         //let tempStr = '';
            //         return(
            //             jtcy ?
            //             <NHSelect disabled = {jtcy ? false : true} style={{ width: 140 }} sign="DMK_XTGL_ZZMMM"
            //             value={text}
            //             onChange={(value) =>{this.handleChange(value,index,'zzmmm')}}

            //             />
            //             :
            //             (this.getzzmmmStr(text))
            //         );
            //     }
            // },
            {
                title: '工作单位及职务',
                dataIndex: 'gzxxdw',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'gzxxdw') }}
                            style={{ width: 140 }}
                            placeholder="请填写单位和职务"
                        />
                    ) : (
                        text
                    );
                }
            },
            // {
            //     title: '联系电话',
            //     dataIndex: 'lxfs',
            //     render: (text, record,index) => {
            //         return jtcy ? (
            //             <Input
            //                 value={text}
            //                 onChange={(e) =>{this.handleChange(e.target.value,index,'lxfs')}}
            //                 style={{ width: 100 }}
            //                 placeholder="请填写电话"
            //                 onBlur={(e) =>{this.inputOnBlur(e.target.value)}}
            //             />
            //         ) : (
            //             text
            //         );
            //     }
            // },
            {
                title: '联系电话',
                dataIndex: 'lxfs',
                render: (text, record, index) => {
                    return jtcy ? (
                        /* <Form.Item style={formStyle}>
                            {getFieldDecorator(`list.${index}.lxfs`, {
                                initialValue: text,
                                rules: [
                                    { required: true, message: '请输入联系电话' },
                                    FormRules.MOBILE_PHONE_NUMBER
                                ]
                            })( */
                                <Input
                                    value={text}
                                    maxLength={11}
                                    placeholder="请填写电话"
                                    onChange={(e) => { this.handleChange(e.target.value, index, 'lxfs') }}
                                />
                         /*    )}
                        </Form.Item> */

                    ) : (
                        text
                    );
                }
            },
            {
                title: 'QQ',
                dataIndex: 'qq',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'qq') }}
                            style={{ width: 100 }}
                            placeholder="请填QQ号"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '微信',
                dataIndex: 'wx',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'wx') }}
                            style={{ width: 100 }}
                            placeholder="请填微信号"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '删除',
                dataIndex: 'operation',
                width: 80,
                render: (text, record, index) => {
                    if (jtcy) {
                        return (
                            <div className="editable-row-operations">

                                <a
                                    href="javascript:;"
                                    onClick={() => this.onjtcyClose(index)}
                                >
                                    <Icon
                                        type="close"
                                        style={{ color: 'red' }}
                                    />
                                </a>
                            </div>
                        );
                    }
                    return (
                        <a href="javascript:;" onClick={() => this.onjtcyClose()}>

                            <Icon type="delete" style={{ color: 'red' }} />
                        </a>
                    );
                }
            }
        ];
        if (!jtcy) {
            jtcyColumns.pop();
        }
        return (
            <div>
                <TitleTop
                    title="家庭成员信息"
                    onEdit={type => {
                        this.setState({
                            jtcy: true,
                            jtcyAdd: true,
                            cacheData: this.deepCopy(this.state.jtcyData)
                        });
                        getChildComponent(3, false);
                    }}
                    onSave={cb => {
                        this.cacheJtcyData(cb);
                    }}
                    onClose={() => {
                        this.setState({
                            jtcy: false,
                            jtcyAdd: false,
                            jtcyData: this.deepCopy(this.state.cacheData)
                        });
                    }}
                />

                <Form>
                    <div className="p-sw">
                        <Table
                            bordered
                            size="middle"
                            pagination={false}
                            dataSource={jtcyData}
                            columns={jtcyColumns}
                        />
                        {jtcyAdd &&
                            (
                                <div
                                    className="mt-10"
                                    styleName="btn_add"
                                    onClick={() => {

                                        this.onjtcyAdd();
                                    }}
                                >
                                    添加家庭成员
                                </div>
                            )
                        }
                    </div>
                </Form>
            </div>
        );
    }
}
export default Form.create()(JtcyForm);