import React from "react";
import { Form, Select, Input, InputNumber, Upload, messag,DatePicker ,Radio,TimePicker,Checkbox, message } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import { Flex } from '@components';
import NHFormItem from '@components/common/NHFormItem/index';
import NHCascader from "@components/common/NHCascader"; 
import { createUuid } from '@utils/NHCore';
import NHFetch from '@utils/NHFetch';
import NHModal from "@components/common/NHModal";
import './index.css';
import moment from 'moment';

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"; //上传logo路径 
import { getLoginUser } from "@utils/NHCore";

function beforeUpload(file) {

    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
        message.error("You can only upload JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
}

class JbxxInitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jbxx: false,
            xsid: '',
            userInfoData: null,
            zzmmmList: [],
            mzmList: [],
            textData: '',
            jgmStr: '',
            jtStr: '',
            xsSsInfo: '',
            dxfsValue:"",//是否迁移户口
            sj:"",
            dxrq:"",
            dxjtList:[],//到校交通工具
            sfzsValue:"",
            xyContent:"",//协议内容
            values:{},
            sureButton:false,
            ZSXYFlag:false
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.setState({
                xsid: userId
            });
        }
        this.loadLxjt();
        this.getZSInfo();
    }

    // 获取到校使用交通工具
    loadLxjt=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXWZ_LXJTGJ').then(res=>{
            if(res && res.code == 200){
               let dxjtList = res.data;
                this.setState({
                    dxjtList:dxjtList
                })
            }
        });
    }

    onSavedxxxFormData = (cb) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                cb(false);
                return;
            }
            cb(true);
            const { cacheFormData } = this.props;
            console.log(this.state.dxrq+this.state.sj);
            if(this.state.dxrq && this.state.sj){
                values.dxsj = this.state.dxrq+this.state.sj;
            }else{
                values.dxsj = this.props.dxsj;
            }/* 
            if(values.sfzs == 1){
                    if(this.state.ZSXYFlag && this.state.sureButton){                        
                        cacheFormData('dxxx', values);
                        this.setState({
                            jbxx: false
                        });
                    }
                }else if(values.sfzs == 0){ */
                    cacheFormData('dxxx', values);
                    this.setState({
                        jbxx: false
                    });
                // }   
        });

    }

    handleCancel=()=>{
        this.setState({
            jbxx: true,
            sureButton:fasle
        });
    }

    handleOk = (stopLoading) => {
        const { cacheFormData } = this.props;
                if(this.state.ZSXYFlag){
                    this.nhModal.close();
                    this.setState({
                        sureButton:true
                    })
                }else{
                    message.warn("请勾选同意住宿协议，在确定");
                }
        }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = info => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10';
                NHFetch(url, 'POST')
                    .then(res => {
                        if (res && res.data) {
                            message.success("上传成功!")
                        }
                    });
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({ imageUrl })
        );
    }

    onNHCascaderChange = (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
        if (type == 'jg') {
            this.setState({ jgmStr: label });
        } else {
            this.setState({ jtStr: label });
        }

    }


      // 是否迁移户口回调
    dxfsOnChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            dxfsValue: e.target.value,
        });
      };

    //   选择到校日期回调
    dateData=(moment,dateString)=>{
        console.log(moment,dateString);
        this.setState({
            dxrq:dateString
        })
    }
    //   选择到校时间回调
    timeData=(moment,timeString)=>{
        console.log(moment,timeString);
        this.setState({
            sj:timeString
        })
    }

    // 获取住宿协议
    getZSInfo = ()=>{
        NHFetch('/xxfb/getFbnrByFlbz','get',{flbz:"XY_ZS"}).then(res=>{
            if(res&&res.code==200){
               console.log("res",res);
               this.setState({
                   xyContent:res.data
               })
            }
        });
    }

    // 回调
    sfzsOnChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            sfzsValue: e.target.value,
        });
        if(e.target.value == '1'){
            this.nhModal.show();
        }else{
            this.setState({
                ZSXYFlag:false
            })
        }
        
      };

      onChangeCheck=(e)=> {
        console.log(`checked = ${e.target.checked}`);
        if(e.target.checked){
            this.setState({
                ZSXYFlag:true
            })
        }else{
            this.setState({
                ZSXYFlag:false
            })
        }
      }

    render() {
        const { form, dxxxFormData, getChildComponent, userInfoData, stuSsxx, editXszp,SFBT,blztFlag } = this.props;
        const { jbxx, xsid,zzmmList,dxjtList,sfzsValue,dxfsValue } = this.state;
        /* const jtjddz = dxxxFormData ? dxxxFormData.jtjddz : '';
        const dxxxFormData = dxxxFormData ? dxxxFormData.dxxxFormData : ''; */
        let dx_sj ="";
        let dxrq = null;
        if(dxxxFormData && dxxxFormData.dxsj){
            let dateStr = dxxxFormData.dxsj
            /* let dxrq = dateStr.substring(0,10);
            let sj = dateStr.substring(11,18); */
            dxrq=dateStr.substring(0,10);
            dx_sj = dateStr.substring(10);
            console.log(dateStr,dxrq,dx_sj);
        }
        let ssxx = '';
        if (stuSsxx && stuSsxx.xqmc && stuSsxx.ldmc && stuSsxx.fj && stuSsxx.cwh) {
            ssxx = stuSsxx.xqmc + stuSsxx.ldmc + ' - ' + stuSsxx.fj + ' - ' + stuSsxx.cwh;
        }
        let footer = [
            {text:'同意',type:'primary',onClick:this.handleOk}
        ];

        return (
  
            <Form layout="inline">
                <TitleTop
                    title="到校信息"
                    onEdit={type => {
                        this.setState({
                            jbxx: true,
                            dxfsValue:dxxxFormData.jtgj
                        });
                        getChildComponent(4, false);
                    }}
                    onSave={cb => {
                        this.onSavedxxxFormData(cb);
                        getChildComponent(4, true);
                    }}
                    onClose={() => {
                        this.setState({
                            jbxx: false
                        });
                    }}
                />

                <Flex className="p-sw">
                    <Flex.Item className="ml-20">
                     <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'dxrq'}
                                label={<span styleName="item-label">到校日期：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} moment(dxrq,"YYYY-MM-DD")
                                initialValue={dxxxFormData ? (dxrq!=null?moment(dxrq,"YYYY-MM-DD"):null) : null}
                            >
                                <DatePicker onChange={this.dateData} placeholder={"选择日期..."} format={"YYYY-MM-DD"}/>
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校日期：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {dxrq ? dxrq : ''}
                                </div></div>
                        }
                    </div> 

                    <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'sj'}
                                label={<span styleName="item-label">到校时间：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                initialValue={dxxxFormData ? (dxxxFormData.dxsj!=null?moment(dxxxFormData.dxsj,"HH:mm:ss"):"") :""}
                            >
                                <TimePicker onChange={this.timeData}  placeholder={"请选择..."} />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校时间：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {dx_sj ? dx_sj : ''}
                                </div></div>
                        }
                    </div> 

                    <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'ptrs'}
                                label={<span styleName="item-label">到校随行人数：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}InputNumber
                                initialValue={dxxxFormData ? dxxxFormData.ptrs : ""}
                            >
                                <InputNumber style={{ width: 140 }} placeholder="请填写随行人数" />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校随行人数：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {dxxxFormData ? dxxxFormData.ptrs : ''}
                                </div></div>
                        }
                    </div>
                    
                    <div styleName="jbxx_item">
                            {jbxx ?
                                <div>
                                    <NHFormItem id={'sfzs'}
                                        label={<span styleName="item-label">是否住宿：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        // hasFeedback={true} 
                                        // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                        initialValue={dxxxFormData ? dxxxFormData.sfzs: ""}
                                    >
                                            <Radio.Group onChange={this.sfzsOnChange} value={sfzsValue} style={{display:"flex",margin:"10px"}}>
                                                <Radio value={'1'}>申请住宿</Radio>
                                                <Radio value={'0'}>不申请住宿</Radio>
                                            </Radio.Group>
                                    </NHFormItem>
                                </div>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>是否住宿：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {dxxxFormData ? (dxxxFormData.sfzs=='1'?"申请住宿":(dxxxFormData.sfzs=='0'?"不申请住宿":"")) : ''}
                                    </div></div>
                            }
                        </div>

                    <div className="p-swc">
                        <div styleName="jbxx_item2">
                            {jbxx ?
                                <div>
                                    <NHFormItem id={'jtgj'}
                                        label={<span styleName="item-label">到校方式：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        initialValue={dxxxFormData ? dxxxFormData.jtgj: ""}
                                    >
                                        <div>
                                            <Radio.Group onChange={this.dxfsOnChange} value={dxfsValue} style={{display:"flex",margin:"10px"}}>
                                            {
                                                    dxjtList.map((item,index)=>{
                                                        return  <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                    </NHFormItem>
                                </div>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校方式：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {dxxxFormData ?dxxxFormData.jtgjmc : ''}
                                    </div></div>
                            }
                        </div>
                    </div>
                        
                    </Flex.Item>
                </Flex>
                <NHModal
                    ref={(form) => { this.nhModal = form }}
                    title="住宿协议"
                    width={"60%"}
                    // style={{width:"70%"}}
                    onOk={this.handleOk}
                    footer={footer}
                    onCancel={this.handleCancel}
                >
                    <div>
                    <div dangerouslySetInnerHTML={{__html: this.state.xyContent}}></div>
                    <Checkbox onChange={this.onChangeCheck} checked={this.state.ZSXYFlag}>
                            <span style={{color:"red"}}>我已阅读并同意该协议。</span>
                    </Checkbox>
                    </div>
                   
                </NHModal>
                <style jsx="true" global="true">
                 {`
                          .ant-col-md-7 {  
                            width: 100px; 
                           }
                `}
          </style>
            </Form>
        );
    }
}


const JbxxForm = Form.create()(JbxxInitForm);

export default JbxxForm;