import React from 'react';
import { Icon, message, Modal, Upload } from 'antd';
import PropTypes from "prop-types";
import { createUuid } from '@utils/NHCore';
import '../../../../../components/common/NHPicturesWall/NHPicturesWall.css';

const fileDocDownloadPath = 'anonymity/docrepo/download/file?attachmentId=';
const redisDownloadPath = window.baseUrl + '/proData/downloadRedisCacheImage?uuid=';

/**
 * @author weishihuai
 * @date 2018/9/12
 * @time 9:52
 * @Description: 图片墙上传组件
 *
 *   图片墙使用说明：
 *   onRemove: this.onImageRemove.bind(this), 非必须, 图片删除之后回传删除图片给父组件的方法
 *   onChange: this.onChange.bind(this), 非必须, 值改变时执行的方法
 *   numberOfLimit: 2, 必须, 图片墙允许上传的图片张数，超过numberOfLimit上传按钮自动隐藏
 *   numberOfSize: 5,  必须, 图片墙允许上传图片大小的最大值，超过numberOfSize的话直接不上传
 *   saveType: 'file', 必须, 图片墙图片保存的格式，默认为‘file’保存在文档库，‘redis’为缓存在redis,‘base64’保存在内存中，如果需要永久保存文件，建议保存在文档库
 *   cacheTime: 30,  非必须, Redis缓存时长，单位：分钟  超过30分钟redis会删除缓存的图片信息
 *   imageList: ['0264a687-baa4-490f-92f5-e988dcd8d976','0264a687-baa4-490f-92f5-e988dcd8d976'] 非必须, 如果是编辑模式下，需要回显已经保存的图片信息，注意一下需要构造如下类型数据才能显示出来：
 */
class NHPicturesWall extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedImageList: [],          //已上传图片
            previewImageVisible: false,     //是否预览图片标识
            previewImageUrl: '',            //预览图片的URL
            previewImageName: '',           //预览图片的名称
        }
    }

    //设置props默认值
    static defaultProps = {
        saveType: 'file',       //保存的类型，默认为file文档库，可选的值: 'file'(上传文档库)、'base64'(保存数据库)、'redis'(缓存redis)
        imageList: [],          //设置默认上传的图片 格式:imageList: [{ uid: '对应文档库uuid', name: '图片名称',status: 'done', url: '图片url'}]
        cacheTime: 30,          //设置Redis缓存的时间，单位：分钟 表示Redis超过30分钟会删除缓存的信息
        numberOfLimit: 1,       //最多允许上传多少张图片 默认为1张
        numberOfSize: 2,        //默认上传大小限制2MB
        disabled: false,        //是否禁用
        onRemove: () => {       //删除成功回调
        },
        onChange: () => {
        },                      //值改变时的回调
        base64UploadUrl: '',    //base64上传图片路径
    };

    componentWillReceiveProps(nextProps) {
        if ('imageList' in nextProps) {
            const imageList = this.isnull(nextProps.imageList);
            const thisImageList = this.isnull(this.props.imageList);
            const { uploadFlag } = this.state;
            if (imageList && !thisImageList) {
                this.setState({ uploadedImageList: [] })
            }
            else if (!uploadFlag && !imageList && JSON.stringify(nextProps.imageList) !== JSON.stringify(this.props.imageList)) {
                const saveType = nextProps.saveType || 'file';
                let uploadedImageList = [];
                nextProps.imageList.map((uuid) => {
                    uploadedImageList.push({
                        uuid: uuid,
                        uid: uuid,
                        url: saveType === 'file' ? fileDocDownloadPath + uuid : redisDownloadPath + uuid
                    });
                });
                this.setState({
                    uploadedImageList
                });

                let imageList = [];
                uploadedImageList.map((file) => {
                    let obj = { uuid: file.uuid };
                    imageList.push(obj);
                });
            }
        }
    }

    isnull = (value) => {
        if (JSON.stringify(value) === '[]' || value === null || typeof (value) === "undefined" || (typeof (value) === 'string' && value.trim() === '')) {
            return true;
        } else {
            return false;
        }

    }


    //组件render之前组装已经上传成功的图片信息uploadedImageList,主要用于回显图片
    componentWillMount() {
        let saveType = this.props.saveType || 'file';
        if (this.props.imageList != undefined) {
            if (saveType === 'base64') {
                if (this.props.imageList.length > 0) {
                    let uploadedImageList = [];
                    this.props.imageList.map((base64Code) => {
                        uploadedImageList.push({
                            uuid: base64Code,
                            uid: base64Code,
                            url: base64Code
                        });
                    });
                    this.setState({
                        uploadedImageList: uploadedImageList
                    });

                    let imageList = [];
                    uploadedImageList.map((file) => {
                        let obj = {
                            uuid: file.uuid,
                            base64Url: file.url
                        };
                        imageList.push(obj);
                    });

                    if (this.props.onChange && typeof this.props.onChange === "function") {
                        this.props.onChange(imageList);
                    }
                }
            } else {
                if (this.props.imageList.length > 0) {
                    let uploadedImageList = [];
                    this.props.imageList.map((uuid) => {
                        if (typeof uuid === 'object') {
                            uuid = uuid['uuid'];
                        }
                        uploadedImageList.push({
                            uuid: uuid,
                            uid: uuid,
                            url: saveType === 'file' ? fileDocDownloadPath + uuid : redisDownloadPath + uuid
                        });
                    });
                    this.setState({
                        uploadedImageList: uploadedImageList
                    });

                    let imageList = [];
                    uploadedImageList.map((file) => {
                        let obj = { uuid: file.uuid };
                        imageList.push(obj);
                    });

                    if (this.props.onChange && typeof this.props.onChange === "function") {
                        this.props.onChange(imageList);
                    }
                }
            }
        }
    }

    //图片预览事件
    handlePreview = (file) => {
        this.setState({
            previewImageUrl: file.url || file.thumbUrl,
            previewImageName: file.name,
            previewImageVisible: true
        });
    };

    //取消图片预览事件
    handlePreviewCancel = () => {
        this.setState({
            previewImageVisible: false
        });
    };

    //文件上传改变事件
    handleChange = (e) => {
        const saveType = this.props.saveType || 'file';
        let fileList = e.fileList;
        let fileStatus = e.file.status;
        if (fileStatus === 'uploading') {    //上传中
        } else if (fileStatus === 'done') {  //上传成功
            let response = e.file.response;
            if (!response) {
                message.error("抱歉，文件由于未知原因上传失败!");
                return;
            }
            let responseMeta = response.meta;
            if (saveType === 'file') {   //上传到文档库
                //上传成功(success为true并且响应码为200)
                if (responseMeta && responseMeta.success && responseMeta.statusCode === 200) {
                    fileList = fileList.map((file) => {
                        if (file.uid === e.file.uid) {
                            file.uuid = response.data.ssbh;
                            //file.uuid = response.data.bh;
                        }
                        return file;
                    });

                    this.getUploadedImage(fileList, 'file');
                } else {
                    message.error("抱歉，文件由于未知原因上传失败!");
                    //过滤上传失败的图片
                    fileList = this.filterUploadFailFile(e.fileList, e.file);
                }

            } else if (saveType === 'redis') { //缓存Redis
                //缓存成功(响应码为200)
                if (response.code === 200) {
                    fileList = fileList.map((file) => {
                        if (file.uid === e.file.uid) {
                            file.uuid = response.data;
                        }
                        return file;
                    });
                    this.getUploadedImage(fileList, 'redis');
                } else {
                    message.error("抱歉，文件由于未知原因上传失败!");
                    //过滤上传失败的图片
                    fileList = this.filterUploadFailFile(e.fileList, e.file);
                }
            } else if (saveType === 'base64') {  //用于保存数据库
                this.getImageBase64(e.file.originFileObj, (imageUrl) => {
                    //上传成功
                    if (response.code === 200) {
                        fileList = fileList.map((file) => {
                            if (file.uid === e.file.uid) {
                                file.uuid = imageUrl;
                                file.base64Url = imageUrl;
                            } else {
                                file.base64Url = file.thumbUrl || file.url;
                            }
                            return file;
                        });
                        this.getUploadedImage(fileList, 'base64');
                    } else {
                        message.error("抱歉，文件由于未知原因上传失败!");
                        //过滤上传失败的图片
                        fileList = this.filterUploadFailFile(e.fileList, e.file);
                    }
                });

            }
        } else if (fileStatus === 'error') {  //上传出错
            message.error("抱歉，文件由于未知原因上传失败!");
            //过滤上传失败的图片
            fileList = this.filterUploadFailFile(e.fileList, e.file);
        }
        if (fileStatus) {
            let uploadFlag = fileStatus === 'uploading';
            this.setState({
                uploadFlag,
                uploadedImageList: fileList
            });
        }
    };

    //获取图片Base64
    getImageBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    //过滤上传失败的图片
    filterUploadFailFile = (list, failUploadedFile) => {
        return list.filter(file =>
            file.uid !== failUploadedFile.uid
        );
    };

    //获取上传成功的图片
    getUploadedImage = (fileList, saveType) => {
        if (saveType === 'base64') {
            let uploadedImageList = [];
            fileList.map((file) => {
                let obj = {
                    uuid: file.uuid,
                    base64Url: file.base64Url
                };
                uploadedImageList.push(obj);
            });

            //父组件回调方法，在父组件可以拿到已经上传成功的图片信息
            if (this.props.onChange && typeof this.props.onChange === "function") {
                this.props.onChange(uploadedImageList);
            }
        } else {
            let uploadedImageList = [];
            fileList.map(file => {
                let obj = {
                    uuid: file.uuid
                };
                uploadedImageList.push(obj);
            });

            //父组件回调方法，在父组件可以拿到已经上传成功的图片信息
            if (this.props.onChange && typeof this.props.onChange === "function") {
                this.props.onChange(uploadedImageList);
            }
        }
    };

    //上传文件之前的钩子，参数为准备上传的文件，若返回 false 则停止上传
    //一般在beforeUpload方法内限制文件上传的格式以及大小
    handelBeforeUpload = (file) => {
        let fileType = file.type;
        let fileName = file.name;
        //判断是否支持该文件格式
        let isInvalidFileType = !fileType || fileType.length < 1;
        if (isInvalidFileType) {
            message.error('抱歉，不支持上传该格式的文件！');
            return !isInvalidFileType;
        }

        let availFileSuffix = ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.gif', '.GIF'];
        let fileSuffixName = fileName.substring(file.name.lastIndexOf('.'));
        let isAvailableSuffix = availFileSuffix.includes(fileSuffixName);
        if (!isAvailableSuffix) {
            let msg = '抱歉，只支持上传【' + availFileSuffix.join(' || ') + '】格式的文件！';
            message.error(msg);
            return isAvailableSuffix;
        }

        //限制上传文件大小(默认上传大小限制2MB)
        let availSize = this.props.numberOfSize || 2;
        let fileSize = file.size / 1024 / 1024;
        const isOverSize = fileSize > availSize;

        if (isOverSize) {
            let msg = '抱歉，上传文件大小最大不能超过' + availSize + 'M！';
            message.error(msg);
            return !isOverSize;
        }
        return true;
    };

    //删除图片事件
    handleRemove = (file) => {
        let uploadedImageList = this.state.uploadedImageList;
        for (let index = 0, len = uploadedImageList.length; index < len; index++) {
            if (uploadedImageList[index].uid === file.uid) {
                uploadedImageList.splice(index, 1);
                break;
            }
        }
        this.setState({
            uploadedImageList: uploadedImageList
        });

        //组装数据返回给父组件，包含文档库的uuid以及文件名称
        let imageList = [];
        uploadedImageList.length > 0 && uploadedImageList.map((file) => {
            let obj = { uuid: file.uuid };
            imageList.push(obj);
        });

        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange(imageList);
        }

        //如果有需要对删除的图片做删除文档库等操作，回传给父组件进行处理
        if (this.props.onRemove && typeof this.props.onRemove === 'function') {
            this.props.onRemove(file.uuid);
        }
    };

    render() {
        const { previewImageVisible, previewImageUrl, uploadedImageList, previewImageName } = this.state;
        const numberOfLimit = this.props.numberOfLimit || 1;    //默认最多上传一张图片
        const saveType = this.props.saveType || 'file';      //默认上传到文档库
        const redisCacheTime = this.props.cacheTime || 30;      //Redis默认保存时长，单位：分钟

        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className={'xgui-picturewall-ant-upload-text'}>Upload</div>
            </div>
        );

        //根据saveType构造上传的url
        const action = saveType === 'file' ? 'anonymity/docrepo/upload' : saveType === 'redis' ? window.baseUrl + '/proData/uploadRedis' : this.props.base64UploadUrl;
        //请求发送的数据
        let requestData = saveType === 'file' ? {
            uuid: createUuid(),
            type: '1'
        } : saveType === 'redis' ? {
            'redisData': redisCacheTime
        } : {};

        const params = {
            name: 'file',
            action: action,  //图片上传路径
            accept: 'image/*',   //接受上传的文件类型,指定为image/**的话，弹出选择文件窗口的时候只会显示图片类型文件，过滤掉.txt、.xsl等非图片文件
            listType: 'picture-card',  //图片墙样式
            multiple: false,  //是否允许多选
            fileList: uploadedImageList,  //已上传的图片
            data: requestData,  //上传所需参数
            onRemove: this.handleRemove,  //删除执行的方法
            beforeUpload: this.handelBeforeUpload, //图片上传前执行的方法
            onPreview: this.handlePreview, //预览图片执行的方法
            onChange: this.handleChange,  //值改变执行的方法
        };

        return (
            <div className="clearfix">
                <Upload {...params}>
                    {uploadedImageList.length >= numberOfLimit ? null : uploadButton}
                </Upload>
                <Modal visible={previewImageVisible} footer={null} onCancel={this.handlePreviewCancel}>
                    <img alt={previewImageName} style={{ width: '100%' }} src={previewImageUrl} />
                </Modal>
            </div>
        );
    }
}

//属性检查
NHPicturesWall.PropTypes = {
    saveType: PropTypes.string,         //保存的类型
    imageList: PropTypes.array,         //初始化图片信息
    cacheTime: PropTypes.number,        //Redis缓存时间
    numberOfLimit: PropTypes.number,    //允许上传的图片张数
    numberOfSize: PropTypes.number,     //允许上传的图片大小
    disabled: PropTypes.bool,           //是否禁用
    base64UploadUrl: PropTypes.string,  //base64图片上传路径
    onRemove: PropTypes.func,           //删除成功回调
    onChange: PropTypes.func,           //值改变回调
};

export default NHPicturesWall;