import React from 'react';
import NHFetch from "@utils/NHFetch";

export default class Hint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hint:'',
        };
    }

    componentWillMount(){
        this.getHint();
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    getHint=()=>{
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/getHint", 'GET', {zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    this.setState({hint:res.data});
                }
            });
    }

    render() {
        return (
            <div className="tip">
                <div dangerouslySetInnerHTML={{__html:this.state.hint}}></div>
            </div>
        );
    }
}
