import React from "react";
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import {
    Button,
    DatePicker,
    Progress,
    Popconfirm,
    Table,
    Input,
    Icon,
    Select,
    Upload,
    Form,
    message,
    Modal
} from 'antd';
import moment from 'moment';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid } from "@utils/NHCore";
import { FormRules } from "@utils/RegExp";
import '../index.css';
import NHFormItem from '@components/common/NHFormItem/index';
// import NHImage from '@components/common/NHImage/index'
import NHImage from './NHImage/index'
const FormItem = Form.Item;
// const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD ';
class HjqkInitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hjqk: false,
            hjqkAdd: false,
            hjqkData: [],
            previewVisible: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('hjqkInitData' in nextProps && nextProps.hjqkInitData) {
            if (nextProps.hjqkInitData instanceof Array) {
                let temp = [...nextProps.hjqkInitData];
                this.setState({ hjqkData: temp });
            }
        }
    }

    cacheHjqkData = (cb) => {
        this.props.form.validateFields((err, values) => {
            if (err) return cb(false);
            //判断数据是否全部填写
            let dataList = [];
            let errorPhone = "";
            let errorList = [];
            let xxcjData = {};
            if (this.state.hjqkData && this.state.hjqkData.length > 0) {
                for (let i = 0; i < this.state.hjqkData.length; i++) {
                    let data = this.state.hjqkData[i];
                    if (!data.hjmc && !data.hjsj && !data.fjid && !data.operation) {

                    } else {
                        dataList.push(data);
                    }
                }
            }
            
            const { cacheFormData, getChildComponent } = this.props;
            getChildComponent(8, true);
            cacheFormData('hjqk', dataList, true);
            cb(true);
            this.setState({
                hjqk: false,
                hjqkAdd: false,
                hjqkData: dataList, 
            });
        })
    }

    onhjqkAdd = () => {
        let { hjqkAdd, hjqkData } = this.state;
        if (!hjqkData) {
            hjqkData = [];
        }
        if (hjqkAdd) {
            hjqkData.push({
                pkid: createUuid(),
                hjmc: '',
                hjsj: '',
                fjid: '',
                operation: ''
            });
            this.setState({
                hjqkData
            });
        }
        this.setState({
            hjqkAdd: true
        });
    };

    onhjqkSave = () => {
        let { hjqkData } = this.state;
        hjqkData.push();
        this.setState({
            hjqkData: hjqkData,
            hjqkAdd: false
        });
    };

    onhjqkClose = (index) => {
        let { hjqkData } = this.state;
        hjqkData.splice(index, 1);
        this.setState({
            hjqkData: hjqkData,
            hjqkAdd: true,
            cacheData: this.deepCopy(hjqkData)
        });
        
    };

    handleChange = (value, index, key) => {
        let hjqkData = this.state.hjqkData;
        hjqkData[index][key] = value;
        this.setState({ hjqkData });
    }

    handleDateChange = (value, index) => {

        let hjqkData = this.state.hjqkData;
        let hjsj = value ? moment(value).format(dateFormat) : undefined;
        hjqkData[index]['hjsj'] = hjsj;
        this.setState({ hjqkData });
    }

    handleFjidChange = (value, index) => {
        let hjqkData = this.state.hjqkData;
        if(value.constructor.name == 'Array'){ 
            if (typeof value === 'object') { 
                let isFileUpload = false;
                let array = [];
                for (let item of value) {
                    if (item.hasOwnProperty('uuid') && item['uuid']) {
                        isFileUpload = true;
                        array.push(item['uuid']) 
                    }
                }
                if (isFileUpload) {
                    hjqkData[index]['fjid'] = array.join(',');
                    this.setState({ hjqkData })
                }
            }
        } 
    }

    handleFjidRemove = (value, index) => {
        let hjqkData = this.state.hjqkData
        let fjData = hjqkData[index]['fjid'];
        let hjqkArr = fjData.split(',');
        for(let i = 0; i < hjqkArr.length; i++) {
            if(hjqkArr[i] == value){
                hjqkArr.splice(i, 1);
            }
        }
        hjqkData[index]['fjid'] = hjqkArr.join(',');
        this.setState({ hjqkData })
    }

    disabledDate(current) {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    }

    deepCopy = (obj) => {
        if (typeof obj != 'object') {
            return obj;
        }

        if (this.isArrayFn(obj)) {
            let newobj = [];
            for (let i = 0; i < obj.length; i++) {
                newobj.push(this.deepCopy(obj[i]));
            }
            return newobj;
        } else {
            let newobj = {};
            for (var attr in obj) {
                newobj[attr] = this.deepCopy(obj[attr]);
            }
            return newobj;
        }
    }

    isArrayFn = (value) => {
        if (typeof Array.isArray === "function") {
            return Array.isArray(value);
        } else {
            return Object.prototype.toString.call(value) === "[object Array]";
        }
    }

    getImage = (fjdata) => {
        if(fjdata != null && fjdata != '' && JSON.stringify(fjdata) !== '{}') {
            if (typeof fjdata === 'object') { 
                let array = [];
                for (let item of fjdata) {
                    if (item.hasOwnProperty('uuid') && item['uuid']) {
                        array.push(item['uuid']) 
                    }
                }
                fjdata= array
            }else{
                fjdata=fjdata.split(",");
            } 
            let imageList = (
                this.imageLists(fjdata)  
            )
            return imageList;
        }
    }

    //图片展示
    imageLists = (imageLists) =>{
        {
            let items=[];
            for(let key in imageLists){
                let imageList = typeof imageLists[key] == 'string'?imageLists[key].split(";"):imageLists[key];
                items.push (
                    <span>
                       <img style={{height:"40px" ,width:"40px", marginRight:"3px"}} src={"anonymity/docrepo/download/file?attachmentId=" + imageList}
                        onClick={() => {
                            this.handlePreview("isImage",imageList[0]);
                        }}/>
                    </span>
                )
            }
            return items;
        }
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => { 
        if (fjmc==="isImage") {
            this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };
        
    //打开图片弹窗
    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }
    // 关闭图片弹窗
    closeModal = () => this.setState({ previewVisible: false });

    render() {

        const { getChildComponent, form } = this.props;
        const { hjqk, hjqkData, hjqkAdd, previewImage, previewVisible } = this.state;

        const hjqkColumns = [
            {
                title: '获奖名称',
                dataIndex: 'hjmc',
                width: '300px',
                render: (text, record, index) => {
                    return hjqk ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'hjmc') }}
                            style={{ width: 140 }}
                            placeholder="请填写获奖名称"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '获奖时间',
                dataIndex: 'hjsj',
                width: '200px',
                render: (text, record, index) => {
                    let hjsj = record.hjsj;
                    return hjqk ? (
                        <DatePicker
                            value={ hjsj ? moment(hjsj, dateFormat) : undefined}
                            format={dateFormat}
                            onChange={(value) => { this.handleDateChange(value, index) }}
                        />
                    ) : (
                        (hjsj ? hjsj : '') 
                    );
                }
            },
            {
                title: '附件',
                dataIndex: 'fjid',
                // width: '500px',
                render: (text, record, index) => {
                    return hjqk ? (
                        <NHImage numberOfSize='10' saveType='file' disabled={hjqk ? false : true}
                            numberOfLimit='5'
                            imageList={record.fjid ? record.fjid.split(",") : []}
                            previewImageVisible='true'
                            onChange={(value) => { this.handleFjidChange(value, index) }}
                            onRemove={(value) => { this.handleFjidRemove(value, index) }}
                        />
                    ) : (
                        this.getImage(record.fjid ? record.fjid : '')
                    );
                }
            },
            {
                title: '删除',
                dataIndex: 'operation',
                width: 80,
                render: (text, record, index) => {
                    if (hjqk) {
                        return (
                            <div className="editable-row-operations">

                                <a
                                    href="javascript:;"
                                    onClick={() => this.onhjqkClose(index)}
                                >
                                    <Icon
                                        type="close"
                                        style={{ color: 'red' }}
                                    />
                                </a>
                            </div>
                        );
                    }
                    return (
                        <a href="#">
                            <Icon type="delete" style={{ color: 'red' }} />
                        </a>
                    );
                }
            }
        ];

        if (!hjqk) {
            hjqkColumns.pop();
        }

        return (
            <div>
                <TitleTop
                    title="获奖情况"
                    onEdit={type => {
                        this.setState({
                            hjqk: true,
                            hjqkAdd: true,
                            cacheData: this.deepCopy(this.state.hjqkData)
                        });
                        getChildComponent(8, false);
                    }}
                    onSave={cb => {
                        this.cacheHjqkData(cb);
                    }}
                    onClose={() => {
                        this.setState({
                            hjqk: false,
                            hjqkAdd: false,
                            hjqkData: this.deepCopy(this.state.cacheData)
                        });
                    }}
                />
                <div className="p-sw">
                    <Table
                        bordered
                        size="middle"
                        pagination={false}
                        dataSource={hjqkData}
                        columns={hjqkColumns}
                    />
                    {hjqkAdd && (
                        <div
                            className="mt-10"
                            styleName="btn_add"
                            onClick={() => {

                                this.onhjqkAdd();
                            }}
                        >
                            添加获奖情况
                        </div>
                    )}
                </div>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.closeModal}
                >
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
            </div>
        );
    }
}
const HjqkForm = Form.create()(HjqkInitForm);
export default HjqkForm;
