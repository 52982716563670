import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@components";
import {
  Button,
  DatePicker,
  Progress,
  Popconfirm,
  Table,
  Input,
  Icon,
  Select,
  Upload,
  message,
  Spin,
} from "antd";
import SiderMenu from "@pages/Assembly/siderMenu";
import Breadcrumb from "@pages/Assembly/Breadcrumb";
import TitleTop from "@pages/Assembly/titleTop";
import NHFetch from "@utils/NHFetch";
import { getLoginUser } from "@utils/NHCore";
import "./index.css";
import JbxxForm from "./JbxxForm"; //基本信息
import JtcyForm from "./JtcyForm"; //家庭成员
import DxxxForm from "./dxxxForm"; //到校信息
import LxfsForm from "./lxfsForm"; //联系方式
import KzxxForm from "./KzxxForm"; //扩展信息
import ZyzxxForm from "./zyzxxForm"; //志愿者信息
import JbxxForm_sjz from "./sjz/JbxxForm"; //基本信息（石家庄）
import JtcyForm_ahgs from "./ahgs/JtcyForm"; //家庭成员信息（安徽工商）
import JbxxForm_ahgs from "./ahgs/JbxxForm"; //基本信息（安徽工商）
import JyjlForm_ahgs from "./JyjlForm"; //教育经历(安徽工商)
import ZyshgxForm_ahgs from "./ZyshgxForm"; //主要社会关系
import JbxxForm_whgc from "./whgc/JbxxForm"; //基本信息（武汉工程）
import JbxxForm_hnyz from "./hnyz/JbxxForm"; //基本信息(湖南幼专)
import HjqkForm_gxxx from "./gxxxzyjsxy/HjqkForm"; //获奖情况(广西信息职业)
import createHistory from "history/createHashHistory";
import CommonTip from "../common";
import NextButton from "@pages/SelfHelp/common/nextIndex";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const history = createHistory();
const { RangePicker } = DatePicker;
const Option = Select.Option;
const stuInfoUrl = "/xxcj/";

export default class UpdateInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mkbz: "GD001_3",
      hjmc: "",
      jbxxFormData: {},
      formInitData: {},
      jbxxInitData: {},
      lxxxInitData: {},
      dxxxInitData: {},
      jtcyInitData: [],
      zyshgxInitData: [],
      jyjlInitData: [],
      kzxxInitData: {},
      userBaseInfo: {},
      stuSsxx: {},
      hjqkInitData: {},
      xsid: "",
      jbxxFlag: true,
      lxfsFlag: true,
      dxxxFlag: true,
      jtcyFlag: true,
      jyjlFlag: true,
      zyshgxFlag: true,
      nextUrl: "", //左侧菜单加载完后传进来
      search: "",
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      isEditJbxx: false,
      isEditJtcy: false,
      isEditZyshgx: false,
      isEditJyjl: false,
      isEditHjqk: false,
      menuList: [],
      zzbdhjid: "",
      Typeconfig: [], //分类采集数据
      bkbjFlage: false,
      blztFlag: "0", //环节办理状态
      uploading: false,
    };
  }

  componentDidMount() {
    this.getPersonConfig();
    this.queryQztjAndYlhj();
    //this.getYlhjData();
    this.getData4Yxwz();
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : "";
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false,
      });
      return;
    }
    let zzbdhjid = this.getQueryString("pkid");
    NHFetch("/zzbd/queryQztjAndYlhj", "GET", {
      xsid: xsid,
      zzbdhjid: zzbdhjid,
    }).then((res) => {
      if (res && res.data) {
        let kfsj = res.data["kfsj"];
        let ylhj = res.data["ylhj"];
        let qzhj = res.data["qzhj"];
        let nohj = res.data['nohj'];
        this.setState({
          nonOperationalData: res.data,
          zzbdhjid: zzbdhjid,
        });
        if (kfsj && kfsj["YXSJ"] != "1") {
          this.setState({ operable: false });
        }

        if (ylhj && ylhj.length > 0) {
          this.setState({ operable: false });
        }

        if (qzhj && qzhj.length > 0) {
          this.setState({ operable: false });
        }

        if (nohj) {
          this.setState({ operable: false });
      }
      }
      let sideMenu = this.refs.siderMenu.state.menuList;
      console.log(sideMenu);
      let blztFlag = "0";
      sideMenu.map((item) => {
        if (item.HJDM == "GD001_3") {
          blztFlag = item.BLZT;
        }
      });
      this.setState({
        blztFlag: blztFlag,
      });
    });
  };

  getQueryString = (name) => {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  };

  setMenuList = (menuList) => {
    let nextCount = 0;
    let bz = this.state.mkbz;
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC;
        this.setState({ hjmc: hjmc });
        nextCount = index + 1;
        return;
      }
    });
    let menu = menuList[nextCount];
    if (menu) {
      let hjdm = menu.HJDM;
      if (hjdm.indexOf("GD") == 0) {
        let gdUrl = mkbz[hjdm];
        this.setState({
          nextUrl: gdUrl,
          search: "pkid=" + menu.PKID,
        });
      } else if (hjdm.indexOf("ZDY") == 0) {
        let zdyUrl = "/customLink";
        this.setState({
          nextUrl: zdyUrl,
          search: "pkid=" + menu.PKID + "&mkbz=" + hjdm + "&lx=" + menu.LX,
        });
      }
    } else {
      this.setState({
        nextUrl: "",
        search: "",
      });
    }
  };

  getChildComponent = (flag, mode) => {
    // console.log("getChildComponent返回数据",flag,mode,this.state.dxxxInitData);
    switch (flag) {
      case 1:
        this.setState({ jbxxFlag: mode });
        break;
      case 2:
        this.setState({ lxfsFlag: mode });
        break;
      case 3:
        this.setState({ jtcyFlag: mode });
        break;
      case 4:
        this.setState({ dxxxFlag: mode });
        break;
      case 5:
        this.setState({ zyzxxFlag: mode });
      case 6:
        this.setState({ jyjlFlag: mode });
      case 7:
        this.setState({ zyshgxFlag: mode });
      case 8:
        this.setState({ hjqkFlag: mode });
      default:
        break;
    }
  };

  //缓存编写的信息，缓存完之后提交
  cacheFormData = (type, values, isAdd) => {
    if (type.indexOf("jbxx") != -1) {
      const {
        jbxx_CSRQ,
        rxxx_byxx,
        xxcj_ZDYHKSFQY,
        jbxx_zzmmm,
        jbxx_hkszd,
        jbxx_jgm,
      } = values;
      // if (jbxx_CSRQ !== null && rxxx_byxx !== null && jbxx_jgm.length > 0 && xxcj_ZDYHKSFQY !== null && jbxx_zzmmm !== null&&jbxx_hkszd!=null) {
      this.setState(
        {
          jbxxInitData: { ...values },
          isEditJbxx: true,
        },
        () => {
          this.insertData4Yxwz(type);
        }
      );
      /* } else {
                message.error("存在必填信息未填写，请填写后再保存！")
            } */
    }
    if (type == "jtcy" && isAdd) {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
            isEditJtcy: true,
          },
          () => {
            this.insertData4Yxwz(type);
          }
        );
      } else {
        message.error("存在必填信息未填写，请填写后再保存！");
      }
    }
    if (type == "dxxx") {
      // console.log("缓存个人信息数据-------",values,type,isAdd);
      const { ptrs, jtgj, zsqk_sfzs, dxsj } = values;
      // if (jtgj!== null && zsqk_sfzs !== null  && ptrs !== null && dxsj!== null) {
      this.setState(
        {
          dxxxInitData: { ...values },
          isEditdxxx: true,
        },
        () => {
          this.insertData4Yxwz(type);
        }
      );
      // } else {
      //     message.error("存在必填信息未填写，请填写后再保存！")
      // }
    }
    if (type == "lxxx") {
      // const {jjlxr_gxdm,jjlxr_jjlxr,jjlxr_lxrdh ,lxxx_dzyx, lxxx_qq, lxxx_sjhm,lxxx_wx,jtdzDetail,jtxx_jtjddz,jtjddz} = values;
      // if (jjlxr_gxdm !== null  && jjlxr_jjlxr !== null && jjlxr_lxrdh !== null&&lxxx_dzyx!=null&& lxxx_qq !== null && lxxx_sjhm !== null&&lxxx_wx!=null&&jtdzDetail!=null) {
      //     console.log("缓存个人信息数据-------",values,type,isAdd);
      this.setState(
        {
          lxxxInitData: { ...values },
          isEditlxxx: true,
        },
        () => {
          this.insertData4Yxwz(type);
        }
      );
      // } else {
      //     message.error("存在必填信息未填写，请填写后再保存！")
      // }
    }
    if (type == "jyjl") {
      this.setState(
        {
          jyjlInitData: values,
          isEditJyjl: true,
        },
        () => {
          this.insertData4Yxwz(type);
        }
      );
    }
    if (type == "zyshgx") {
      this.setState(
        {
          zyshgxInitData: values,
          isEditZyshgx: true,
        },
        () => {
          this.insertData4Yxwz(type);
        }
      );
    }
    if (type == "hjqk" && isAdd) {
      this.setState(
        {
          hjqkInitData: values,
          isEditHjqk: true,
        },
        () => {
          this.insertData4Yxwz(type);
        }
      );
    }
  };

  //获取个人信息初始数据
  getData4Yxwz = () => {
    let userId = getLoginUser() ? getLoginUser().userId : undefined;
    if (userId) {
      this.setState({ xsid: userId });
      NHFetch("/newStudent/queryXsInfo", "GET", { xsid: userId }).then(
        (res) => {
          if (res && res.data) {
            console.log(res.data);
            let stuInfo = res.data.stuInfo;
            let stuXxcj = res.data.stuXxcj;
            let stuSsxx = res.data.stuSsxx;
            let editXszp = res.data.editXszp;
            this.setState({
              userBaseInfo: stuInfo.userBaseInfo,
              jbxxInitData: stuInfo.xsJbxx4YxwzVO,
              lxxxInitData: stuInfo.xsJbxx4YxwzVO,
              dxxxInitData: stuInfo.xsJbxx4YxwzVO,
              jtcyInitData: stuInfo.jtcyVo,
              zyshgxInitData: stuInfo.zyshgxVO,
              jyjlInitData: stuInfo.jyjlVO,
              hjqkInitData: stuInfo.hjqkVO,
              kzxxInitData: { ...stuXxcj },
              stuSsxx: stuSsxx,
              editXszp: editXszp,
            });
          }
        }
      );
    } else {
      message.error("登录超时,请重新登录！");
    }
  };
    // 获取个人信息采集分类配置 
    getPersonConfig=()=>{
        NHFetch('/xxcj/getXxcjFl','get').then(res=>{
            if(res&&res.code==200){
               console.log("res",res);
               this.setState({
                   Typeconfig:res.data
               })
            }
        });
  };

  // 单个保存个人信息数据
  insertData4Yxwz = (type) => {
    const {
      jbxxInitData,
      jtcyInitData,
      lxxxInitData,
      dxxxInitData,
      zyshgxInitData,
      jyjlInitData,
      xsid,
      zzbdhjid,
      hjqkInitData,
    } = this.state;
    let flbs = "";
    let xsxx = {};
    let jtcyVo = [];
    let zyshgxVO = [];
    let jyjlVO = [];
    let hjqkVO = [];
    let xsInfo4YxwzVO = {};
    console.log(type);
    if (type == "jbxx") {
      xsInfo4YxwzVO = Object.assign(xsxx, jbxxInitData);
      flbs = "jbxx";
    } else if (type == "jbxx_sjz") {
      xsInfo4YxwzVO = Object.assign(xsxx, jbxxInitData);
      flbs = "jbxx_sjz";
    } else if (type == "jbxx_whgc") {
      xsInfo4YxwzVO = Object.assign(xsxx, jbxxInitData);
      flbs = "jbxx_whgc";
    } else if (type == "jbxx_ahgs") {
      xsInfo4YxwzVO = Object.assign(xsxx, jbxxInitData);
      flbs = "jbxx_ahgs";
    } else if (type == "jbxx_hnyz") {
      xsInfo4YxwzVO = Object.assign(xsxx, jbxxInitData);
      flbs = "jbxx_hnyz";
    } else if (type == "jtcy") {
      jtcyVo = jtcyInitData;
      flbs = "jtxx";
    } else if (type == "jtxx_ahgs") {
      jtcyVo = jtcyInitData;
      flbs = "jtxx_ahgs";
    } else if (type == "jyjl") {
      jyjlVO = jyjlInitData;
      flbs = "jyjl";
    } else if (type == "zyshgx") {
      zyshgxVO = zyshgxInitData;
      flbs = "zyshgx";
    } else if (type == "lxxx") {
      xsInfo4YxwzVO = Object.assign(xsxx, lxxxInitData);
      flbs = "lxxx";
    } else if (type == "dxxx") {
      xsInfo4YxwzVO = Object.assign(xsxx, dxxxInitData);
      flbs = "dxxx";
    } else if (type == "hjqk") {
      hjqkVO = hjqkInitData;
      flbs = "hjqk";
    }
    xsInfo4YxwzVO = {
      xsid,
      xsxx,
      flbs,
    };
    // 增加判断社会关系，家庭成员，教育经历编辑其中一个时，另外两个不要传空
    if (type.indexOf("jyjl") !== -1) {
      xsInfo4YxwzVO["jyjlVO"] = jyjlVO;
    } else if (type.indexOf("jtcy") !== -1) {
      xsInfo4YxwzVO["jtcyVo"] = jtcyVo;
    } else if (type.indexOf("zyshgx") !== -1) {
      xsInfo4YxwzVO["zyshgxVO"] = zyshgxVO;
    } else if (type.indexOf("hjqk") !== -1) {
      xsInfo4YxwzVO["hjqkVO"] = hjqkVO;
    }
    console.log(xsInfo4YxwzVO);
    NHFetch("/newStudent/saveOrUpdateXsInfo", "POST", xsInfo4YxwzVO).then(
      (res) => {
        if (res && res.code == 200) {
          console.log(res);
          message.success("保存成功");
          this.getData4Yxwz();
        } else {
          message.error("保存失败");
        }
      }
    );
  };

  // 获取个人信息采集分类配置
  getPersonConfig = () => {
    NHFetch("api/zhxg-yxxt/yxwz/getXxcjFl", "get").then((res) => {
      if (res && res.code == 200) {
        console.log("res", res);
        this.setState({
          Typeconfig: res.data,
        });
      }
    });
  };

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList();
  };

  //提交
  handelData = () => {
    this.setState({
      uploading: true,
    });
    let xsid = getLoginUser() ? getLoginUser().userId : undefined;
    let hjblFlag = true;
    /* if(this.state.blztFlag=="1"){
            hjblFlag = true
        } */
    let params = {
      xsid: xsid,
      hjblFlag: hjblFlag,
      zzbdhjid: this.state.zzbdhjid,
    };

    NHFetch("/zzbd/stuInfoSubmit", "post", params).then((res) => {
      if (res && res.code == 200) {
        if (res.data != null && res.data.code == "1") {
          this.refs.siderMenu.reloadMenuList();
          message.success("提交成功");
          this.setState({
            bkbjFlage: true,
            hjblFlag: true,
            uploading: false,
          });
          this.queryQztjAndYlhj();
        } else if (res.data != null && res.data.code == "0") {
          message.error(res.data.msg);
          this.setState({
            uploading: false,
          });
        } else {
          message.error("个人信息环节提交失败");
        }
      } else {
        message.error("个人信息环节提交失败");
      }
    });
  };

  render() {
    const {
      xsid,
      jtcyInitData,
      zyshgxInitData,
      Typeconfig,
      bkbjFlage,
      blztFlag,
      hjqkInitData,
    } = this.state;
    return (
      <div>
        {this.state.operable ? (
          <Flex className="w1180 mt-20" align="start">
            <SiderMenu
              ref={"siderMenu"}
              setMenuList={this.setMenuList}
              title="自助报到"
              /*   active={0}*/
              mkbz={this.state.mkbz}
              menuType="自助报到"
            />
            <Flex.Item className="page_con_r">
              <Breadcrumb
                noLine
                list={[
                  { name: "首页", url: "/index" },
                  { name: "自助报到", url: "/register" },
                  { name: this.state.hjmc },
                ]}
              />
              <Spin spinning={this.state.uploading}>
                <div className="box">
                  <Hint />
                  {/* 渲染个人信息采集分类，通过判断是否存在该分类并且通过字段SFXS控制是否显示 */}
                  {Typeconfig.map((item) => {
                    if (item.FLBS == "jbxx" && item.SFXS == "1") {
                      return (
                        <JbxxForm
                          ref="refJbxxForm"
                          jbxxFormData={this.state.jbxxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          userInfoData={this.state.userBaseInfo}
                          stuSsxx={this.state.stuSsxx}
                          editXszp={this.state.editXszp}
                          kzxxInitData={this.state.kzxxInitData.zdglVOs}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "jbxx_ahgs" && item.SFXS == "1") {
                      //安徽工商个人基本信息
                      return (
                        <JbxxForm_ahgs
                          ref="refJbxxForm_ahgs"
                          jbxxFormData={this.state.jbxxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          userBaseInfo={this.state.userBaseInfo}
                          stuSsxx={this.state.stuSsxx}
                          editXszp={this.state.editXszp}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "jbxx_hnyz" && item.SFXS == "1") {
                      return (
                        <JbxxForm_hnyz
                          ref="refJbxxForm_hnyz"
                          jbxxFormData={this.state.jbxxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          userInfoData={this.state.userBaseInfo}
                          stuSsxx={this.state.stuSsxx}
                          editXszp={this.state.editXszp}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "lxxx" && item.SFXS == "1") {
                      return (
                        <LxfsForm
                          ref="refJbxxForm"
                          lxxxFormData={this.state.lxxxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          userInfoData={this.state.userBaseInfo}
                          stuSsxx={this.state.stuSsxx}
                          editXszp={this.state.editXszp}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "jbxx_sjz" && item.SFXS == "1") {
                      return (
                        <JbxxForm_sjz
                          ref="refJbxxForm"
                          jbxxFormData={this.state.jbxxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          userInfoData={this.state.userBaseInfo}
                          stuSsxx={this.state.stuSsxx}
                          editXszp={this.state.editXszp}
                          kzxxInitData={this.state.kzxxInitData.zdglVOs}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "jbxx_whgc" && item.SFXS == "1") {
                      return (
                        <JbxxForm_whgc
                          ref="refJbxxForm"
                          jbxxFormData={this.state.jbxxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          userInfoData={this.state.userBaseInfo}
                          stuSsxx={this.state.stuSsxx}
                          editXszp={this.state.editXszp}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "jtxx" && item.SFXS == "1") {
                      return (
                        <JtcyForm
                          ref="refJtcyForm"
                          jtcyInitData={jtcyInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }

                    if (item.FLBS == "jtxx_ahgs" && item.SFXS == "1") {
                      return (
                        <JtcyForm_ahgs
                          ref="refJtcyForm_ahgs"
                          jtcyInitData={jtcyInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }

                    if (item.FLBS == "jyjl" && item.SFXS == "1") {
                      return (
                        <JyjlForm_ahgs
                          ref="refJyjlForm_ahgs"
                          jyjlInitData={this.state.jyjlInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }

                    if (item.FLBS == "zyshgx" && item.SFXS == "1") {
                      return (
                        <ZyshgxForm_ahgs
                          ref="refZyshgxForm_ahgs"
                          zyshgxInitData={this.state.zyshgxInitData}
                          getChildComponent={this.getChildComponent}
                          cacheFormData={this.cacheFormData}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }

                    if (item.FLBS == "zyzxx" && item.SFXS == "1") {
                      return (
                        <ZyzxxForm
                          ref="refZyzxxForm"
                          getChildComponent={this.getChildComponent}
                        />
                      );
                    }
                    if (item.FLBS == "hjqk" && item.SFXS == "1") {
                      return (
                        <HjqkForm_gxxx
                          ref="refHjqkForm"
                          getChildComponent={this.getChildComponent}
                          hjqkInitData={hjqkInitData}
                          cacheFormData={this.cacheFormData}
                          SFBT={item.SFBT}
                          blztFlag={this.state.blztFlag}
                        />
                      );
                    }
                    if (item.FLBS == "kzxx" && item.SFXS == "1") {
                      return (
                        <KzxxForm
                          ref="refKzxxForm"
                          getChildComponent={this.getChildComponent}
                          reloadMenu={this.reloadMenu}
                          xsid={this.state.xsid}
                          zzbdhjid={this.state.zzbdhjid}
                          initData={this.state.kzxxInitData}
                          getData4Yxwz={this.getData4Yxwz}
                        />
                      );
                    }
                  })}

                  <div className="p-sw text-center mt-30">
                    {this.state.blztFlag == "0" && (
                      <Button type="primary" onClick={this.handelData}>
                        提交
                      </Button>
                    )}
                    {this.state.blztFlag == "1" && <Button>已提交</Button>}
                    <NextButton
                      nextUrl={this.state.nextUrl}
                      search={this.state.search}
                    />
                    {this.state.blztFlag == "1" && (
                      <div style={{ color: "red", margin: "10px" }}>
                        提示：该环节已提交完成
                      </div>
                    )}
                  </div>
                </div>
              </Spin>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip
            hjmc={"个人信息填写"}
            key={"GD001_3"}
            mkbz="GD001_3"
            nonOperationalData={this.state.nonOperationalData}
          />
        )}
      </div>
    );
  }
}
