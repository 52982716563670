import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import {
  Button,
  Checkbox,
  Col,
  Modal,
  Row,
  message,
  Alert,
  Spin,
  Radio,
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import SelectBox from './selectBox';
import SelectModal from './selectModal';
import './index.css';
import NHFetch from '@utils/NHFetch';
import getSize from '@utils/getSize';
import createHistory from 'history/createHashHistory';
import { getLoginUser, getLoginUserInfo } from '@utils/NHCore';
import CommonTip from '../common';
import NextButton from '@pages/SelfHelp/common/nextIndex';
import { mkbz } from '@pages/Assembly/siderMenu/menuObj';
import Hint from '@pages/SelfHelp/common/hint';
import { NHConfirm } from '@components/common/NHModal';

const history = createHistory();
const confirm = Modal.confirm;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;

/**
 * @author weishihuai
 * @date 2018/7/6
 * @time 14:41
 * @Description: 迎新网站-自助报到-宿舍分配
 */
export default class Dorm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mkbz: 'GD006',
      hjmc: '',
      xzId: '',
      _xzId: '',
      cwsList: [], //床位数
      sfbzList: [], //收费标准
      xqList: [], //校区信息
      currentXqid: '', //当前校区ID
      fjxxList: [], //房间信息
      currentClickCwPkid: '', //当前床位信息ID
      xsssruYwxx: {}, //学生宿舍入住业务信息
      isHjLinked: false, //环节是否办理状态
      param: {}, //过滤条件
      scrollHeight: 200, //默认滚动高度
      loginUserInfo: {}, //当前登录学生信息
      nextUrl: '', //左侧菜单加载完后传进来
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      autoBlLoading: false, //自动办理按钮载入状态
      cancelLoading: false, //取消办理按钮载入状态
      zdrzLoading: false, //自动入住载入状态
      sfbz: '', //收费标准选中值
      sfmy: '', //是否满员选中值
      setRoom: [], //过滤房间信息
      rootFlag: false,
    };
  }

  componentWillMount() {
    let that = this;
    this.setState({ scrollHeight: getSize().windowH - 206 });
    window.onresize = function () {
      that.setState({
        scrollHeight: getSize().windowH - 206,
      });
    };
  }

  componentDidMount() {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    NHFetch('api/zhxg-yxwz/newStudent/' + xsid + '/getYxwzUserInfoByXsid', 'GET')
        .then(res => {
            if (res && res.data) {
                this.queryQztjAndYlhj((operable) => {
                    if (operable) {
                    //   let xsid = getLoginUser() ? getLoginUser().userId : '';
                      if (xsid) {
                        let _this = this;
                        // getLoginUserInfo(xsid, (data) => {
                          _this.setState(
                            {
                              loginUserInfo: res.data,
                            },
                            () => {
                              const { loginUserInfo } = _this.state;
                              const xqid = loginUserInfo.XQID,
                                xbm = loginUserInfo.XBM;
              
                              if (xsid) {
                                //办理状态根据 关联现场环节和是否反向关联修改 不根据现场状态
                                //_this.updateZzbdBlzt();
                                //加载收费标准、床位数信息
                                _this.getSfbzAndCwsInfo(xsid, xqid, xbm);
                                _this.getXsssrzYwxxByXsid(xsid);
                                //如果学生校区ID不为空,则只加载本校区宿舍信息
                                if (xqid) {
                                  _this.setState({
                                    currentXqid: xqid,
                                  });
                                  _this.getFjxxList({
                                    cws: '',
                                    sfbz: '',
                                    xsid: xsid,
                                    xqid: xqid,
                                    xbm: xbm,
                                  });
                                }
                              }
                              _this.checkZzbdisLinked();
                            }
                          );
                        // });
                      }
                    }
                  });
            } else {
                message.error("抱歉，未能查询到相关学生信息，请稍后重试！");
            }
        }) 
    
  }

  //迎新现场已办理时迎新网站自动办理
  updateZzbdBlzt = () => {
    let zzbdhjid = this.getQueryString('pkid');
    NHFetch('/zzbd/updateZzbdBlzt', 'GET', {
      zzbdhjid: zzbdhjid,
      hjdm: 'GD006',
    }).then((res) => {
      if (res.data) {
        this.reloadMenu();
      }
    });
  };

  //查询前置条件和依赖环节
  queryQztjAndYlhj = (callback) => {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false,
      });
      return;
    }
    let zzbdhjid = this.getUrlParams('pkid');
    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', {
      xsid: xsid,
      zzbdhjid: zzbdhjid,
    }).then((res) => {
      if (res && res.data) {
        let kfsj = res.data['kfsj'];
        let ylhj = res.data['ylhj'];
        let qzhj = res.data['qzhj'];
        let nohj = res.data['nohj'];
        this.setState({ nonOperationalData: res.data });
        if (kfsj && kfsj['YXSJ'] != '1') {
          this.setState({ operable: false });
        }

        if (ylhj && ylhj.length > 0) {
          this.setState({ operable: false });
        }

        if (qzhj && qzhj.length > 0) {
          this.setState({ operable: false });
        }

        if (nohj) {
          this.setState({ operable: false });
       }

        if (callback) {
          callback(this.state.operable);
        }
      }
    });
  };

  setMenuList = (menuList) => {
    let nextCount = 0;
    let bz = this.state.mkbz;
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC;
        this.setState({ hjmc: hjmc });
        nextCount = index + 1;
        return;
      }
    });
    let menu = menuList[nextCount];
    if (menu) {
      let hjdm = menu.HJDM;
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm];
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID,
        });
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink';
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
        });
      }
    } else {
      this.setState({
        nextUrl: '',
        search: '',
      });
    }
  };

  //获取url中传递的参数
  getUrlParams = (param) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(
          location.href
        ) || [, ''])[1].replace(/\+/g, '%20')
      ) || null
    );
  };

  //获取收费标准、床位信息等
  getSfbzAndCwsInfo = (xsid, xqid, xbm) => {
    NHFetch('/gyxtUnauth/cwfp/getYxxcSfbzAndSslxInfo', 'GET', {
      xqid: xqid ? xqid : '',
      xsid: xsid,
      xbm: xbm,
    }).then((res) => {
      if (res) {
        const data = res.data;
        this.setState({
          cwsList: data.cwsList,
          sfbzList: data.sfbzList,
          xqList: data.xqList,
          rootFlag: true
        });

        //如果学生无校区信息,默认加载第一个Tab校区的宿舍信息
        if (!xqid) {
          this.setState({
            currentXqid: data.xqList[0].XQID,
          });
          this.getFjxxList({
            cws: '',
            sfbz: '',
            xsid: xsid,
            xqid: data.xqList[0].XQID,
            xbm: xbm,
          });
        }
      }
    });
  };

  //获取宿舍房间信息
  getFjxxList = (param) => {
    NHFetch('/gyxtUnauth/cwfp/getYxwzSsfpFjxxList', 'GET', param).then(
      (res) => {
        if (res) {
          this.setState(
            {
              fjxxList: res.data,
              rootFlag: true
            },
            () => {
              this.fitlerRoom();
            }
          );
        }
      }
    );
  };

  // 过滤可以选择的房间信息
  fitlerRoom = () => {
    let kyxzRoom = [];
    // console.log("获取房间信息-------",res.data);
    let fjxxList = this.state.fjxxList;
    fjxxList.map((item, index) => {
      if (Number(item.yxNum) < Number(item.allNum)) {
        // console.log("未住满的房间(个数判断)------",item);
        if (item.bedList && item.bedList.length > 0) {
          item.yxwzCwxxTzVOs.map((item2, index2) => {
            console.log('item2', item2);
            if (item2.xz == '0') {
              // console.log("可以选择的床位---------",item2,item);
              kyxzRoom.push(item);
            }
          });
        }
      }
    });
    let setRoom = [];
    if (kyxzRoom.length > 0) {
      setRoom = Array.from(new Set(kyxzRoom));
      // setRoom = Array.from(setRoom);
    }
    console.log('过滤后的房间------', kyxzRoom, setRoom);
    this.setState({
      setRoom: setRoom,
    });
  };

  //查询学生宿舍入住信息
  getXsssrzYwxxByXsid = (xsid) => {
    NHFetch('/gyxtUnauth/cwfp/getXsssrzYwxxByXsid', 'GET', {
      xsid: xsid,
    }).then((res) => {
      if (res && res.data) {
        this.setState({
          xsssruYwxx: res.data,
        });
      }
    });
  };

  //确认床位分配
  confirmCwfpxx = (xzId) => {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xqid = loginUserInfo.XQID,
      xbm = loginUserInfo.XBM;

    if (!xzId) {
      message.warning('请先选择一个床位！');
      return;
    }

    //检查该床位是否被其他学生抢占(防并发的情况下同一个床位被其他人重复选择)
    NHFetch('/gyxtUnauth/cwfp/checkCwIsPermitSelect', 'GET', {
      cwid: xzId,
      xsid: xsid,
    }).then((res) => {
      if (res) {
        if (res.data === 0) {
          message.error('抱歉，该床位已经被其他同学抢占，请选择其他床位！');
          return;
        } else {
          //确认床位信息
          this.cwfpSubmit(xsid, xzId, xqid, xbm);
        }
      }
    });
  };

  //床位分配提交
  cwfpSubmit = (xsid, currentClickCwPkid, xqid, xbm) => {
    let zzbdhjid = this.getUrlParams('pkid');
    let params = { xsid: xsid, cwid: currentClickCwPkid, zzbdhjid: zzbdhjid };

    NHFetch('/gyxtUnauth/cwfp/checkCwIsAvailableSelected', 'GET', {
      cwid: currentClickCwPkid,
    }).then((res) => {
      if (res) {
        if (res.data) {
          NHFetch('/gyxtUnauth/cwfp/yxwzConfirmCwxx', 'POST', params).then(
            (res) => {
              if (res) {
                if (res.data) {
                  message.success('分配成功！');
                  this.reloadMenu();
                  this.setState({
                    currentClickCwPkid: '',
                  });

                  this.getFjxxList({
                    cws: '',
                    sfbz: '',
                    xsid: xsid,
                    xqid: xqid
                      ? xqid
                      : this.state.currentXqid
                      ? this.state.currentXqid
                      : this.state.xqList[0].XQID,
                    xbm: xbm,
                  });

                  this.getXsssrzYwxxByXsid(xsid);

                  this.checkZzbdisLinked();
                } else {
                  message.error('分配失败，请稍后重试！');
                }
              }
            }
          );
        } else {
          message.error(
            '抱歉，该床位未到分配时间或已过床位分配时间期限，请选择其他床位! '
          );
        }
      }
    });
  };

  //选择床位弹层
  showConfirm = (value, id, cwh) => {
    confirm({
      title: <span>确定选择{value.name}房间的下列床铺？</span>,
      content: (
        <SelectModal
          data={value}
          id={id}
          currentClickCwh={cwh}
          onChange={(value) => {
            this.setState({
              xzId: value.id,
              currentClickCwPkid: value.id,
            });
          }}
        />
      ),
      onOk: () => {
        const { currentClickCwPkid } = this.state;
        //确定分配宿舍提交
        this.confirmCwfpxx(currentClickCwPkid);
      },
      onCancel: () => {
        this.setState({
          currentClickCwPkid: '',
        });
      },
    });
  };

  judeageCs = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    NHFetch('/gyxtUnauth/cwfp/yxwzSyzxcs', 'GET', { xsid: xsid }).then(
      (res) => {
        if (res && res.data === -1) {
          this.handleCancelListener();
        } else if (res && res.data === 0) {
          message.error('自选宿舍次数已用完，不可再取消办理');
          /* NHConfirm("自选宿舍次数已用完，取消后不能再进行自选宿舍操作！确定取消？",() => {
            this.handleCancelListener();
           },"warn"); */
        } else if (res && res.data !== -1 && res.data !== 0) {
          NHConfirm(
            '确定取消？取消后还剩' + res.data + '次可选',
            () => {
              this.handleCancelListener();
            },
            'warn'
          );
        }
      }
    );
  };

  //取消办理事件
  handleCancelListener = () => {
    this.setState({
      cancelLoading: true,
    });
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xqid = loginUserInfo.XQID,
      xbm = loginUserInfo.XBM;

    let zzbdhjid = this.getUrlParams('pkid');
    let params = { xsid: xsid, zzbdhjid: zzbdhjid };
    NHFetch('/gyxtUnauth/cwfp/cancelBlSsfp', 'POST', params)
      .then((res) => {
        if (res && res.data === 1) {
          message.success('取消办理成功！');
          this.reloadMenu();
          this.getFjxxList({
            cws: '',
            sfbz: '',
            xsid: xsid,
            xqid: xqid
              ? xqid
              : this.state.currentXqid
              ? this.state.currentXqid
              : this.state.xqList[0].XQID,
            xbm: xbm,
          });

          this.checkZzbdisLinked();

          this.setState({
            xsssruYwxx: {},
            cancelLoading: false,
          });
        } else {
          message.error('取消办理失败，请稍后重试！');
          this.setState({
            cancelLoading: false,
          });
        }
      })
      .catch(() => {
        message.error('取消办理失败，请稍后重试！');
        this.setState({
          cancelLoading: false,
        });
      });
  };

  //自动办理事件
  handleAutoDistributeListener = () => {
    this.setState({
      autoBlLoading: true,
    });
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xqid = loginUserInfo.XQID,
      xbm = loginUserInfo.XBM;
    let zzbdhjid = this.getUrlParams('pkid');

    if (!xsid) {
      message.warning('自动办理失败,请稍后重试！');
      return;
    }

    const { param } = this.state;
    //自动分配宿舍
    let params = {
      cws: param.cws || '',
      sfbz: param.sfbz || '',
      xsid: xsid,
      xqid: xqid
        ? xqid
        : this.state.currentXqid
        ? this.state.currentXqid
        : this.state.xqList[0] && this.state.xqList[0].XQID,
      xbm: xbm,
      zzbdhjid: zzbdhjid,
    };

    NHFetch('/gyxtUnauth/cwfp/yxwzAutoDistributeSscwxx', 'POST', params)
      .then((res) => {
        if (res) {
          if (res.data === 1) {
            let text = '';
            NHFetch('/gyxtUnauth/cwfp/getXsssrzYwxxByXsid', 'GET', {
              xsid: xsid,
            }).then((res) => {
              if (res && res.data) {
                let data = res.data;
                this.setState({
                  xsssruYwxx: data,
                });
                text = data.ldmc + '-' + data.fj + '-' + data.cwh + '号床位';
                message.success('自动办理成功,分配宿舍为 ' + text + '！');
                this.reloadMenu();
                this.getFjxxList({
                  cws: '',
                  sfbz: '',
                  xsid: xsid,
                  xqid: xqid
                    ? xqid
                    : this.state.currentXqid
                    ? this.state.currentXqid
                    : this.state.xqList[0].XQID,
                  xbm: xbm,
                });

                this.getXsssrzYwxxByXsid(xsid);
                this.checkZzbdisLinked();

                this.setState({
                  autoBlLoading: false,
                });
              } else {
                message.error('自动办理失败,请稍后重试！');
                this.setState({
                  autoBlLoading: false,
                });
              }
            });
          }
        } else {
          // message.error("自动办理失败,请稍后重试！");
          this.setState({
            autoBlLoading: false,
          });
        }
      })
      .catch(() => {
        message.error('自动办理失败,请稍后重试！');
        this.setState({
          autoBlLoading: false,
        });
      });
  };
  getQueryString = (name) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
          location.href
        ) || [, ''])[1].replace(/\+/g, '%20')
      ) || null
    );
  };
  //检查环节是否已办理
  checkZzbdisLinked = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    let zzbdhjid = this.getUrlParams('pkid');
    NHFetch('/zzbdCommon/checkIsZzbdHjLinked', 'GET', {
      xsid: xsid,
      zzbdhjid: zzbdhjid,
    }).then((res) => {
      console.log('公寓', res.data);
      if (res) {
        if (res.data) {
          this.setState({
            isHjLinked: res.data,
          });
        } else {
          this.setState({
            isHjLinked: false,
          });
        }
      } else {
        this.setState({
          isHjLinked: false,
        });
      }
    });
  };

  //自动入住事件
  autoSelectCwListener = (value) => {
    this.setState({
      zdrzLoading: true,
    });
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xqid = loginUserInfo.XQID,
      xbm = loginUserInfo.XBM;

    const { yxwzCwxxTzVOs, name } = value;
    let zzbdhjid = this.getUrlParams('pkid');
    let availableSelectArr = [],
      availSelectCwPkId = '',
      text = '';

    for (let item of yxwzCwxxTzVOs) {
      availableSelectArr.push(item.xz);
    }

    if (availableSelectArr.indexOf('0') === -1) {
      message.warning('抱歉，该房间已满员，请选择其他房间！');
      return;
    }

    for (let item of yxwzCwxxTzVOs) {
      //床位未被选择并且未过分配期限
      if (item.xz === '0' && !item.expire) {
        availSelectCwPkId = item.id;
        text = name + '-' + item.cwh;
        break;
      }
    }

    if (availSelectCwPkId) {
      NHFetch('/gyxtUnauth/cwfp/checkCwIsPermitSelect', 'GET', {
        cwid: availSelectCwPkId,
        xsid: xsid,
      }).then((res) => {
        if (res) {
          if (res.data === 0) {
            message.error('抱歉，该床位已经被其他同学抢占，请选择其他床位！');
            this.setState({
              zdrzLoading: false,
            });
            return;
          } else {
            // 确认床位信息
            NHFetch('/gyxtUnauth/cwfp/yxwzConfirmCwxx', 'POST', {
              xsid: xsid,
              cwid: availSelectCwPkId,
              zzbdhjid: zzbdhjid,
            }).then((res) => {
              if (res) {
                if (res.data) {
                  message.success(
                    '分配成功，分配宿舍为 ' + text + ', 请尽快确认办理！'
                  );
                  this.reloadMenu();
                  this.getFjxxList({
                    cws: '',
                    sfbz: '',
                    xsid: xsid,
                    xqid: xqid
                      ? xqid
                      : this.state.currentXqid
                      ? this.state.currentXqid
                      : this.state.xqList[0].XQID,
                    xbm: xbm,
                  });

                  this.getXsssrzYwxxByXsid(xsid);
                  this.checkZzbdisLinked();

                  this.setState({
                    zdrzLoading: false,
                  });
                }
              } else {
                message.error('分配失败，请稍后重试！');
                this.setState({
                  zdrzLoading: false,
                });
              }
            });
          }
        }
      });
    } else {
      message.warning('抱歉，未匹配到合适的床位信息,请选择其他床位！');
      this.setState({
        zdrzLoading: false,
      });
    }
  };

  //收费标准onChange事件
  onSfbzChangeListener = (checkedValues) => {
    const { param, currentXqid, sfmy } = this.state;
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xbm = loginUserInfo.XBM;
    param.sfbz = checkedValues.length > 0 ? checkedValues.join(',') : '';
    param.xsid = xsid;
    param.xqid = currentXqid ? currentXqid : this.state.xqList[0].XQID;
    param.xbm = xbm;
    param.sfmy = sfmy;
    this.setState({
      sfbz: checkedValues.length > 0 ? checkedValues.join(',') : '',
    });
    this.getFjxxList(param);
  };

  //是否满员onChange事件
  onSfmyChangeListener = (e) => {
    const { param, currentXqid, sfbz } = this.state;
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xbm = loginUserInfo.XBM;
    param.sfbz = sfbz;
    param.xsid = xsid;
    param.xqid = currentXqid ? currentXqid : this.state.xqList[0].XQID;
    param.xbm = xbm;
    param.sfmy = e.target.value;
    this.setState({
      sfmy: e.target.value,
    });
    this.getFjxxList(param);
  };

  //宿舍类型onChange事件
  onCwsChange = (checkedValues) => {
    const { param, currentXqid } = this.state;
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xbm = loginUserInfo.XBM;
    param.cws = checkedValues.length > 0 ? checkedValues.join(',') : '';
    param.xsid = xsid;
    param.xbm = xbm;
    param.xqid = currentXqid ? currentXqid : this.state.xqList[0].XQID;
    this.getFjxxList(param);
  };

  //tab切换事件
  tabChangeListener = (activeKey) => {
    let xsid = getLoginUser() ? getLoginUser().userId : '';
    const { loginUserInfo } = this.state;
    const xbm = loginUserInfo.XBM;
    const { cws, sfbz } = this.state.param;

    this.setState({
      currentXqid: activeKey,
    });

    //加载房间信息
    this.getFjxxList({
      cws: cws || '',
      sfbz: sfbz || '',
      xsid: xsid,
      xqid: activeKey,
      xbm: xbm,
    });
  };

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList();
  };

  /*  handleNextRoom = (RoomList) =>{
        const {fjxxList,setRoom} = this.state;
        console.log("当前宿舍信息-----------",RoomList,fjxxList,setRoom);
        let list = [];
        fjxxList.map((item,index)=>{
            if(item.fjid == "cba18dd6-ac51-4119-b35e-1f2d9eb30bf7"){
                item. yxNum = '8';
            }
            list.push(item);
        })
        console.log("模拟修改-----------",list);
        this.setState({
            fjxxList:list
        },()=>{
            this.fitlerRoom();
        })
    } */

  render() {
    const {
      xzId,
      _xzId,
      cwsList,
      xqList,
      sfbzList,
      fjxxList,
      setRoom,
      xsssruYwxx,
      isHjLinked,
      scrollHeight,
      currentXqid,
      autoBlLoading,
      cancelLoading,
      zdrzLoading,
    } = this.state;
    //学生已选宿舍信息
    const { xqmc, ldmc, fj, cwh } = xsssruYwxx;
    let yxssInfo =
      (xqmc || '') +
      '-' +
      (ldmc || '') +
      '-' +
      (fj || '') +
      '-' +
      (cwh || '') +
      '号床';
    let fsmyList = [
      { label: '未满', value: '0' },
      { label: '满员', value: '1' },
    ];
    let RoomList = setRoom;
    /* let RoomList = []
        if(setRoom.length > 1){
            RoomList.push(setRoom[0]);
        }else{
            RoomList = setRoom;
        } */
    return (
      <div>
        {this.state.operable ? (
          <Flex className="w1180 mt-20" align="start">
            <SiderMenu
              ref={'siderMenu'}
              setMenuList={this.setMenuList}
              title="自助报到"
              mkbz={this.state.mkbz}
              /*   active={5}*/
              menuType="自助报到"
            />
            <Flex.Item className="page_con_r" styleName="">
              <Breadcrumb
                noLine
                list={[
                  { name: '首页', url: '/index' },
                  { name: '自助报到', url: '/register' },
                  { name: this.state.hjmc },
                ]}
              />
              <div className="box">
                <Hint />
                <TitleTop
                  className="mt-20"
                  title={this.state.hjmc}
                  extra={false}
                >
                  {!isHjLinked ? (
                    <Button
                      className="ml-10"
                      type="primary"
                      ghost
                      loading={autoBlLoading}
                      onClick={this.handleAutoDistributeListener}
                    >
                      自动办理
                    </Button>
                  ) : (
                    <Button
                      className="ml-10"
                      type="danger"
                      ghost
                      loading={cancelLoading}
                      onClick={this.judeageCs}
                    >
                      取消办理
                    </Button>
                  )}
                </TitleTop>
                <div className="p-sw">
                  <Flex>
                    <span>收费标准：</span>
                    <Flex.Item>
                      <CheckboxGroup
                        style={{ width: '100%' }}
                        onChange={this.onSfbzChangeListener}
                      >
                        <Row>
                          {sfbzList.map((elem, index) => {
                            return (
                              <Col key={index} span={4}>
                                <Checkbox value={elem.value}>
                                  {elem.label}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </CheckboxGroup>
                    </Flex.Item>
                  </Flex>
                  <Flex>
                    <span>是否满员：</span>
                    <Flex.Item>
                      <RadioGroup
                        style={{ width: '100%' }}
                        onChange={this.onSfmyChangeListener}
                        defaultValue={'0'}
                      >
                        <Row>
                          {fsmyList.map((elem, index) => {
                            return (
                              <Col key={index} span={4}>
                                <Radio value={elem.value}>{elem.label}</Radio>
                              </Col>
                            );
                          })}
                        </Row>
                      </RadioGroup>
                    </Flex.Item>
                  </Flex>
                  {/* <Flex className="mt-10">
                                    <span>宿舍类型：</span>
                                    <Flex.Item>
                                        <CheckboxGroup
                                            style={{width: '100%'}}
                                            onChange={this.onCwsChange}
                                        >
                                            <Row>
                                                {cwsList.map(
                                                    (elem, index) => {
                                                        return (
                                                            <Col
                                                                key={index}
                                                                span={4}
                                                            >
                                                                <Checkbox
                                                                    value={
                                                                        elem.value
                                                                    }
                                                                >
                                                                    {elem.label}
                                                                </Checkbox>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </CheckboxGroup>
                                    </Flex.Item>
                                </Flex> */}
                  {isHjLinked && (
                    <div>
                      <Alert
                        message={'当前选择宿舍为: ' + yxssInfo}
                        type="info"
                        showIcon
                      />
                    </div>
                  )}
                  {this.state.rootFlag && <Spin spinning={zdrzLoading}>
                    <div styleName="tab_box">
                      <SelectBox
                        Data={RoomList}
                        xzId={_xzId}
                        showConfirm={this.showConfirm}
                        onChange={(value) => {
                          this.setState({
                            xzId: value.id,
                            currentClickCwPkid: value.id,
                          });
                        }}
                        autoSelectCwListener={(value) =>
                          this.autoSelectCwListener(value)
                        }
                        scrollHeight={scrollHeight}
                        tabChangeListener={this.tabChangeListener}
                        xqList={xqList}
                        currentXqid={currentXqid}
                      />
                    </div>
                  </Spin>}
                </div>
                <div className="text-center mt-50">
                  {/* {fjxxList.length >1 && <Button type="primary" onClick={()=>this.handleNextRoom(RoomList)} >下一个宿舍</Button>} */}
                  <NextButton
                    nextUrl={this.state.nextUrl}
                    search={this.state.search}
                  />
                </div>
              </div>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip
            hjmc={'自选宿舍'}
            key={'GD006'}
            mkbz="GD006"
            nonOperationalData={this.state.nonOperationalData}
          />
        )}
      </div>
    );
  }
}
