import React, { Component } from 'react'
import '../../../../../assets/css/dormV2/RoomDetail.css'
import { cloneDeep } from 'lodash-es'
import NHFetch from '@utils/NHFetch'
import bed01 from '../../../../../assets/images/dormV2/bed01.png'
import bed02 from '../../../../../assets/images/dormV2/bed02.png'
import bed03 from '../../../../../assets/images/dormV2/bed03.png'
import bed04 from '../../../../../assets/images/dormV2/bed04.png'
import { message, Spin } from 'antd'

export default class RoomDetail extends Component {
  state = {
    fjxx: {},
    cwxxList: [],
    currentCwh: '',
    loading: false
  }

  componentDidMount() {
    this.getFjxx()
  }
  //图片显示
  buildImageUrl = cwxx => {
    if (cwxx.selected) {
      return bed04
    }

    const img = {
      kx: bed03,
      bkx: bed01,
      yx: bed02
    }
    return img[cwxx.xz]
  }

  // 获取房间床位信息
  getFjxx = () => {
    const { fjid } = this.props
    this.setState({ loading: true })
    NHFetch('/ssfp/getFjxxByFjid', 'get', { fjid }).then(res => {
      if (res && res.code === 200) {
        const cwxxList = cloneDeep(res.data.cwxxList)
        cwxxList.forEach(item => {
          // 添加字段用于判断床位是否选择
          item.selected = false
        })
        this.setState({ cwxxList, fjxx: res.data, loading: false })
      }
    })
  }

  // 选择床位
  selectBed = cwxx => {
    const { cwxxList } = this.state
    return () => {
      if (cwxx.xz !== 'kx') {
        message.info('当前床位无法选择', 1)
      } else {
        const newCwxxList = cloneDeep(cwxxList)
        newCwxxList.forEach(item => {
          item.selected = false
          item.cwid === cwxx.cwid && (item.selected = true)
        })
        this.setState({ cwxxList: newCwxxList, currentCwh: cwxx.cwh })
      }
    }
  }

  // 确定选择床位
  confirmSelect = () => {
    const { cwxxList } = this.state
    const { zzbdhjid } = this.props
    const filterArr = cwxxList.filter(item => item.selected)
    return new Promise((resolve, reject) => {
      if (!filterArr.length) {
        message.info('请先选择床位', 1)
        reject()
      } else {
        const cwid = filterArr[0].cwid
        NHFetch('/ssfp/yxwzConfirmCwxx', 'post', {
          cwid,
          zzbdhjid
        }).then(res => {
          if (res && res.code === 200) {
            message.success('选择床位成功')
            resolve('success')
          } else {
            reject()
          }
        })
      }
    })
  }

  render() {
    const { cwxxList, currentCwh, fjxx, loading } = this.state
    const { lc_fh } = this.props
    const data = cwxxList.map((cwxx, i) => ({
      icon: this.buildImageUrl(cwxx),
      text: cwxxList.length > 9 ? `${cwxx.cwh}床` : `0${cwxx.cwh}床`,
      data: cwxx
    }))

    return (
      <div className='roomDetail'>
        <div className="info">
          <div className="row first">总床位：{fjxx.cws}</div>
          <div className="row">
            剩余床位：<span style={{ color: Number(fjxx.kxcws) ? '#397ef0' : '#ff4d4f' }}>{fjxx.kxcws}</span>
          </div>
          {fjxx.sfxssfbz && fjxx.sfxssfbz === '1' && <div className="row">费用：{fjxx.sfbz || '暂未维护'}</div>}
        </div>

        <div className="bed-box-top">
          <div className="toChose">
            {currentCwh ? (
              <React.Fragment>
                <div className="icon icon-active"></div>
                <div className="decription">
                  已选择{lc_fh}
                  {currentCwh > 9 ? `${currentCwh}床` : `0${currentCwh}床`}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="icon"></div>
                <div className="decription">请选择床位</div>
              </React.Fragment>
            )}
          </div>
          <div className="IscanChose">
            <div className="yesLeft">
              <p>
                <span></span>
                可选
              </p>
            </div>
            <div className="noRight">
              <p>
                <span></span>
                已选
              </p>
            </div>
            <div className="noMiddle">
              <p>
                <span></span>
                不可选
              </p>
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <div class="bed-box-wrapper">
            <div className="bed-box">
              {data.map(item => {
                return (
                  <div key={item.text} class="bed" style={{ backgroundImage: `url(${item.icon})` }} onClick={this.selectBed(item.data)}>
                    {item.text}
                  </div>
                )
              })}
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}
