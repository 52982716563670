import React from 'react';
import PropTypes, { number } from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { Row, Col, Button, Icon, Card, Form, Input, Divider, DatePicker, message, Alert, Spin ,Modal} from 'antd';
import { getLoginUser, hasAuth } from "@utils/NHCore";
import NHFormItem from '@components/common/NHFormItem/index';
import FormRules from '@components/common/NHFormItem/FormRules';
import NHFetch from "@utils/NHFetch";
import moment from 'moment';
import NHInputRender from '@components/common/NHInputPicker/render';
import './index.css';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { Link } from 'react-router-dom';
import { Flex } from '@components';

const FormItem = Form.Item;
const basicUrl = "/zzbd";
const stuInfoUrl = "/xxcj/";


//日期格式化
const dateFormat = 'YYYY-MM-DD';
class KzxxFormComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xsid: '',
            kzxx: false,
            scrollHeight: 0, //默认滚动高度
            initData: {}, //初始数据
            loading: false, //页面加载状态
            onSubmit: false, //保存按钮加载状态
            isShow: false,
            previewVisible: false,
            formData: '',
            dmkmcList: {},
            zdzmc: ''
        }
    }

    onSaveKzxx = (cb) => { 
        this.props.form.validateFields((error, values) => {
            if (error) {
                return;
            }
            this.setState({
                formData: { ...values },
                kzxx: false
            });
            const { cacheFormData, getChildComponent } = this.props;
            getChildComponent(2, true); 
            let xxcjData = {};
            Object.keys(values).forEach(function(key){ 
                let keys = "xxcj_"+key;
                if(values[key]!=null && values[key]!=undefined){
                    xxcjData[keys] = values[key]; // 这里的name为目标属性名
                     if(values[key].constructor.name == 'Moment'){   //处理Moment格式的数据  
                        //values[key] = moment(values[key]).format(dateFormat); 
                    } else if(values[key].constructor.name == 'Array'){ 
                        if (typeof values[key] === 'object') { 
                            let isFileUpload = false;
                            let array = [];
                            for (let item of values[key]) {
                                if (item.hasOwnProperty('uuid') && item['uuid']) {
                                    isFileUpload = true;
                                    array.push(item['uuid']) 
                                }
                            }
                            if (isFileUpload) {
                                // values[key]= array.join(',');
                                xxcjData[keys] = array.join(',');// 这里的name为目标属性名
                            }else{
                                // values[key] =  values[key].join(',');
                                xxcjData[keys] = values[key].join(','); // 这里的name为目标属性名
                            }
                        }else{ 
                            // values[key] =  values[key].join(',');
                            xxcjData[keys] = values[key].join(',');
                        } 
                    } 
                 }else{
                    // values[key]='';
                    xxcjData[keys]='';
                 }
            })
            let postData = {
                'xxcj': { 'xsid': this.props.xsid, 'formData': { ...xxcjData }},
                'params': { 'zzbdhjid': this.props.zzbdhjid, 'xsid': this.props.xsid }
            };
        let flbs ="";
        // let xsxx = {'xxcj_zdyhksfqy':values.ZDYHKSFQY?"1":"0","xxcj_zdysyd":values.ZDYSYD,"xxcj_zdyhklx":values.ZDYHKLX};
        let xsxx = { ...xxcjData } 
        flbs = "kzxx"
        // xsxx['xm'] = '卡瓦';
        let xsInfo4YxwzVO = {  
            xsid:this.props.xsid,
            xsxx,
            flbs
            } 
            NHFetch('/newStudent/saveOrUpdateXsInfo', 'POST', xsInfo4YxwzVO)
                .then(res => {
                    if (res && res.code == 200) {
                        message.success("保存成功");
                        cb(true);
                        // this.props.reloadMenu();
                        this.props.getData4Yxwz();
                    } else {
                        message.error("保存失败");
                    }
                });
        });
    }

    //控件回调
    onChange = (date, dateString) => {
        // console.log('date', date)
        // console.log('dateString', dateString)
        // if(date && dateString){
        //     //this.
        // }
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz, zdbz) => { 
        NHFetch("/proData/selectDataList", "GET", { sign: dmbz })
            .then(res => { 
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[zdbz] = res.data;
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }
      //获取联级下拉列表value对应的名称
    getCascaderDmkMc = (level,sign, cascaderValue,zdbz) => { 
        NHFetch("/proData/selectCascaderList", "GET", { level:level,sign: sign ,cascaderValue:cascaderValue})
            .then(res => {  
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[level+zdbz] = res.data; 
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }
    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => { 
        if (fjmc==="isImage") {
            this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };

    //打开图片弹窗
    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }
    // 关闭图片弹窗
    closeModal = () => this.setState({ previewVisible: false });
    // 文件展示 
    fileLists = (fileLists) => {
            {
                let items=[];
                for(let key in fileLists){
                    let fileList = typeof fileLists[key] == 'string'?fileLists[key].split(";"):fileLists[key]; 
                    items.push (
                        <div >
                            <a onClick={() => {
                             this.handlePreview("file",fileList[0]);
                            }}>{fileList[1]}</a> 
                        </div>
                    )
                }
            return items;
            }
        };
    //图片展示
    imageLists = (imageLists) =>{
        {
            let items=[];
            for(let key in imageLists){
                let imageList = typeof imageLists[key] == 'string'?imageLists[key].split(";"):imageLists[key];
                items.push (
                    <span>
                       <img style={{height:"80px" ,width:"80px"}} src={"anonymity/docrepo/download/file?attachmentId=" + imageList}
                        onClick={() => {
                            this.handlePreview("isImage",imageList[0]);
                        }}/>
                    </span>
                )
            }
            return items;
        }
    }
    render() {
        const { form, getChildComponent, initData } = this.props;
        const { kzxx, formData,previewVisible,previewImage  } = this.state;
        //提示信息
        let AlertParams = {
            message: '当前没有需要填写的信息',
            description: '',
            type: 'info'
        }
        //信息采集字段分类
        const infoItems = [];
        let reactid = 0; 
        if (initData) {
            //表单信息、表单字段信息
            const { zdglVOs = [] } = initData;
            if (zdglVOs != null) {
                if (zdglVOs.length > 0) {
                    let array = [];
                    //循环过滤出多行文本框的key，偶数在左，奇数在右，相应的同行的另一条文本也需要加宽
                    for (let key in zdglVOs) {
                        let { kjcs } = zdglVOs[key];
                        let kjcsjson = JSON.parse(kjcs); 
                        if (kjcsjson.type === "textarea" ||kjcsjson.type === "oldupload" ||kjcsjson.type === "upload" ) {
                            if (key % 2 === 0) { 
                                array.push(Number.parseInt(key));
                                array.push(Number.parseInt(key) + 1);
                            } else {
                                array.push(Number.parseInt(key));
                                array.push(Number.parseInt(key) - 1);
                            }
                        }
                    }
                    //显示表单字段信息
                    for (let key in zdglVOs) { 
                        //字段状态、是否必填、默认值、字段标识、字段名称、控件参数、字段值
                        let { zdbz, zdmc, kjcs, zdz, sfbt , mrz, zdzt} = zdglVOs[key]; //表单字段
                        let zdzmc = '';
                        let kjcsjson = JSON.parse(kjcs); 
                        if(formData && formData[zdbz] != null){  
                            zdz = formData[zdbz]; 
                        } 
                        if(kjcsjson.type === 'datepicker'||kjcsjson.type === 'datetimepicker'||kjcsjson.type === 'timepicker'||kjcsjson.type === 'monthpicker'||kjcsjson.type === 'rangepicker'){    //日期值展示阶段，从moment转为文字 
                            if(zdz){
                                zdzmc=moment(zdz).format(kjcsjson.format);
                            }
                        } else if (kjcsjson.type === "select") {
                            if (kjcsjson.dataTag && !this.state.dmkmcList[zdbz]) {
                                this.getDmkMc(kjcsjson.dataTag, zdbz);
                            }
                            let list = this.state.dmkmcList[zdbz]; 
                            list && list.map(item => {
                                if (item.VALUE === zdz) {
                                    zdzmc = item.LABEL;
                                }
                            })
                        } else if (kjcsjson.type === "multiselect") { 
                            if (kjcsjson.dataTag && !this.state.dmkmcList[zdbz]) {
                                this.getDmkMc(kjcsjson.dataTag, zdbz);
                            }
                            let list = this.state.dmkmcList[zdbz]; 
                            if(zdz ){ 
                                let multiselect=typeof zdz == 'string'?zdz.split(","):zdz;    
                                let multiselectList=[];
                                for(let i in multiselect){
                                    list && list.map(item => {
                                        if (item.VALUE === multiselect[i]) {
                                            multiselectList.push(item.LABEL);
                                        }
                                    })
                                }
                                zdzmc=multiselectList.join(",");
                         }
                        }else if (kjcsjson.type === "nhcascader") {   
                            if(zdz){ 
                                let nhcascaderList=typeof zdz == 'string'?zdz.split(","):zdz;     
                                let nhcascaderLists=[];    
                                for(let i in nhcascaderList){  
                                    let level=i;
                                    let c=i;
                                    let d=i;
                                    let a=++level;
                                    let b=--d;
                                    if (kjcsjson.dataTag && !this.state.dmkmcList[a+zdbz] ) {
                                        if(i == "0"){
                                            this.getCascaderDmkMc(a,kjcsjson.dataTag,'',zdbz) 
                                        }
                                        else{
                                            this.getCascaderDmkMc(a, kjcsjson.dataTag,nhcascaderList[b],zdbz) 
                                        } 
                                    }
                                        let list=this.state.dmkmcList[level+++zdbz];
                                        
                                        list && list.map(item => {   
                                            if (item.value === nhcascaderList[c]) { 
                                                nhcascaderLists.push(item.label);
                                            }
                                        }) 
                                }
                                zdzmc=nhcascaderLists.join("/");
                            }
                         }else if(kjcsjson.type === "switch"){
                            zdzmc = (zdz === "1" || zdz === true) ? kjcsjson.checkedText:kjcsjson.uncheckedText;
                        } else if(kjcsjson.type === "radio"){
                            kjcsjson.options&&kjcsjson.options.map((item,key)=>{
                                if(item.value === zdz){
                                    zdzmc = item.label;
                                }
                            })
                        }else if(kjcsjson.fileType === "file" && zdz  ){  
                            let fileLists=typeof zdz == 'string'?zdz.split(","):zdz;   
                            zdzmc=(
                                this.fileLists( fileLists)  
                            )
                        }else if(kjcsjson.fileType === "image" && zdz ){    
                            if (typeof zdz === 'object') { 
                                let array = [];
                                for (let item of zdz) {
                                    if (item.hasOwnProperty('uuid') && item['uuid']) {
                                        array.push(item['uuid']) 
                                    }
                                }
                                zdz= array
                            }else{
                                zdz=zdz.split(",");
                            } 
                            zdzmc=(
                                this.imageLists(zdz)  
                            )  
                        }else { 
                            zdzmc = zdz;
                        } 
                        let required = sfbt === '1' ? true : false;

                        let formItem;
                        const csz =formData ? formData[zdbz] : zdz 
                        if(required){
                            formItem = (kjcsjson.type === 'textarea' || kjcsjson.type === "oldupload" ||kjcsjson.type === "upload" ? 
                            (
                                <div key={'div' + reactid++} 
                                    style={ zdzt==="3" ? {display:"none"} : (kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "120px" } : {})} 
                                    styleName={"kzxx_item_textarea"}
                                >
                                    
                                    <div styleName="kzxx_label"><span style={{ color: 'red' }}>*</span>{zdmc}：</div>
                                    <div styleName="kzxx_name_textarea">
                                        {
                                            kzxx && zdzt === "1"?
                                                <NHInputRender key={key}  id={zdbz} label={''} defaultValue={mrz}  
                                                    value={kjcsjson.validationValueType === 'array' ? (csz && typeof csz == 'string'? csz .split(",") :  csz ? csz:[]) : csz}
                                                    required={required}
                                                    disabled={kzxx ? false : true}
                                                    form={form} formLayout={'vertical'} feature={kjcs} />
                                                :
                                                zdzmc
                                                 
                                        }
                                    </div>
                                </div>
    
                            )
                            :
                            (
                                <div key={'div' + reactid++} 
                                    style={ zdzt==="3" ? {display:"none"} : (kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "71px" } : {})} 
                                    styleName={"kzxx_item"}
                                >
                                    
                                    <div styleName="kzxx_label"><span style={{ color: 'red' }}>*</span>{zdmc}：</div>
                                    <div styleName="kzxx_name">
                                        {
                                            kzxx && zdzt === "1"?
                                                <NHInputRender key={key}  id={zdbz} label={''} defaultValue={mrz} 
                                                    value={kjcsjson.validationValueType === 'array' ? (csz && typeof csz == 'string'? csz .split(",") : csz ? csz:[]): csz}
                                                    required={required}
                                                    disabled={kzxx ? false : true}
                                                    form={form} formLayout={'vertical'} feature={kjcs}
                                                    onChange={this.onChange} />
                                                :
                                                zdzmc
                                        }
                                    </div>
                                </div>
    
                            ));
                        }else{  
                            formItem = (kjcsjson.type === 'textarea' || kjcsjson.type === "oldupload" ||kjcsjson.type === "upload"  ? 
                            (
                                <div key={'div' + reactid++} 
                                    style={ zdzt==="3" ? {display:"none"} : (kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "120px" } : {})} 
                                    styleName={"kzxx_item_textarea"}
                                >
                                    <div styleName="kzxx_label">{zdmc}：</div>
                                    <div styleName="kzxx_name_textarea">
                                        { 
                                            kzxx && zdzt === "1"?
                                                <NHInputRender key={key}  id={zdbz} label={''} defaultValue={mrz} 
                                                    value={kjcsjson.validationValueType === 'array' ?(csz && typeof csz == 'string'? csz .split(",") : csz ? csz:[]): csz}
                                                    required={required}
                                                    disabled={kzxx ? false : true}
                                                    form={form} formLayout={'vertical'} feature={kjcs} />
                                                :
                                                zdzmc
                                        }
                                    </div>
                                </div>
    
                            )
                            :
                            (
                                <div key={'div' + reactid++} 
                                    style={ zdzt==="3" ? {display:"none"} : (kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "71px" } : {})} 
                                    styleName={"kzxx_item"}
                                >
                                    <div styleName="kzxx_label">{zdmc}：</div>
                                    <div styleName="kzxx_name">
                                        {
                                            kzxx && zdzt === "1"?
                                                <NHInputRender key={key}  id={zdbz} label={''} defaultValue={mrz} 
                                                    value={kjcsjson.validationValueType === 'array' ?(csz && typeof csz == 'string'? csz .split(",") : csz ? csz:[]) : csz}
                                                    required={required}
                                                    disabled={kzxx ? false : true}
                                                    form={form} formLayout={'vertical'} feature={kjcs} 
                                                    onChange={this.onChange}/>
                                                :
                                                zdzmc
                                        }
                                    </div>
                                </div>
    
                            ));
                        }
                        infoItems.push(formItem);
                    }
                } else {
                    //未找到表单配置的采集字段信息
                    AlertParams['message'] = '暂时没有需要填写的信息';
                    AlertParams['description'] = '当前未配置采集信息项';
                    AlertParams['type'] = 'warning';
                }
            }
        }


        return (
            <div>
                {infoItems.length > 0 &&
                    <div>
                        <TitleTop
                            title="扩展信息"
                            onEdit={type => {
                                this.setState({
                                    kzxx: true
                                });
                                getChildComponent(2, false);
                            }}
                            onSave={cb => {
                                this.onSaveKzxx(cb);
                            }}
                            onClose={() => {
                                this.setState({
                                    kzxx: false
                                });
                            }}
                        />
                        {
                            infoItems.length > 0 ?
                                <Form>
                                    <div className="p-sw">
                                        <div styleName="kzxx_list">
                                            {infoItems}
                                        </div>
                                    </div>
                                </Form> 
                                :
                                <Alert style={{ marginTop: 20 }}
                                    {...AlertParams}
                                    showIcon
                                />
                        }
                         <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.closeModal}
                    >
                        <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                        />
                    </Modal>
                    </div>
                }
                  <style jsx="true" global="true">
            {`

                            .app__css__app-pages-SelfHelp-updateInfo-__index__kzxx_item {
 
                                height: 100% !important; 
                            }
                            .app__css__app-pages-SelfHelp-updateInfo-__index__kzxx_label {
                                background-color: #fff;
                            } 
                            .app__css__app-pages-SelfHelp-updateInfo-__index__kzxx_name { 
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 67%;
                            }
                        `}
          </style>
            </div>
        )
    }

}

const KzxxForm = Form.create()(KzxxFormComponent);
export default KzxxForm;