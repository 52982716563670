import NHFetch from "@utils/NHFetch";
import { message } from "antd";
import  { getQueryString }  from '@utils/globalUtil'

/**
 * @author qianzhiwei
 * @date 2020/09/27
 * @description 根据学生ID以及环节ID查询前置环节以及依赖环节信息
 * @param {学生id} xsid 
 * @param {自助报到环节id} hjid 
 * @param {回掉函数} callback 
 */
export const getQztjAndYlhjInfoByHjId = (xsid, hjid, callback) => {
    if (!xsid || !hjid) {
      return;
    }
  
    NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: hjid})
      .then(response => {
        if (response) {
          if (callback) {
            callback(response.data);
          }
        }
      }).catch(() => {
      message.error("服务器异常，请稍后重试！");
    });
  
  };

/**
 * @author qianzhiwei
 * @date 2020/09/27
 * @description 获取环节ID
 */  
export const getZzbdhjid = () => {
    return getQueryString('pkid');
}

export const getQztjAndYlhjInfo = (xsid, callback) => {
    let hjid = getZzbdhjid();
    getQztjAndYlhjInfoByHjId();
  };