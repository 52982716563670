import React from "react";
import { Form, Select, Upload, message } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import { Flex } from '@components';
import NHFormItem from '@components/common/NHFormItem/index';
import NHCascader from "@components/common/NHCascader";
import { createUuid } from '@utils/NHCore';
import NHFetch from '@utils/NHFetch';
import '../index.css';
import NHModal from "@components/common/NHModal";

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"; //上传logo路径 
const zpTip = "<p style=\"white-space: normal;text-align:center;color:#000;font-size:16px;\">—照片要求—</p><br/><p style=\"white-space: normal;\">1.本人清晰、免冠、正面彩色头像；要求面部五官无遮挡，人头部约占照片高度的三分之二；</p><br/><p style=\"white-space: normal;\">2.常戴眼镜的人员允许佩戴眼镜，要求不能为宽边框，拍照时注意镜片不要反光;</p><br/><p style=\"white-space: normal;\">3.照片背景颜色尽量单一，底色为白色、蓝色、红色均可；</p><br/><p style=\"white-space: normal;\">4.照片光线均匀，面部无明显逆光；</p><br/><p style=\"white-space: normal;\">5.照片文件大小要求：大于60KB，小于1MB;</p><br/><p style=\"white-space: normal;\">6.照片像素要求：宽高在200*200以上；</p><p><br/></p>"

import { getLoginUser } from "@utils/NHCore";

function beforeUpload(file) {
    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
        message.error("You can only upload JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
}

class JbxxInitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfoData: {},
            jbxx: false,
            xsid: '',
            zzmmmList: [],
            mzmList: [],
            textData: '',
            jgmStr: '',
            jtStr: '',
            xsSsInfo: '',
            zzmmValue: 1,//政治面貌
            sfqyValue: -1,//是否迁移户口
            xyContent: "",//协议内容
            values: {},
            sj: "",
            sureButton: false,
            ZSXYFlag: false,
            zzmmList: [
                /* {value:"01",label:"中国共产党党员"},
                {value:"02",label:"中国共产党预备党员"},
                {value:"03",label:"中国共产主义青年团团员"},
                {value:"04",label:"中国国民党革命委员会会员"},
                {value:"05",label:"中国民主同盟盟员"},
                {value:"06",label:"中国民主建国会会员"},
                {value:"07",label:"中国民主促进会会员"},
                {value:"08",label:"中国农工民主党党员"},
                {value:"09",label:"中国致公党党员"},
                {value:"10",label:"九三学社社员"},
                {value:"11",label:"台湾民主自治同盟盟员"},
                {value:"12",label:"无党派民主人士"},
                {value:"13",label:"群众"} */
            ],//政治面貌
            hkszdDetail: "",
            jgmmc: "",
            zzmmMC: "",
            mzList: [],
            mzMc: ""
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        }
        this.loadZzmm();
        this.loadMz();
    }

     // 获取学生头像
     getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }

    // 政治面貌
    loadZzmm = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dMK_XTGL_ZZMMM').then(res => {
            if (res && res.code == 200) {
                let list = []
                let zzmmList = res.data;
                zzmmList.map((item) => {
                    list.push({ "value": item.VALUE, label: item.LABEL })
                })
                this.setState({
                    zzmmList: list
                })
            }
        });
    }

    // 民族
    loadMz = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=DMK_XTGL_MZM').then(res => {
            if (res && res.code == 200) {
                let list = []
                let mzList = res.data;
                mzList.map((item) => {
                    list.push({ "value": item.VALUE, label: item.LABEL })
                })
                this.setState({
                    mzList: list
                })
            }
        });
    }

    onSavejbxxFormData = (cb) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                cb(false);
                return;
            }
            this.setState({
                jbxx: false,
            });
            cb(true);
            console.log("66666666666666", values.hkszd, "vvv", values.jgm, "22222222", values);
            let hkszdArray = values.hkszd;
            if (hkszdArray.length > 0) {
                let num = hkszdArray.length;
                values['hkszd'] = hkszdArray[num - 1]
                console.log(values['hkszd']);
            }
            let jgArray = values.hkszd;
            if (this.state.jgDetail != "" && this.state.jgDetail) {
                values['jgDetail'] = this.state.jgDetail;
            } else {
                values['jgDetail'] = this.props.jbxxFormData.jgDetail
            }
            if (this.state.hkszdDetail != "" && this.state.hkszdDetail) {
                values['hkszdDetail'] = this.state.hkszdDetail;
            } else {
                values['hkszdDetail'] = this.props.jbxxFormData.hkszdDetail
            }
            if (this.state.zzmmMC != "" && this.state.zzmmMC) {
                values['zzmmmc'] = this.state.zzmmMC;
            } else {
                values['zzmmmc'] = this.props.jbxxFormData.zzmmmc
            }
            if (this.state.mzMc != "" && this.state.mzMc) {
                values['mzMc'] = this.state.mzMc;
            } else {
                values['mzMc'] = this.props.jbxxFormData.mzMc
            }
            const { cacheFormData } = this.props;
            cacheFormData('jbxx_sjz', values);
        });

    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    // 上传图片
    handleUploadChange = info => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10';
                NHFetch(url, 'POST')
                    .then(res => {
                        if (res && res.data) {
                            message.success("上传成功!")
                        }
                    });
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({ imageUrl })
        );
    }
    onjgNHCascaderChange = (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
        this.setState({
            jgDetail: label
        })
    }

    onNHCascaderChange = (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
        this.setState({ hkszdDetail: label });

    }

    // 政治面貌回调
    zzmmSelect = value => {
        let mc = ""
        console.log('radio checked', value);
        this.state.zzmmList.map((item) => {
            if (item.value == value) {
                mc = item.label;
            }
        })
        this.setState({
            zzmmValue: value,
            zzmmMC: mc
        }, () => {
            console.log("赋值zzmm", this.state.zzmmMC);
        });
    };

    // 民族回调
    mzSelect = value => {
        let mc = ""
        console.log('radio checked', value);
        this.state.mzList.map((item) => {
            if (item.value == value) {
                mc = item.label;
            }
        })
        this.setState({
            mzValue: value,
            mzMc: mc
        }, () => {
            console.log("赋值mz", this.state.mzMc);
        });
    };

    // 是否迁移户口回调
    sfqyOnChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            sfqyValue: e.target.value,
        });
    };

    // 取消按钮
    handleCancel = () => {
        this.setState({
            jbxx: true,
            sureButton: fasle
        });
    }

    zphandleOk = () => {
        this.zpModal.close();
        // this.beforeUpload();
        this.setState({
            modal: false
        }, () => {
            this.handleUploadChange(this.state.modalInfo);
        })

    }

    // modalSure=(info)=>{
    //     this.zpModal.show();
    // }

    openZPModal = (info) => {
        this.zpModal.show();
        this.setState({
            modalInfo: info
        })
    }

    render() {
        const { form, jbxxFormData, getChildComponent, userInfoData, stuSsxx, editXszp, kzxxInitData, SFBT, blztFlag, dxxxFormData } = this.props;
        const { jbxx, imageUrl, tempUrl, xsid, zzmmList, mzList } = this.state;
        const jtjddz = jbxxFormData ? jbxxFormData.jtjddz : '';
        let ssxx = '';
        let zdz = ""
        console.log("照片是否可以编辑-----", editXszp);
        if (jbxxFormData.xxcj_zdyhksfqy == undefined) {
            if (kzxxInitData) {
                kzxxInitData.map((item) => {
                    if (item.zdbz == "ZDYHKSFQY") {
                        zdz = item.zdz;
                        console.log(item.zdz);
                    }
                })
            }
        } else {
            zdz = jbxxFormData.xxcj_zdyhksfqy;
        }

        if (stuSsxx && stuSsxx.xqmc && stuSsxx.ldmc && stuSsxx.fj && stuSsxx.cwh) {
            ssxx = stuSsxx.xqmc + stuSsxx.ldmc + ' - ' + stuSsxx.fj + ' - ' + stuSsxx.cwh;
        }
        console.log(jbxxFormData);
        return (

            <Form layout="inline">
                <TitleTop
                    title="基本信息"
                    onEdit={type => {
                        this.setState({
                            jbxx: true,
                            sfqyValue: zdz
                        });
                        getChildComponent(1, false);
                    }}
                    onSave={cb => {
                        this.onSavejbxxFormData(cb);//现在不需要单条保存数据
                        getChildComponent(1, true);
                    }}
                    onClose={() => {
                        this.setState({
                            jbxx: false
                        });
                    }}
                />

                <Flex className="p-sw">
                    <div styleName="jbxx_icon" className="_bg">
                        {jbxx && editXszp === "true" ?
                            <Upload
                                name="photo"
                                accept={'image/*'}
                                showUploadList={false}
                                action={uploadUrl + "?xsid=" + xsid}
                                beforeUpload={this.beforeUpload}
                                onChange={this.openZPModal}
                            >
                                {imageUrl || userInfoData ? (
                                    imageUrl ?

                                        <img
                                            src={imageUrl}
                                            alt=""
                                            className="avatar"
                                            style={{ border: "1px solid #1890ff", padding: "5px" }}
                                        />
                                        : tempUrl
                                ) : (
                                    <div styleName="upload-box">
                                        <div styleName="upload-title">
                                            上传图片
                                        </div>
                                    </div>
                                )}
                            </Upload>
                            :
                            imageUrl ?
                                <img
                                    src={imageUrl}
                                    alt=""
                                    className="avatar"
                                />
                                :
                                tempUrl

                        }

                    </div>
                    <Flex.Item className="ml-20">
                        <div styleName="jbxx_item">
                            <span styleName="item-label">姓名：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.XM : ''}

                            </div>
                        </div>
                        <div styleName="jbxx_item">
                            <span styleName="item-label">性别：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >

                                {
                                    userInfoData && userInfoData.XB ?

                                        userInfoData.XB === '男' ?
                                            <img src={require('@images/boy.png')} alt="" />
                                            : userInfoData.XB === '女' ?
                                                <img src={require('@images/girl.png')} alt="" />
                                                :
                                                <img src={require('@images/weizhixb.png')} alt="" />
                                        : ''

                                }

                            </div>
                        </div>

                        <div>
                            <div styleName="jbxx_item2">
                                {jbxx ?
                                    <NHFormItem id={'mzm'}
                                        label={<span styleName="item-label">民族：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        // hasFeedback={true} 
                                        initialValue={userInfoData ? userInfoData.MZM : ""}
                                    >
                                        <Select defaultValue={userInfoData ? userInfoData.MZM : ''} style={{ width: 220 }} onChange={this.mzSelect} value={this.state.mzValue} placeholder="请选择...">
                                            {
                                                mzList.map((item, index) => {
                                                    return <Option value={item.value}>{item.label}</Option>
                                                })
                                            }
                                        </Select>

                                    </NHFormItem>
                                    : <div>
                                        <span styleName="item-label"><span style={{ color: 'red' }}>*</span>民族：</span>
                                        <div
                                            className="t-clamp-1"
                                            styleName="item-name"
                                            style={{ display: 'inline' }}
                                        >
                                            {userInfoData ? (this.state.mzMc ? this.state.mzMc : userInfoData.MZ ) : ''}
                                        </div></div>
                                }
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">学号：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.XH : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">学院：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.BMMC : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">专业：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.ZYMC : ''}
                            </div>
                        </div>

                        <div>
                            <div styleName="jbxx_item">
                                {jbxx ?
                                    <NHFormItem id={'hkszd'}
                                        label={<span styleName="item-label">户口所在地：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        // hasFeedback={true} 
                                        // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                        initialValue={jbxxFormData ? jbxxFormData.hkszdArray : ""}
                                    >
                                        {/* <Input style={{ width: 140 }} placeholder="请填写户口所在地" /> */}
                                        <NHCascader style={{ width: 400 }} sign={"zhxg_ssq"} onChange={(value, selectedOptions) => this.onNHCascaderChange(value, selectedOptions, 'jt')} />
                                    </NHFormItem>
                                    : <div>
                                        <span styleName="item-label"><span style={{ color: 'red' }}>*</span>户口所在地：</span>
                                        <div
                                            className="t-clamp-1"
                                            styleName="item-name"
                                            style={{ display: 'inline' }}
                                        >
                                            {jbxxFormData ? jbxxFormData.hkszdDetail : ''}
                                        </div></div>
                                }
                            </div>
                        </div>

                        <div styleName="jbxx_item2">
                            {jbxx ?
                                <NHFormItem id={'jgm'}
                                    label={<span styleName="item-label">籍贯：</span>}
                                    colon={false}
                                    required={true}
                                    form={form}
                                    initialValue={jbxxFormData ? jbxxFormData.jgArray : []}
                                >
                                    <NHCascader style={{ width: 400 }} sign={"zhxg_ssq"} onChange={(value, selectedOptions) => this.onjgNHCascaderChange(value, selectedOptions, 'jt')} />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>籍贯：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {jbxxFormData ? jbxxFormData.jgDetail : ''}
                                    </div></div>
                            }
                        </div>



                        <div>
                            <div styleName="jbxx_item2">
                                {jbxx ?
                                    <NHFormItem id={'zzmmm'}
                                        label={<span styleName="item-label">政治面貌：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        // hasFeedback={true} 
                                        // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                        initialValue={jbxxFormData ? jbxxFormData.zzmmm : ""}
                                    >
                                        <Select defaultValue={jbxxFormData ? jbxxFormData.zzmmm : ''} style={{ width: 220 }} onChange={this.zzmmSelect} value={this.state.zzmmValue} placeholder="请选择...">
                                            {
                                                zzmmList.map((item, index) => {
                                                    return <Option value={item.value}>{item.label}</Option>
                                                })
                                            }
                                        </Select>

                                    </NHFormItem>
                                    : <div>
                                        <span styleName="item-label"><span style={{ color: 'red' }}>*</span>政治面貌：</span>
                                        <div
                                            className="t-clamp-1"
                                            styleName="item-name"
                                            style={{ display: 'inline' }}
                                        >
                                            {jbxxFormData ? jbxxFormData.zzmmmc : ''}
                                        </div></div>
                                }
                            </div>
                        </div>

                    </Flex.Item>
                </Flex>
                <NHModal
                    ref={(form) => { this.zpModal = form }}
                    title="照片规范"
                    width={"40%"}
                    // style={{width:"70%"}}
                    onOk={this.zphandleOk}
                // footer={footer}
                // onCancel={this.zphandleCancel}
                >
                    <img src={require('../../../../../../assets/images/ylt.png')} style={{ width: "50%", position: "relative", left: "25%" }}></img>
                    <div dangerouslySetInnerHTML={{ __html: zpTip }} style={{ textAlign: "center" }}></div>
                </NHModal>

                <style jsx="true" global="true">
                    {`
                          .ant-col-md-7 {  
                            width: 100px; 
                           }
                `}
                </style>
            </Form>
        );
    }
}


const JbxxForm = Form.create()(JbxxInitForm);

export default JbxxForm;