import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {Form, Input, Row, Col, Button, Modal, Select, Collapse, Icon,message} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import createHistory from 'history/createHashHistory'; 
const history = createHistory();
//确认对话框
const confirm = Modal.confirm;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

import './index.css';
import NHFetch from "@utils/NHFetch";
import MajorForm from './form';
import {getLoginUser} from "@utils/NHCore";
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";

@Form.create()
export default class Major extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz:'GD007_1',
            hjmc:'',
            data:[],
            visible: false,
            confirmLoading: false,
            xsid:'',
            zytzPkid:'',
            nextUrl:'',//左侧菜单加载完后传进来
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            XsxxMojorData:{},//获取专业信息接口
            hjblztData: {}, //环节办理状态信息
            blztFlag:"0",//办理状态
            delloading:false,
            ifapplyloading:false,
            applyloading:false,
            editloading:false,
        };
    }

    componentWillMount(){
        let xsid = getLoginUser().userId;
        this.setState({xsid:xsid})
    }

    componentDidMount(){
        this.queryQztjAndYlhj();
        this.zytzList();
        this.getMojorInfo(); 
    }

    // 获取专业信息接口
    getMojorInfo = () => {
        let xsid = getLoginUser()?getLoginUser().userId:'';
        NHFetch("/zdybl/getXsxxAndLcidByXsid", 'GET', {xsid:xsid})
            .then(res => {
                if(res.code == "200"){
                    this.setState({
                        XsxxMojorData:res.data
                    })
                }else{
                    message.error("获取专业信息接口失败");
                }
            })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=()=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']
                    this.setState({nonOperationalData:res.data});
                    if(kfsj && kfsj['YXSJ']!='1'){
                        this.setState({operable:false});
                    }

                    if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }

                    if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }

                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
                let sideMenu = this.refs.siderMenu.state.menuList;
                let blztFlag = "0";
                sideMenu.map((item)=>{
                    if(item.HJDM=="GD007_1"){
                        blztFlag = item.BLZT;
                    }
                })
                this.setState({
                    blztFlag:blztFlag
                })
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList=(menuList)=>{
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                let hjmc = elem.HJMC;
                this.setState({hjmc:hjmc})
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                })
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                })
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }

    zytzList = ()=>{
        let params = {
            xsid:this.state.xsid
        }
        NHFetch('/zytz/getZytzList',"GET",params)
            .then(res => {
                if (res && res.data) {
                    this.setState({data:res.data});
                }
            })
    }

    deleteTip=(pkid)=>{
        confirm({
            title: '提示',
            content: '是否确定删除该条申请数据',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk:()=>{
                this.delete(pkid);
            },
            onCancel() {

            },
        });
    }

    delete=(pkid)=>{
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch('/zytz/deleteById',"POST",{pkid:pkid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data==1) {
                    let blztFlag = this.state.blztFlag == '0' ? '1' : '0';
                    message.success('删除成功.');
                    this.reloadMenu();
                    this.setState({visible: false,blztFlag:blztFlag});
                    this.zytzList();//重新加载
                }else{
                    message.warn('删除异常');
                }
                this.zytzList();//重新查询
            }).catch(err=> {
            message.warn('删除异常');
        });
    }

     //是否放弃申请
     ifApply=(pkid)=>{
        confirm({
            title: '提示',
            content: '是否确认放弃该专业调整申请，放弃后将不能再重新发起！ ',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk:()=>{
                this.handelApply(pkid);
            },
            onCancel() {

            },
        });
    }

    //放弃申请--确定
    handelApply = (pkid) => {
        let data={
            pkid:pkid,
            shzt:'4', 
            xsid:this.state.xsid,
            zzbdhjid : this.getQueryString('pkid')
        }
        NHFetch('/zzbd/zytzfa/giveUpApply', 'POST', data)
                .then(res => {
                    if (res) {
                        if(res.data==1){
                            message.success('放弃申请成功');
                            this.zytzList();
                            this.reloadMenu(); 
                        }else{
                            message.error("放弃申请失败")
                        }
                    }else{
                        message.error("放弃申请失败")
                    }
                })
    }

    showModal = () => {
        const zytzPkid = this.state.zytzPkid;
        if(zytzPkid){
            this.setState({
                visible: true
            });
        }else{
            const xsid = this.state.xsid;
            const uid = getLoginUser() ? getLoginUser().userId : '';
            if(!uid){
                message.error("登录已过期，请重新登录！");
                return;
            }
            NHFetch('/zytz/getZytzCountByZt',"GET",{xsid:xsid})
                .then(res => {
                    if (res && res.data>0) {
                        message.warn("已存在专业调整申请，不可再申请 !");
                    }else{
                        this.setState({
                            visible: true
                        });
                    }
                });
        }
    };
    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    handleOk = e =>{
        confirm({
            title: '提示',
            content: '是否确定提交申请，提交后不可修改！',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk: () => {
                this.handleSubmit(e);
            },
            onCancel() {

            },
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            confirmLoading: true
        });
        let zzbdhjid = this.getQueryString('pkid');
        this.form.validateFields((err, values) => {
            if (err) {
                this.setState({
                    confirmLoading: false
                });
                return false;
            }

            let bmid = values['bmids'];
            let zyid = values['zyids'];
            let pkid = values['pkid'];
            let bjid ='';
            if(values['bjids'] && values['bjids'].length>0){
                bjid = values['bjids'];
            }

            let sqly = values['ly'];

            let params={
                zzbdhjid:zzbdhjid,
                zytzDTO:{ 
                    tzhyxid:bmid,
                    tzhzyid:zyid,
                    tzhbjid:bjid,
                    sqly:sqly,
                    pkid:pkid,
                }
            }
            let blztFlag = this.state.blztFlag == '0' ? '1' : '0';
            if(pkid){
                NHFetch('/zytz/update',"POST",params)
                    .then(res => {
                        if (res && res.data && res.data==1) {
                            message.success("修改成功！");
                            this.reloadMenu();
                            this.setState({visible: false,blztFlag:blztFlag});
                            this.zytzList();//重新加载
                        }else{
                            message.warn("修改异常！");
                            this.setState({confirmLoading:false});
                        }
                        this.setState({confirmLoading: false});
                    }).catch((res) => {
                    this.setState({confirmLoading: false});
                    message.warn("修改异常！");
                    return res;
                    });
            }else{
                NHFetch('/zytz/insert',"POST",params)
                    .then(res => {
                        if (res && res.data && res.data==1) {
                            message.success("保存成功！");
                            this.reloadMenu();
                            this.setState({visible: false,blztFlag:blztFlag});
                            this.zytzList();//重新加载
                        }else{
                            message.warn("保存异常！");
                            this.setState({confirmLoading:false});
                        }
                        this.setState({confirmLoading: false});
                    }).catch((res) => {
                    this.setState({confirmLoading: false});
                    message.warn("保存异常！");
                    return res;
                });
            }
        });
    };

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList();
    }

    //确认无误 
    confirmBtnClick=()=>{
        confirm({
            title: '提示',
            content: '专业是否确认无误？',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk:()=>{
                this.submitBtnClick();
            },
            onCancel() {

            },
        });
    }


    // 确认办理
    submitBtnClick = () =>{
        const { XsxxMojorData } = this.state;
        let xsid = getLoginUser()?getLoginUser().userId:'';
        let zzbdhjid = this.getQueryString('pkid');
        let formData = {xsid: xsid, yhid: xsid, zzbdhjid: zzbdhjid, lchjid: XsxxMojorData.LCID}
            NHFetch('/zdybl/saveCustomLinkData',"POST",formData)
            .then(res => {
                if(res){
                    if (res.data == '1') {
                        this.reloadMenu();
                        message.success('环节办理成功！');
                    let blztFlag = this.state.blztFlag == '0' ? '1' : '0';
                    this.setState({
                        blztFlag:blztFlag
                    })
                    }else{
                        message.error('环节办理失败，请重试！');
                    }
                    
                }
            })
    }

    // 取消办理
    cancleBtnClick = () =>{
        const { XsxxMojorData } = this.state;
        let xsid = getLoginUser()?getLoginUser().userId:'';
        let zzbdhjid = this.getQueryString('pkid');
        let formData = {xsid: xsid, lchjid: XsxxMojorData.LCID, zzbdhjid: zzbdhjid}
        NHFetch('/zdybl/cancelCustomLinkData', 'POST', formData)
            .then(res => {
                if (res) {
                    if (res.data == '1') {
                        message.success('环节办理取消成功！');
                        //取消办理完成回调
                        this.reloadMenu();
                        let blztFlag = this.state.blztFlag == '0' ? '1' : '0';
                        this.setState({
                            blztFlag:blztFlag
                        })
                    } else {
                        message.error('环节办理取消失败，请重试！');
                    }
                }
            })
    }


    render() {
        const { visible, confirmLoading,XsxxMojorData,blztFlag} = this.state;
        return (
            <div>{this.state.operable?
                <div>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                               ref={"siderMenu"}
                               title="自助报到"
                               setMenuList={this.setMenuList}
                         /*      active={6}*/
                               mkbz={this.state.mkbz}
                               menuType='自助报到'/>
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint/>
                            {this.state.data.length == 0 && blztFlag=="0"  ? <TitleTop
                                className="mt-20"
                                title={this.state.hjmc}
                                extra={false}
                                apply
                                onApplyClick={() => {
                                    this.setState({zytzPkid:''},()=>{
                                        this.showModal();
                                    })
                                }}
                            />:<TitleTop
                            className="mt-20"
                            title={this.state.hjmc}
                            extra={false}
                        />}
                            <div className="p-sw" style={{border:"1px solid #d9d9d9",margin:20}}>
                            <Row>
                                <Col
                                    styleName="col"
                                    span={8}
                                >
                                    姓名：{XsxxMojorData?XsxxMojorData.XM:""}
                                </Col>
                                <Col
                                    styleName="col"
                                    span={8}
                                >
                                    性别：{XsxxMojorData?XsxxMojorData.XB:""}
                                </Col>
                                <Col
                                    styleName="col"
                                    span={8}
                                >
                                    学号：{XsxxMojorData?XsxxMojorData.XH:""}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    styleName="col"
                                    span={8}
                                >
                                    学院：{XsxxMojorData?XsxxMojorData.BMMC:""}
                                </Col>
                                <Col
                                    styleName="col"
                                    span={8}
                                >
                                    专业：{XsxxMojorData?XsxxMojorData.ZYMC:""}
                                </Col>
                                <Col
                                    styleName="col"
                                    span={8}
                                >
                                    班级：{XsxxMojorData?XsxxMojorData.BJMC:""}
                                </Col>
                            </Row>
                            </div>
                            <div className="p-sw">
                                <Collapse accordion defaultActiveKey={['0']}>
                        {
                            this.state.data.map((elem, index) => {
                            // let tzqxx = elem.TZQYXMC + " - " + elem.TZQZYMC + " - " + elem.TZQBJMC;
                            let tzqxx;
                                if(elem.TZQBJMC != undefined){
                                    tzqxx = elem.TZQYXMC + " - " + elem.TZQZYMC + " - " + elem.TZQBJMC;
                                }else{
                                    tzqxx = elem.TZQYXMC + " - " + elem.TZQZYMC;
                                }
                            let tzhxx = `${elem.TZHYXMC}${elem.TZHZYMC?` - ${elem.TZHZYMC}`:``}${elem.TZHBJMC?` - ${elem.TZHBJMC}`:``}`;
                            return (
                                    <Panel
                                        header={
                                            <Flex>
                                                <Flex.Item>
                                                    申请人：<span className="text-blue">
                                                    {elem['XM']}
                                                    </span>
                                                </Flex.Item>
                                                <Flex.Item>性别：{elem['XB']}</Flex.Item>
                                                {elem['ZT']=='3'?
                                                    <Flex.Item className="text-right">
                                                         {elem['ZT']=='3'?<Button type="primary" ghost shape="circle" style={{height: "26px",borderRadius:"13px",padding:"0 15px",marginRight:"10px"}}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                this.ifApply(elem['PKID']);
                                                            }}
                                                        >放弃申请</Button>:null}
                                                        <Button type="primary" shape="circle" ghost style={{height: "26px",borderRadius:"13px",padding:"0 15px",marginRight:"10px"}} 
                                                             onClick={e => {
                                                                e.stopPropagation();
                                                                this.setState({zytzPkid: elem['PKID']}, () => {
                                                                    this.showModal();
                                                                });
                                                            }}
                                                        >修改</Button>
                                                        {elem['ZT']=='3'?<Button shape="circle" style={{height: "26px",borderRadius:"13px",padding:"0 15px"}}
                                                            onClick={e => { 
                                                                e.stopPropagation();
                                                                this.deleteTip(elem['PKID']);
                                                            }}
                                                        >删除</Button>:null}
                                                    </Flex.Item>:
                                                    <Flex.Item className="text-right">
                                                    </Flex.Item>
                                                }
                                            </Flex>
                                        }
                                        key={index}
                                    >
                                        <Row>
                                            <Col
                                                styleName="coll_label"
                                                span={4}
                                            >
                                                调整前：
                                            </Col>
                                            <Col span={20}>{tzqxx}</Col>
                                        </Row>
                                        <Row className="mt-10">
                                            <Col
                                                styleName="coll_label"
                                                span={4}
                                            >
                                                调整后：
                                            </Col>
                                            <Col span={20}>{tzhxx}</Col>
                                        </Row>
                                        <Row className="mt-10">
                                            <Col
                                                styleName="coll_label"
                                                span={4}
                                            >
                                                调整理由：
                                            </Col>
                                            <Col span={20}>
                                                {elem['SQLY']}
                                            </Col>
                                        </Row>
                                        <Flex
                                            justify="between"
                                            styleName="coll_b"
                                        >
                                            <div>
                                                申请状态：<span className="text-primary">
                                                    {elem['SHZT']}
                                                </span>
                                            </div>
                                            <div>
                                                申请时间：<span className="text-sub">
                                                   {elem['SQSJ']}
                                                </span>
                                            </div>
                                        </Flex>
                                        <div>
                                            审核意见：<span className="text-primary" style={{marginTop:10}}>
                                                {elem['HTYJ']}
                                            </span>
                                        </div>
                                    </Panel>
                                    );
                                })
                            }
                                </Collapse>
                            </div>
                            <div className="text-center mt-50">
                                {this.state.data.length==0 && blztFlag=="0" && <Button type='primary' onClick={()=>this.confirmBtnClick()} >确认无误</Button>}
                                {/* {this.state.data.length==0 && blztFlag=="1" && <Button type='primary' onClick={()=>this.cancleBtnClick()} >取消办理</Button>} */}
                                {/* <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/> */}
                            </div>
                        </div>
                    </Flex.Item>
                </Flex>
                <Modal
                    className="modal_body_0"
                    title="专业调整编辑"
                    width={820}
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading} 
                    onCancel={this.handleCancel}
                >
                    {visible&&<MajorForm xsid={this.state.xsid}  zytzPkid={this.state.zytzPkid} ref={(form) => {this.form = form}} xsxxData = {this.state.xsxxData}/>}
                </Modal>
                </div>:<CommonTip hjmc = {"专业调整"} key={'GD007_1'} mkbz='GD007_1' nonOperationalData={this.state.nonOperationalData}/>}
            </div>
        );
    }
}
