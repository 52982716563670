import React from 'react';
import './index.css';
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import SiderMenu from '@pages/Assembly/siderMenu';
import { Flex } from '@components';
import { Button, Table, Icon, InputNumber, Input, message } from 'antd';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import Hint from "@pages/SelfHelp/common/hint";
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid, getLoginUser } from '@utils/NHCore';
import NHFetch from "../../../../utils/NHFetch";
import CommonTip from "../common";
import createHistory from 'history/createHashHistory';

const history = createHistory();

export default class education extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD021',
            hjmc: '',
            operable: true,//是否显示环节
            nextUrl: '',//左侧菜单加载完后传进来
            jgfsx: 0,   //及格分数线
            score: 0,   //个人分数
        };
    }

    componentDidMount() {
        this.queryQztjAndYlhj();
    }

    startStudy = () => {
        // let uid = '201901000872'
        const pkid = getLoginUser().userId;
        NHFetch("/zzbd/startStudy", 'GET', { pkid: pkid })
            .then(res => {
                if (res && res.data == "-1") {
                    message.info("请先完善入学教育配置相关信息！");
                }else if(res && res.data){
                    window.open(res.data)
                }
            })
    }

    nextStudy = () => {
        // let uid = '201901000872'
        const pkid = getLoginUser().userId;
        NHFetch("/zzbd/getCoursePerformance", 'GET', { pkid: pkid })
            .then(res => {
                if (res && res.data) {
                    let jgfsx = 0;
                    let score = 0;
                    jgfsx = res.data.jgfsx;
                    score = res.data.score;
                    if (score < jgfsx) {
                        message.info("请先完成入学教育考试再进行下一环节操作！");
                        //重新加载菜单
                        this.refs.siderMenu.reloadMenuList()
                    } else {
                        //选择已办理
                        this.insertHjblzt();
                    }
                    this.setState({
                        jgfsx: jgfsx,
                        score: score
                    })
                }else{
                    message.info("请先完善入学教育配置相关信息！");
                }
            })
    }

    insertHjblzt = () => {
        const zzbdhjid = this.getUrlParams("pkid");
        const pkid = getLoginUser().userId;
        // const uid = '201901000872'
        let params = { pkid: pkid, zzbdhjid: zzbdhjid };
        NHFetch("/zzbd/getLinkHandling",'POST',params)
            .then(res => {
                if(res && res.data){
                    this.refs.siderMenu.reloadMenuList()
                    this.next();
                }
            })
    }

    next = () => {
        let nextUrl = this.state.nextUrl;
        let search = this.state.search;
        history.push({
            pathname: nextUrl,
            search: search,
        }
        );
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    //环节pkid
    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    render() {

        return (
            <div styleName="page_buy">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            /*       active={1}*/
                            menuType="自助报到"
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw">
                                    <Button type="ghost" style={{ marginLeft: 30, marginTop: 30 }} onClick={this.startStudy}>开始学习</Button>
                                    <Button type="ghost" style={{ marginLeft: 30, marginTop: 30 }} onClick={this.nextStudy}>下一环节</Button>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex> : <CommonTip hjmc={"入学教育"} key={"GD021"} mkbz='GD021' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        )
    }
}