import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Button, Form, Icon, InputNumber, Input, Select, Radio, Tag} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "../../../../utils/NHFetch";
import NHSelect from "@components/common/NHSelect";
import {NHConfirm} from "@components/common/NHModal";
import {message} from "antd/lib/index";
import './index.css';
import createHistory from 'history/createHashHistory';
import {createUuid, getLoginUser} from '@utils/NHCore';
import CommonTip from "../common";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHRadioGroup from "@components/common/NHRadioGroup";

const history = createHistory();
const basicUrl = "/zzbd";
const commonUrl = '/zzbdCommon';
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16}
};

@Form.create()
export default class ChoosePhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD027_2',
            hjmc: '',
            nextUrl: '',             // 左侧菜单加载完后传进来
            search: '',
            operable: true,          // 是否显示环节
            nonOperationalData: {},  // 不可操作的数据
            disabledTime: 3,        // 4s内禁止再次点击下一批按钮
            disablebtnClick: false, // [下一批]按钮是否禁用
            loading: false,
            isChoosePhoneNum: true,// 是否自选手机号码
            phoneNumList: [],        // 手机号码列表
            page: 1,                // 默认展示第一页手机号码
            pageSize: 10,           // 一页每个运营商最多展示10个手机号码
            yysmc: '',              // 已选的运营商名称
            sjh: '',                // 已选的手机号码
            sfglxyw: false,         // 是否关联校园网（默认不关联，益阳医专是true，根据系统管理的参数值进行切换）
            sfktxyw: false,         // 是否开通校园网（当sfglxyw为true是才有用）
            editable: true          // 是否可以自选编辑
        };
    }

    componentDidMount() {
        this.checkIsZzbdHjLinked();
        this.queryQztjAndYlhj();
        this.getPhoneNumById(); // 查询学生是否已经办理了，办理了就回填数据
        this.getSfglxyw(); // 查询是否关联校园网
        this.getPhoneNumList( 1, this.state.pageSize);
    }

    // 查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData: res.data});
                    if (kfsj && kfsj['YXSJ'] !== '1') {
                        this.setState({operable: false});
                    }
                    if (ylhj && ylhj.length > 0) {
                        this.setState({operable: false});
                    }
                    if (qzhj && qzhj.length > 0) {
                        this.setState({operable: false});
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    // 环节pkid
    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    // 检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({
                            editable: false
                        });
                    } else {
                        this.setState({
                            editable: true
                        })
                    }
                } else {
                    this.setState({
                        editable: true
                    })
                }
            });
    };

    // 设置左侧菜单
    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({hjmc: hjmc})
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }


    // 获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    // 重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    // 获取参数设置：自选手机号码是否关联了校园网（关联则 增加是否开通校园网下拉框选项，不关联则没有该选项）
    getSfglxyw = () => {
        NHFetch('/zzbdCommon/getCssz', 'GET', {csbz: 'YXXT_ZXSJH_SFXYGLXYW'}).then(res => {
            if (res && res.data) {
                // 返回 "true", "false", null
                this.setState({sfglxyw: res.data == 'true'});
            } else {
                this.setState({sfglxyw: false});
            }
        })
    }

    // 是否自选手机号码
    onChangeZx = (value) => {
        if (value === "1") { // 0否 1是
            this.setState({
                isChoosePhoneNum: true,
                page: 1,
            });
            this.getPhoneNumList( 1, this.state.pageSize);
            this.props.form.setFieldsValue({sjh: undefined, yys: undefined});
        } else {
            this.setState({
                isChoosePhoneNum: false,
                page: 1,
            })
        }
    }

    // 点击换一批手机号码
    onChangeNumList = () => {
        const {page} = this.state;
        this.setState({loading: true});
        if (!this.state.disabledTime == 0) {
            this.count();   // 4s倒计时
        }
        this.getPhoneNumList( page + 1, this.state.pageSize); //改变了运营商需要从第一页开始
            this.setState({
                page: page + 1
        })
        //this.getPhoneNumList(yys, page + 1, this.state.pageSize);
    }

    // 手机号码列表回到第一页
    backToFirstPage = () => {
        this.setState({page: 1, disablebtnClick: false});
        this.getPhoneNumList( 1, this.state.pageSize);
    }

    // 根据运营商获取手机号列表（默认为0中国移动）
    getPhoneNumList = ( page, pageSize) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        NHFetch(basicUrl + "/getPhoneNumList", "GET", params)
            .then(res => {
                if (res) {
                    if (res.data) {
                        if (res.data.length < this.state.pageSize) {
                            this.setState({
                                disablebtnClick: true // 最后一页了，[下一批]按钮不能再按了
                            })
                        }
                        let option = [];
                        option = res.data.map(option =>
                            <Radio key={option.SJH} value={option.SJH+'and'+option.YYS}>{option.SJH}</Radio>
                        );
                        this.setState({phoneNumList: option});
                    }
                }
            });
    }

    // 查询某学生自选的手机号码
    getPhoneNumById = () => {
        const xsid = getLoginUser().userId;
        NHFetch(basicUrl + "/getPhoneNumById", "GET", {xsid: xsid})
            .then(res => {
                if (res) {
                    if (res.data) {
                        let data = res.data;
                        this.setState({
                            yysmc: data.YYSMC,
                            sjh: data.SJH,
                            isChoosePhoneNum: data.SFZX == "1",
                            sfktxyw: data.SFKTXYW == "1",
                            editable: false,
                        });
                    } else {
                        this.setState({
                            editable: true
                        })
                    }
                } else {
                    message.error('获取数据失败，请重试！');
                }
            });
    }

    //倒计时4s
    count = () => {
        let {disabledTime} = this.state;
        let siv = setInterval(() => {
            this.setState({disabledTime: (disabledTime--)}, () => {
                if (disabledTime <= -1) {
                    clearInterval(siv);　//倒计时setInterval函数会每秒执行一次函数，用clearInterval来停止执行
                    this.setState({loading: false, disabledTime: 3})
                }
            });
        }, 1000);
    }

    // 提交保存
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            console.log(values)
            let str = this.state.sfglxyw ? "您选择" + (this.state.sfktxyw ? "" : "不") + "开通校园网，" : "您";
            let formData = {};
            if (this.state.sfglxyw && !values['sfktxyw']) {
                message.error("请选择是否开通校园网！");
                return;
            }
            formData.sfktxyw = values['sfktxyw'] ? values['sfktxyw'] : "0";
            if (values['sfzx'] == '1') {
                let sjhStr = values['sjh'].split("and");
                if (sjhStr[0] == null || typeof (sjhStr[0]) == "undefined" || sjhStr[0].trim().length === 0) {
                    message.error('请选择手机号码！');
                    return
                }
                formData.sfzx = "1";
                formData.yys = sjhStr[1]
                formData.sjh = sjhStr[0]
            } else {
                formData.sfzx = "0";
                formData.yys = "";
                formData.sjh = "";
                str = str + "确定不办理自选手机号吗，";
            }
            str = str + "提交后不能再进行操作！确定提交？";
            NHConfirm(str, () => {
                const {isChoosePhoneNum, editable} = this.state;
                const xsid = getLoginUser().userId;
                const zzbdhjid = this.getUrlParams("pkid");
                let params = {
                    xsid: xsid,
                    zzbdhjid: zzbdhjid,
                    formData: formData
                };
                //请求提交保存
                NHFetch(basicUrl + "/savePhoneNumById", 'POST', params)
                    .then(res => {
                        if (res) {
                            if (res.data == '1') {
                                message.success("保存成功！");
                                if (editable) {
                                    this.setState({editable: false}); //一经提交则不可再修改
                                }
                                this.getPhoneNumById();
                                this.reloadMenu();
                            } else {
                                message.error("保存失败，请重试！");
                            }
                        }
                    })
            }, "warn");
        });
    };


    render() {
        const {getFieldDecorator, getFieldValue, setFieldsValue} = this.props.form;
        const {editable, isChoosePhoneNum, phoneNumList, sjh, yysmc, disablebtnClick, sfglxyw, sfktxyw} = this.state;
        return (
          <div styleName="page_buy">
              {this.state.operable ?
                  <Flex className="w1180 mt-20" align="start">
                      <SiderMenu
                          ref={"siderMenu"}
                          setMenuList={this.setMenuList}
                          title="自助报到"
                          mkbz={this.state.mkbz}
                          menuType="自助报到"
                      />
                      <Flex.Item className="page_con_r">
                          <Breadcrumb
                              noLine
                              list={[
                                  {name: '首页', url: '/index'},
                                  {name: '自助报到', url: '/register'},
                                  {name: this.state.hjmc}
                              ]}
                          />
                          <div className="box">
                              <Hint/>
                              <TitleTop title={this.state.hjmc} extra={false}/>
                              <Form onSubmit={this.handleSubmit}>
                                  <div className="p-sw">
                                      {sfglxyw ?
                                          <Flex>
                                              <div className="form_item">
                                                  <FormItem
                                                      {...formItemLayout}
                                                      label={"是否开通校园网"}>
                                                      {getFieldDecorator('sfktxyw', {
                                                          initialValue: sfktxyw ? "1" : "0",
                                                          placeholder: '请选择',
                                                          rules: [{
                                                              required: true,
                                                              message: "请选择是否开通校园网"
                                                          }]
                                                      })(
                                                          editable ?
                                                              <Select placeholder="请选择" onChange={value => {
                                                                  if (value === "1") { // 0否 1是
                                                                      this.setState({sfktxyw: true});
                                                                  } else {
                                                                      this.setState({sfktxyw: false})
                                                                  }
                                                              }}>
                                                                  <Option value="0">否</Option>
                                                                  <Option value="1">是</Option>
                                                              </Select>
                                                              : <span>{sfktxyw ? '是' : '否'}</span>
                                                      )}
                                                  </FormItem>
                                              </div>
                                          </Flex> : null}
                                      <Flex>
                                          <div className="form_item" style={{display:'none'}}>
                                              <FormItem
                                                  {...formItemLayout}
                                                  label={"是否自选手机号"}>
                                                  {getFieldDecorator('sfzx', {
                                                      initialValue: "1",
                                                  })(
                                                      <Input />
                                                  )}
                                              </FormItem>
                                          </div>
                                      </Flex>
                                      <Flex>
                                          {((isChoosePhoneNum) || (isChoosePhoneNum && !editable)) &&
                                          <div className="form_item">
                                              <FormItem
                                                  {...formItemLayout}
                                                  label="手机号码"
                                              >
                                                  {getFieldDecorator('sjh', {
                                                      initialValue: sjh,
                                                      rules: [{required: true, message: '请选择手机号码'}]
                                                  })(
                                                      editable ?
                                                          <Radio.Group>
                                                              {phoneNumList}
                                                          </Radio.Group>
                                                          : <span>{sjh}</span>
                                                  )}
                                              </FormItem>
                                              { editable ?
                                                  <div className="text-center mt-30">

                                                      <Button type="ghost" loading={this.state.loading}
                                                              onClick={this.onChangeNumList}
                                                              disabled={disablebtnClick}>
                                                          {this.state.loading ? this.state.disabledTime + '秒' : '下一批'}
                                                      </Button>
                                                      {disablebtnClick && this.state.page !== 1 ?
                                                          <Button type="ghost" style={{marginLeft: "15px"}}
                                                                  onClick={this.backToFirstPage}>
                                                              回到第一页
                                                          </Button> : undefined
                                                      }
                                                  </div> : undefined
                                              }
                                          </div>
                                          }
                                      </Flex>
                                      <div className="text-center mt-50">
                                          <Button type="primary" htmlType="submit" disabled={!editable}>保存</Button>
                                          <NextButton nextUrl={this.state.nextUrl} search={this.state.search}/>
                                      </div>
                                  </div>
                              </Form>
                          </div>
                      </Flex.Item>
                  </Flex> : <CommonTip hjmc={"自选手机号码"} key={"GD027_2"} mkbz='GD027_2'
                                       nonOperationalData={this.state.nonOperationalData}/>}
          </div>
      );
    }
}
