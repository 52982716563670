import React from "react";
import { Form, Select, Input, InputNumber, Upload, messag,DatePicker ,Radio,TimePicker,Checkbox, message } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import { Flex } from '@components';
import NHFormItem from '@components/common/NHFormItem/index';
import NHCascader from "@components/common/NHCascader"; 
import { createUuid } from '@utils/NHCore';
import NHFetch from '@utils/NHFetch';
import moment from 'moment';
import './index.css';
import NHModal from "@components/common/NHModal";

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"; //上传logo路径 
const zpTip="<p style=\"white-space: normal;text-align:center;color:#000;font-size:16px;\">—照片要求—</p><br/><p style=\"white-space: normal;\">1.本人清晰、免冠、正面彩色头像；要求面部五官无遮挡，人头部约占照片高度的三分之二；</p><br/><p style=\"white-space: normal;\">2.常戴眼镜的人员允许佩戴眼镜，要求不能为宽边框，拍照时注意镜片不要反光;</p><br/><p style=\"white-space: normal;\">3.照片背景颜色尽量单一，底色为白色、蓝色、红色均可；</p><br/><p style=\"white-space: normal;\">4.照片光线均匀，面部无明显逆光；</p><br/><p style=\"white-space: normal;\">5.照片文件大小要求：大于60KB，小于1MB;</p><br/><p style=\"white-space: normal;\">6.照片像素要求：宽高在200*200以上；</p><p><br/></p>"

import { getLoginUser } from "@utils/NHCore";

function beforeUpload(file) {

    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
        message.error("You can only upload JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
}

class JbxxInitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            basicData:[],
            jbxx: false,
            xsid: '',
            userInfoData: null,
            zzmmmList: [],
            mzmList: [],
            textData: '',
            jgmStr: '',
            jtStr: '',
            xsSsInfo: '',
            zzmmValue:1,//政治面貌
            sfqyValue:-1,//是否迁移户口
            csny:"",
            dxjtList:[],//到校交通工具
            sfzsValue:"",
            dxfsValue:"",//到校方式
            xyContent:"",//协议内容
            values:{},
            sj:"",
            dxrq:"",
            sureButton:false,
            ZSXYFlag:false,
            zzmmList:[
                // {value:"01",label:"中国共产党党员"},
                // {value:"02",label:"中国共产党预备党员"},
                // {value:"03",label:"中国共产主义青年团团员"},
                // {value:"04",label:"中国国民党革命委员会会员"},
                // {value:"05",label:"中国民主同盟盟员"},
                // {value:"06",label:"中国民主建国会会员"},
                // {value:"07",label:"中国民主促进会会员"},
                // {value:"08",label:"中国农工民主党党员"},
                // {value:"09",label:"中国致公党党员"},
                // {value:"10",label:"九三学社社员"},
                // {value:"11",label:"台湾民主自治同盟盟员"},
                // {value:"12",label:"无党派民主人士"},
                // {value:"13",label:"群众"}
                ],//政治面貌
                hkszdDetail:"",
                jgmmc:"",
                zzmmMC:"",
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({ 
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        }
        this.getjbxxData();
        this.loadLxjt();
        this.getZSInfo();
        this.loadZzmm();
    }

      // 获取学生头像
      getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }


    // 政治面貌
    loadZzmm=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dMK_XTGL_ZZMMM').then(res=>{
            if(res && res.code == 200){
                let list = []
                let zzmmList = res.data;
                zzmmList.map((item)=>{
                    list.push({"value":item.VALUE,label:item.LABEL})
                })
                this.setState({
                    zzmmList:list
                })
            }
        });
    }

    // 获取到校使用交通工具
    loadLxjt=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXWZ_LXJTGJ').then(res=>{
            if(res && res.code == 200){
               let dxjtList = res.data;
                this.setState({
                    dxjtList:dxjtList
                })
            }
        });
    }

    getjbxxData= () =>{
         let xsid = getLoginUser() ? getLoginUser().userId : undefined;
        NHFetch("/newStudent/getStudentInfo", 'get',{xsid:xsid})
        .then(res => {
            if (res && res.data) {
                this.setState({
                    basicData:res.data
                })
            }
        });
    }
    onSavejbxxFormData = (cb) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                cb(false);
                return;
            }
            this.setState({
                jbxx: false
            });
            cb(true);
            console.log("66666666666666",values.hkszd,"vvv",values.jgm,"22222222",values);
            let hkszdArray = values.hkszd;
            if(hkszdArray.length>0){
                let num = hkszdArray.length;
                values['hkszd'] = hkszdArray[num-1]
                console.log(values['hkszd']);
            }
            let jgArray = values.hkszd;
            if(this.state.csny!=""&&this.state.csny &&this.state.csny!=null){
                values['csrq']=this.state.csny;
            }else{
                values['csrq'] = this.props.jbxxFormData.csrq
            }
            if(this.state.jgDetail!=""&&this.state.jgDetail){
                values['jgDetail']=this.state.jgDetail;
            }else{
                values['jgDetail'] = this.props.jbxxFormData.jgDetail
            }
            if(this.state.hkszdDetail!=""&&this.state.hkszdDetail){
                values['hkszdDetail']=this.state.hkszdDetail;
            }else{
                values['hkszdDetail'] = this.props.jbxxFormData.hkszdDetail
            }
            if(this.state.zzmmMC!=""&&this.state.zzmmMC){
                values['zzmmmc']=this.state.zzmmMC;
            }else{
                values['zzmmmc'] = this.props.jbxxFormData.zzmmmc
            }
           /*  if(this.state.dxrq && this.state.sj){
                console.log(this.state.dxrq,this.state.sj);
                values.dxsj = this.state.dxrq+this.state.sj;
            }else{
                values.dxsj = this.props.dxsj;
            } */
            // values['hkszd'] = hkszdArray[hkszdArray.length-1]
            // value['jgm'] =  jgArray[jgArray.length-1];
            // values['jbxx_jgm']=values.jbxx_jgm[values.jbxx_jgm.length-1]
            const { cacheFormData } = this.props;
            cacheFormData('jbxx', values);
        });

    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = info => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10';
                NHFetch(url, 'POST')
                    .then(res => {
                        if (res && res.data) {
                            message.success("上传成功!")
                        }
                    });
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({ imageUrl })
        );
    }
    onjgNHCascaderChange= (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
        this.setState({
            jgDetail:label
        })
    }

    onNHCascaderChange = (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
            this.setState({ hkszdDetail: label });

    }

    // 政治面貌回调
    zzmmSelect = value => {
        let mc = ""
        console.log('radio checked', value);
        this.state.zzmmList.map((item)=>{
            if(item.value==value){
                mc=item.label;
            }
        })
        this.setState({
            zzmmValue: value,
            zzmmMC:mc
        },()=>{
            console.log("赋值zzmm",this.state.zzmmMC);
        });
      };

      // 是否迁移户口回调
    sfqyOnChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            sfqyValue: e.target.value,
        });
      };

      //   选择出生年月回调
    timeData=(moment,timeString)=>{
        console.log(moment,timeString);
        this.setState({
            csny:timeString
        })
    }

  // 到校方式回调
  dxfsOnChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
        dxfsValue: e.target.value,
    });
  };

//   选择到校日期回调
dateData=(moment,dateString)=>{
    console.log(moment,dateString);
    this.setState({
        dxrq:dateString
    })
}
//   选择到校时间回调
dxtimeData=(moment,timeString)=>{
    console.log(moment,timeString);
    this.setState({
        sj:timeString
    })
}

// 获取住宿协议
getZSInfo = ()=>{
    NHFetch('/xxfb/getFbnrByFlbz','get',{flbz:"XY_ZS"}).then(res=>{
        if(res&&res.code==200){
           console.log("res",res);
           this.setState({
               xyContent:res.data
           })
        }
    });
}

// 是否住宿回调
sfzsOnChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
        sfzsValue: e.target.value,
    });
    if(e.target.value == '1'){
        this.nhModal.show();
    }else{
        this.setState({
            ZSXYFlag:false
        })
    }
    
  };
//   是否住宿弹窗的勾选事件
  onChangeCheck=(e)=> {
    console.log(`checked = ${e.target.checked}`);
    if(e.target.checked){
        this.setState({
            ZSXYFlag:true
        })
    }else{
        this.setState({
            ZSXYFlag:false
        })
    }
  }


    // 取消按钮
    handleCancel=()=>{
        this.setState({
            jbxx: true,
            sureButton:false
        });
    }

    // 是否住宿弹窗确定按钮
    handleOk = (stopLoading) => {
        const { cacheFormData } = this.props;
                if(this.state.ZSXYFlag){
                    this.nhModal.close();
                    this.setState({
                        sureButton:true
                    })
                }else{
                    message.warn("请勾选同意住宿协议，再确定");
                }
        }
    zphandleOk=()=>{
        this.zpModal.close();
        // this.beforeUpload();
        this.setState({
            modal:false
        },()=>{
            this.handleUploadChange(this.state.modalInfo);
        })

    }

    // modalSure=(info)=>{
    //     this.zpModal.show();
    // }

    openZPModal=(info)=>{
        this.zpModal.show();
        this.setState({
            modalInfo:info
        })
    }

    render() {
        const { form, jbxxFormData, getChildComponent, userInfoData, stuSsxx, editXszp,kzxxInitData,SFBT,blztFlag,dxxxFormData } = this.props;
        const { jbxx, imageUrl, tempUrl, xsid,zzmmList,basicData,dxjtList,sfzsValue,dxfsValue  } = this.state;
        const jtjddz = jbxxFormData ? jbxxFormData.jtjddz : '';
        let ssxx = '';
        let zdz = ""
        console.log("照片是否可以编辑-----",editXszp);
        console.log("kzxxInitData",kzxxInitData,jbxxFormData.xxcj_zdyhksfqy);
       if(jbxxFormData.xxcj_zdyhksfqy==undefined){
           if(kzxxInitData){
               kzxxInitData.map((item)=>{
                   if(item.zdbz=="ZDYHKSFQY"){
                       zdz = item.zdz; 
                       console.log(item.zdz);
                       }
                   })
           }
        }else{
            zdz = jbxxFormData.xxcj_zdyhksfqy;
        }
        
        // 到校时间
        let dx_sj ="";
        let dxrq = null;
        if(jbxxFormData && jbxxFormData.dxsj){
            let dateStr = jbxxFormData.dxsj
            /* let dxrq = dateStr.substring(0,10);
            let sj = dateStr.substring(11,18); */
            dxrq=dateStr.substring(0,10);
            dx_sj = dateStr.substring(10);
            console.log(dateStr,dxrq,dx_sj);
        }
        // 是否住宿弹窗页脚
        let footer = [
            {text:'确定',type:'primary',onClick:this.handleOk}
        ];

        if (stuSsxx && stuSsxx.xqmc && stuSsxx.ldmc && stuSsxx.fj && stuSsxx.cwh) {
            ssxx = stuSsxx.xqmc + stuSsxx.ldmc + ' - ' + stuSsxx.fj + ' - ' + stuSsxx.cwh;
        }
        console.log(jbxxFormData);
        return (
  
            <Form layout="inline">
                <TitleTop
                    title="基本信息"
                    onEdit={type => {
                        this.setState({
                            jbxx: true,
                            sfqyValue:zdz,
                            dxfsValue:jbxxFormData?jbxxFormData.jtgj:""
                        });
                        getChildComponent(1, false);
                    }}
                    onSave={cb => {
                        this.onSavejbxxFormData(cb);//现在不需要单条保存数据
                        getChildComponent(1, true);
                    }}
                    onClose={() => {
                        this.setState({
                            jbxx: false
                        });
                    }}
                />

                <Flex className="p-sw">
                    <div styleName="jbxx_icon" className="_bg">
                        {jbxx && editXszp === "true" ?
                            <Upload
                                name="photo"
                                accept={'image/*'}
                                showUploadList={false}
                                action={uploadUrl + "?xsid=" + xsid}
                                beforeUpload={this.beforeUpload}
                                onChange={this.openZPModal}
                            >
                                {imageUrl || userInfoData ? (
                                    imageUrl ?

                                        <img
                                            src={imageUrl}
                                            alt=""
                                            className="avatar"
                                        />
                                        : tempUrl
                                ) : (
                                        <div styleName="upload-box">
                                            <div styleName="upload-title">
                                                上传图片
                                        </div>
                                        </div>
                                    )}
                            </Upload>
                            :
                            imageUrl ?
                                <img
                                    src={imageUrl}
                                    alt=""
                                    className="avatar"
                                    style={{border:"1px solid #1890ff",padding:"5px"}}
                                />
                                :
                                tempUrl

                        }

                    </div>
                    <Flex.Item className="ml-20">
                        <div styleName="jbxx_item">
                            <span styleName="item-label">姓名：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {basicData ? basicData.XM : ''}

                            </div>
                        </div>
                        <div styleName="jbxx_item">
                            <span styleName="item-label">性别：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >

                                {
                                    basicData && basicData.XB ?

                                    basicData.XB === '男' ?
                                            <img src={require('@images/boy.png')} alt="" />
                                            : basicData.XB === '女' ?
                                                <img src={require('@images/girl.png')} alt="" />
                                                :
                                                <img src={require('@images/weizhixb.png')} alt="" />
                                        : ''

                                }

                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">民族：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {basicData ? basicData.MZ : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">学号：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {basicData ? basicData.XH : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                        <span styleName="item-label">学院：</span>
                        <div
                            className="t-clamp-1"
                            styleName="item-name"
                            style={{ display: 'inline' }}
                        >
                            {basicData ? basicData.BM : ''}
                        </div>
                    </div>

                    <div styleName="jbxx_item">
                        <span styleName="item-label">专业：</span>
                        <div
                            className="t-clamp-1"
                            styleName="item-name"
                            style={{ display: 'inline' }}
                        >
                            {basicData ? basicData.ZY : ''}
                        </div>
                    </div>

                    <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'csrq'}
                                label={<span styleName="item-label">出生年月：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true}
                                
                                initialValue={jbxxFormData.csrq ? moment(jbxxFormData.csrq, 'YYYY-MM-DD HH:mm') : null}
                            >
                                <DatePicker onChange={this.timeData} placeholder={"选择日期..."} format={"YYYY-MM-DD"}/>
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>出生年月：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.csrq  : ''}
                                </div></div>
                        }
                    </div>

                    <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'byxx'}
                                label={<span styleName="item-label">毕业中学：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                initialValue={jbxxFormData ? jbxxFormData.byxx : ""}
                            >
                                <Input style={{ width: 140 }} placeholder="请填写毕业中学" />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>毕业中学：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.byxx : ''}
                                </div></div>
                        }
                    </div>

                    <div styleName="jbxx_item" style={{display:"flex"}}>
                        
                        {jbxx ?
                            <NHFormItem id={'sg'}
                                label={<span styleName="item-label">身高：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                initialValue={jbxxFormData ? jbxxFormData.sg : ""}
                            >
                                <Input style={{ width: 140 }} placeholder="请填写身高(cm)" />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>身高：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.sg : ''}
                                </div></div>
                        }
                        <span style={{padding:"6px 0px",color:"#000"}}>cm</span>
                    </div>

                    <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'xqah'}
                                label={<span styleName="item-label">兴趣爱好：</span>}
                                colon={false}
                                form={form}
                                // required={true}
                                // hasFeedback={true} 
                                // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                initialValue={jbxxFormData ? jbxxFormData.xqah : ""}
                            >
                                <Input style={{ width: 140 }} placeholder="请填写兴趣爱好" />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label">兴趣爱好：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.xqah : ''}
                                </div></div>
                        }
                    </div>
                    <div>
                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'hkszd'}
                                    label={<span styleName="item-label">户口所在地：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={jbxxFormData ? jbxxFormData.hkszdArray : ""}
                                >
                                    {/* <Input style={{ width: 140 }} placeholder="请填写户口所在地" /> */}
                                    <NHCascader style={{ width: 400 }} sign={"zhxg_ssq"} onChange={(value, selectedOptions) => this.onNHCascaderChange(value, selectedOptions, 'jt')}/>
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>户口所在地：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {jbxxFormData ? jbxxFormData.hkszdDetail : ''}
                                    </div></div>
                            }
                        </div>
                    </div>

                    <div styleName="jbxx_item2">
                        {jbxx ?
                            <NHFormItem id={'jgm'}
                            label={<span styleName="item-label">籍贯：</span>}
                            colon={false}
                            required={true}
                            form={form}
                            initialValue={jbxxFormData ? jbxxFormData.jgArray: []}
                        >
                            <NHCascader style={{ width: 400 }} sign={"zhxg_ssq"} onChange={(value, selectedOptions) => this.onjgNHCascaderChange(value, selectedOptions, 'jt')}/>
                        </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>籍贯：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.jgDetail: ''}
                                </div></div>
                        }
                    </div>

                    

                    <div>
                        <div styleName="jbxx_item2">
                            {jbxx ?
                                <NHFormItem id={'zzmmm'}
                                    label={<span styleName="item-label">政治面貌：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={jbxxFormData ? jbxxFormData.zzmmm : ""}
                                >
                                    <Select defaultValue={jbxxFormData ? jbxxFormData.zzmmm : ''} style={{ width: 220 }} onChange={this.zzmmSelect} value={this.state.zzmmValue} placeholder="请选择...">
                                            {
                                                zzmmList.map((item,index)=>{
                                                    return <Option value={item.value}>{item.label}</Option>
                                                })
                                            }
                                    </Select>
                                            
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>政治面貌：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {jbxxFormData ? jbxxFormData.zzmmmc: ''}
                                    </div></div>
                            }
                        </div>
                    </div>

                    {/* <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'dxrq'}
                                label={<span styleName="item-label">到校日期：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} moment(dxrq,"YYYY-MM-DD")
                                initialValue={jbxxFormData ? (dxrq!=null?moment(dxrq,"YYYY-MM-DD"):null) : null}
                            >
                                <DatePicker onChange={this.dateData} placeholder={"选择日期..."} format={"YYYY-MM-DD"}/>
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校日期：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {dxrq ? dxrq : ''}
                                </div></div>
                        }
                    </div>  */}

                    {/* <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'sj'}
                                label={<span styleName="item-label">到校时间：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                initialValue={jbxxFormData ? (jbxxFormData.dxsj!=null?moment(jbxxFormData.dxsj,"HH:mm:ss"):"") :""}
                            >
                                <TimePicker onChange={this.dxtimeData}  placeholder={"请选择..."} />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校时间：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {dx_sj ? dx_sj : ''}
                                </div></div>
                        }
                    </div>  */}

                    {/* <div styleName="jbxx_item">
                        {jbxx ?
                            <NHFormItem id={'ptrs'}
                                label={<span styleName="item-label">到校随行人数：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}InputNumber
                                initialValue={jbxxFormData ? jbxxFormData.ptrs : ""}
                            >
                                <InputNumber style={{ width: 140 }} placeholder="请填写随行人数" />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校随行人数：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.ptrs : ''}
                                </div></div>
                        }
                    </div> */}
                    
                    <div className="p-swc" style={{padding:"0px"}}>
                        <div styleName="jbxx_item" style={{width:'100%'}}>
                                {jbxx ?
                                    <div style={{display:"flex"}}>
                                        <div style={{padding: "10px",color:"#9A9A9A",fontSize:"12px"}}>在校期间是否申请入住学生宿舍：</div>
                                        <NHFormItem id={'sfzs'}
                                            label={''}
                                            colon={false}
                                            form={form}
                                            required={true}
                                            // hasFeedback={true} 
                                            // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                            initialValue={jbxxFormData ? jbxxFormData.sfzs: ""}
                                        >
                                                <Radio.Group onChange={this.sfzsOnChange} value={sfzsValue} style={{display:"flex",margin:"10px"}}>
                                                    <Radio value={'1'}>申请住宿</Radio>
                                                    <Radio value={'0'}>不申请住宿</Radio>
                                                </Radio.Group>
                                        </NHFormItem>
                                    </div>
                                    : <div>
                                        <span styleName="item-label"><span style={{ color: 'red' }}>*</span>在校期间是否申请入住学生宿舍：</span>
                                        <div
                                            className="t-clamp-1"
                                            styleName="item-name"
                                            style={{ display: 'inline' }}
                                        >
                                            {jbxxFormData ? (jbxxFormData.sfzs=='1'?"申请住宿":(jbxxFormData.sfzs=='0'?"不申请住宿":"")) : ''}
                                        </div></div>
                                }
                        </div>
                    </div>

                    {/* <div className="p-swc">
                        <div styleName="jbxx_item2">
                            {jbxx ?
                                    <NHFormItem id={'jtgj'}
                                        label={<span styleName="item-label">到校方式：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        initialValue={jbxxFormData ? jbxxFormData.jtgj: ""}
                                    >
                                        <div>
                                            <Radio.Group onChange={this.dxfsOnChange} value={dxfsValue} style={{display:"flex",margin:"10px"}}>
                                            {
                                                    dxjtList.map((item,index)=>{
                                                        return  <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                    </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>到校方式：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {jbxxFormData ?jbxxFormData.jtgjmc : ''}
                                    </div></div>
                            }
                        </div>
                    </div> */}

                    {/* <div className="p-swc">
                        <div styleName="jbxx_item2" style={{width:'50%'}}>
                            
                            {jbxx ?
                                <div style={{display:'flex'}}>
                                    <div style={{padding:"10px 0px"}}>
                                        <span style={{ color: 'red' }}>*</span>
                                        <span styleName="item-label">户口是否准备迁入学校：</span>
                                    </div>
                                    <NHFormItem id={'xxcj_zdyhksfqy'}
                                        label={''}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        // hasFeedback={true} 
                                        // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                        initialValue={zdz ? zdz: ""}
                                    >
                                        <div>
                                            <Radio.Group onChange={this.sfqyOnChange} value={this.state.sfqyValue} style={{display:"flex",margin:"10px"}}>
                                                <Radio value={'1'}>是</Radio>
                                                <Radio value={'0'}>否</Radio>
                                            </Radio.Group>
                                        </div>
                                    </NHFormItem>
                                </div>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>户口是否准备迁入学校：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {zdz ? (zdz=='1'?'是':(zdz=='0'?'否':"")):""}
                                    </div></div>
                            }
                        </div>
                    </div> */}
                    </Flex.Item>
                </Flex>

                <NHModal
                    ref={(form) => { this.zpModal = form }}
                    title="照片规范"
                    width={"40%"}
                    // style={{width:"70%"}}
                    onOk={this.zphandleOk}
                    // footer={footer}
                    // onCancel={this.zphandleCancel}
                >
                    <img src={require('../../../../../assets/images/ylt.png')} style={{width:"50%",position:"relative",left:"25%"}}></img>
                    <div dangerouslySetInnerHTML={{__html: zpTip}} style={{textAlign:"center"}}></div>
                </NHModal>

                <NHModal
                    ref={(form) => { this.nhModal = form }}
                    title="住宿协议"
                    width={"60%"}
                    // style={{width:"70%"}}
                    onOk={this.handleOk}
                    footer={footer}
                    onCancel={this.handleCancel}
                >
                    <div>
                    <div dangerouslySetInnerHTML={{__html: this.state.xyContent}}></div>
                    <Checkbox onChange={this.onChangeCheck} checked={this.state.ZSXYFlag}>
                            <span style={{color:"red"}}>我已阅读并同意该协议。</span>
                    </Checkbox>
                    </div>
                   
                </NHModal>
                <style jsx="true" global="true">
                 {`
                          .ant-col-md-7 {  
                            width: 100px; 
                           }
                `}
          </style>
            </Form>
        );
    }
}


const JbxxForm = Form.create()(JbxxInitForm);

export default JbxxForm;