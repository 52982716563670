import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {Button,Card,Row,Col,Popover} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import createHistory from 'history/createHashHistory';
import {mkbz} from "../../Assembly/siderMenu/menuObj";
const history = createHistory();

export default class CommonTip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextUrl:'',
            search:'',
            menuList:[],
        };
    }

    componentWillReceiveProps(nextProps){
        const nonOperationalData = this.props.nonOperationalData
        const nextnonOperationalData = nextProps.nonOperationalData;
        const mkbz = this.props.mkbz;
        const nextMkbz = nextProps.mkbz;
        if(Object.is(nonOperationalData,nextnonOperationalData) && Object.is(mkbz,nextMkbz)){
            this.setNext(this.state.menuList);
        }
    }



    setMenuList=(menuList)=>{
        this.setState({menuList:menuList});
        this.setNext(menuList);
    }

    setNext=(menuList)=>{
        if(!menuList || menuList.length==0){
            return;
        }
        let nextCount = 0;
        let bz = this.props.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                });
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = '/customLink';
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                });
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }



    //依赖环节跳转路径
    to_ylhj=(elem)=>{
        let hjdm = elem.HJDM;
        if(hjdm.indexOf('GD')==0){
            let hashUrl = mkbz[hjdm];
            history.push({
                    pathname:hashUrl,
                    search:'pkid='+elem.PKID,
                }
            );
        }else if(hjdm.indexOf('ZDY')==0){
            let hashUrl = '/customLink';
            history.push({
                    pathname:hashUrl,
                    search:'pkid='+elem.PKID+'&mkbz='+hjdm+'&lx='+elem.LX,
                }
            );
        }
    }

    render() {
        const data = this.props.nonOperationalData;
        return (
            <div style={{position:'relative'}}>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                                setMenuList={this.setMenuList}
                                title="自助报到"
                                mkbz = {this.props.mkbz}
                                menuType='自助报到'/>
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.props.hjmc }
                            ]}
                        />
                        <div className="box">
                                <div className="tip">
                                    <span className="text-info-red">
                                        温馨提示：
                                    </span>
                                    <span>以下原因导致该环节暂未开放</span>
                                </div>
                                <TitleTop title="原因提示" extra={false}/>

                                <Flex align="start" wrap="wrap">
                                    {data['nologin']&&<Card style={{paddingLeft:0,width:'100%'}} bordered={false}>
                                        <Row gutter={24}>
                                            <Col span={24} style={{lineHeight:'22px',marginTop:'30px'}}>
                                                <span className="text-info-red">登录超时</span>
                                            </Col>
                                        </Row>
                                    </Card>}
                                    {data['kfsj'] && data['kfsj']['YXSJ']!='1'&&<Card style={{paddingLeft:0,width:'100%'}} bordered={false}>
                                        <Row gutter={24}>
                                            <Col span={24} style={{lineHeight:'22px', marginTop:'30px'}}>
                                                <b style={{fontSize:'15px'}}>开放时间</b >：<span className="text-info-red">{data['kfsj']['KSSJ']}</span>&emsp;至&emsp;<span className="text-info-red">{data['kfsj']['JSSJ']}</span>
                                            </Col>
                                        </Row>
                                    </Card>}
                                    {data['ylhj']&&data['ylhj'].length>0&&
                                    <div style={{width:'100%',position:'relative',marginTop:'30px',left:'21px',textAlign:'left'}}>
                                        <p style={{color: '#a49e9e', fontSize:'15px'}}>以下环节未办理，请先办理！</p>
                                        {data['ylhj'].map((elem,index)=>{
                                            return(
                                                <Button type="primary" onClick={()=>{this.to_ylhj(elem)}} style={{marginRight:'10px'}} ghost>{elem.HJMC}</Button>
                                                // <Button onClick={()=>{this.to_ylhj(elem)}} type="primary" style={{marginRight:'10px'}}>{elem.HJMC}</Button>
                                            )
                                        })}
                                    </div>
                                    }
                                    {data['qzhj']&&data['qzhj'].length>0&&
                                    <Card style={{paddingLeft:0,width:'100%'}} bordered={false}>
                                        <Row gutter={24}>
                                            <Col span={24} style={{lineHeight:'22px',marginTop:'30px'}}>
                                                <p style={{color: '#a49e9e', fontSize:'15px'}}>以下迎新现场环节或未办理，请先办理！</p>
                                                {data['qzhj'].map((elem,index)=>{
                                                    return(
                                                        <Button type="primary" style={{marginRight:'10px'}}>{elem.hjmc}</Button>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Card>
                                    }
                                     {data['nohj']&&<Card style={{paddingLeft:0,width:'100%'}} bordered={false}>
                                        <Row gutter={24}>
                                            <Col span={24} style={{lineHeight:'22px',marginTop:'30px'}}>
                                                <span className="text-info-red">环节不存在</span>
                                            </Col>
                                        </Row>
                                    </Card>}
                                    <div style={{textAlign:'right',width: '100%'}}>
                                        <img src={require('@images/question1.png')}/>
                                    </div>
                                </Flex>
                                <div className="text-center mt-50">
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>
                        </div>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}
