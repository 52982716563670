import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Form, DatePicker, Input, InputNumber, Button, Select, Table, Icon, TimePicker } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import { message } from "antd/lib/index";
import moment from 'moment';
import NHSelect from "@components/common/NHSelect";
import { createUuid, getLoginUser } from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
// import 'moment/src/locale/zh-cn';
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const image = require('./images/hngs-ewmhy.png');

const history = createHistory();
const format = 'HH:mm';


const basicUrl = "/zzbd";
const commonUrl = '/zzbdCommon';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class IdentityVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD010_6',
            hjmc: '',
            data: [],
            loading: false,
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            blztFlag: "0",
            imgFlag: false,
        };
    }

    componentDidMount() {
        this.queryQztjAndYlhj();
        this.getXshyztByXsid();
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    } else if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    } else if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    } else if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                let blztFlag = elem.BLZT;
                this.setState({ hjmc, blztFlag })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    getXshyztByXsid = () => {
        NHFetch("/hngs/zzbd/getXshyztByXsid", 'GET').then(res => {
            if (res && res.data && res.data == '2') {
                this.commonSaveLinkTransactionStatus();
            }
        })
    }

    //自动办理环节
    commonSaveLinkTransactionStatus = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : "";
        let zzbdhjid = this.getQueryString("pkid");
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
            res => {
                if (res) {
                    if (res.data === 1) {
                        this.setState({ imgFlag: false });
                        this.reloadMenu();
                    }
                } else {
                    message.error("办理环节失败，请稍后重试！");
                }
            }
        );
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    handleVerification = () => {
        this.setState({
            imgFlag: true
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { operable, blztFlag, mkbz, hjmc, imgFlag, editable, initPOvalue, lxjtgjmc, options, dqddz, isHide, lxjtgjbc, lxrqbc, isShow } = this.state;
        return (
            <div>
                {operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r" styleName="">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={hjmc} extra={false} />
                                <div className="p-sw text-center mt-30">
                                    {imgFlag && <img style={{ width: 235, height: 235 }} src={image} alt="湖南工商-二维码核验"></img>}
                                    {blztFlag == "1" && <span>您已完成身份核验，请办理下一环节</span>}
                                    <div style={{ marginTop: 20 }}>
                                        {
                                            blztFlag == "1" ?
                                                <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                                :
                                                <span>
                                                    <Button type="primary" onClick={this.handleVerification} > {'身份核验办理'} </Button>
                                                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.getXshyztByXsid} > {'刷新'} </Button>
                                                </span>
                                        }
                                    </div>
                                </div>

                            </div>
                        </Flex.Item>
                    </Flex>
                    :
                    <CommonTip hjmc={"身份核验"} key={'GD010_6'} mkbz='GD010_6' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        )
    }
}