import React from "react";
import { Form, Input, Radio } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import { Flex } from '@components';
import NHFormItem from '@components/common/NHFormItem/index';
import NHCascader from "@components/common/NHCascader";
import { createUuid } from '@utils/NHCore';
import NHFetch from '@utils/NHFetch';
import { FormRules } from "@utils/RegExp";
import './index.css';

import { getLoginUser } from "@utils/NHCore";
class JbxxInitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jbxx: false,
            xsid: '',
            userInfoData: null,
            zzmmmList: [],
            mzmList: [],
            textData: '',
            jgmStr: '',
            jtStr: '',
            xsSsInfo: '',
            zzmmValue: 1,//政治面貌
            sfqyValue: 1,//是否迁移户口
            jjlxrData: [],//紧急联系人数据
            jjlxsValue: "",
            jjlxrmc: ""
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.setState({
                xsid: userId
            });
        }
        this.getgxData();
    }

    // 获取关系数据
    getgxData = () => {
        let params = {
            sign: "dmk_XSXX_JJLXR_GX"
        }
        NHFetch('/proData/selectDataList', 'GET', params)
            .then(res => {
                if (res && res.code == 200) {
                    this.setState({
                        jjlxrData: res.data
                    })
                } else {
                    message.error("获取紧急联系人数据失败!")
                }

            });
    }

    onSavelxxxFormData = (cb) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                cb(false);
                return;
            }
            cb(true);
            let jtszdq = values.jtszdq;
            if (jtszdq.length > 0) {
                let num = jtszdq.length;
                values['jtszdq'] = jtszdq[num - 1]
            }
            const { cacheFormData } = this.props;
            if (this.state.jtStr !== '') {
                values.jtdzDetail = this.state.jtStr;
            } else {
                values.jtdzDetail = this.props.lxxxFormData ?
                    this.props.lxxxFormData.jtdzDetail ? this.props.lxxxFormData.jtdzDetail : ""
                    : "";
            }
            if (values['gxdm'] != '08') {
                values['gxmc'] = null
            }
            values['gx'] = this.state.jjlxrmc;
            console.log(values);
            cacheFormData('lxxx', values);
            this.setState({
                jbxx: false
            });
        });

    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = info => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10';
                NHFetch(url, 'POST')
                    .then(res => {
                        if (res && res.data) {
                            message.success("上传成功!")
                        }
                    });
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({ imageUrl })
        );
    }

    onNHCascaderChange = (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
        if (type == 'jg') {
            this.setState({ jgmStr: label });
        } else {
            this.setState({ jtStr: label });
        }

    }

    // 是否迁移户口回调
    jjlxrgxOnChange = e => {
        let jjlxrmc = ""
        console.log('radio checked', e.target.value);
        this.state.jjlxrData.map((item) => {
            if (e.target.value == item.VALUE) {
                jjlxrmc = item.LABEL;
            }
        })
        this.setState({
            jjlxsValue: e.target.value,
            jjlxrmc: jjlxrmc
        });
    };

    render() {
        const { form, lxxxFormData, getChildComponent, stuSsxx } = this.props;
        const { jbxx, jjlxrData } = this.state;
        const jtjddz = lxxxFormData ? lxxxFormData.jtjddz : '';
        const jtdzDetail = lxxxFormData ? lxxxFormData.jtdzDetail : '';
        let ssxx = '';
        if (stuSsxx && stuSsxx.xqmc && stuSsxx.ldmc && stuSsxx.fj && stuSsxx.cwh) {
            ssxx = stuSsxx.xqmc + stuSsxx.ldmc + ' - ' + stuSsxx.fj + ' - ' + stuSsxx.cwh;
        }
        return (

            <Form layout="inline">
                <TitleTop
                    title="联系方式"
                    onEdit={type => {
                        this.setState({
                            jbxx: true,
                            jjlxsValue: lxxxFormData.gx
                        });
                        getChildComponent(2, false);
                    }}
                    onSave={cb => {
                        this.onSavelxxxFormData(cb);
                        getChildComponent(2, true);
                    }}
                    onClose={() => {
                        this.setState({
                            jbxx: false
                        });
                    }}
                />

                <Flex className="p-sw">
                    <Flex.Item className="ml-20">

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'sjhm'}
                                    label={<span styleName="item-label">手机号：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/, message: "请输入正确的手机号码或电话号码" }}
                                    pattern={FormRules.MOBILE_PHONE_NUMBER}
                                    initialValue={lxxxFormData ? lxxxFormData.sjhm : ""}
                                >
                                    <Input style={{ width: 140 }} placeholder="请填写手机号" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>手机号：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.sjhm : ''}
                                    </div></div>
                            }
                        </div>

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'dzyx'}
                                    label={<span styleName="item-label">电子邮箱：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={lxxxFormData ? lxxxFormData.dzyx : ""}
                                >
                                    <Input style={{ width: 140 }} placeholder="请填写电子邮箱" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>电子邮箱：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.dzyx : ''}
                                    </div></div>
                            }
                        </div>

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'qq'}
                                    label={<span styleName="item-label">QQ号：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={lxxxFormData ? lxxxFormData.qq : ""}
                                >
                                    <Input style={{ width: 140 }} placeholder="请填写QQ号" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>QQ号：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.qq : ''}
                                    </div></div>
                            }
                        </div>

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'wx'}
                                    label={<span styleName="item-label">微信号：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={lxxxFormData ? lxxxFormData.wx : ""}
                                >
                                    <Input style={{ width: 140 }} placeholder="请填写微信号" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>微信号：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.wx : ''}
                                    </div></div>
                            }
                        </div>

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'jjlxr'}
                                    label={<span styleName="item-label">紧急联系人：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={lxxxFormData ? lxxxFormData.jjlxr : ""}
                                >
                                    <Input style={{ width: 140 }} placeholder="请填写紧急联系人" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>紧急联系人：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.jjlxr : ''}
                                    </div></div>
                            }
                        </div>

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'lxrdh'}
                                    label={<span styleName="item-label">紧急联系人电话：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/, message: "请输入正确的手机号码或电话号码" }}
                                    pattern={FormRules.MOBILE_PHONE_NUMBER}
                                    initialValue={lxxxFormData ? lxxxFormData.lxrdh : ""}
                                >
                                    <Input style={{ width: 150 }} placeholder="请填写紧急联系人电话" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>紧急联系人电话：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.lxrdh : ''}
                                    </div></div>
                            }
                        </div>

                        <div styleName="jbxx_item2">
                            {jbxx ?
                                <div>
                                    {/* <span styleName="item-label">与紧急联系人关系：</span> */}
                                    <NHFormItem id={'gxdm'}
                                        label={<span styleName="item-label">与紧急联系人关系：</span>}
                                        colon={false}
                                        form={form}
                                        required={true}
                                        // hasFeedback={true} 
                                        // pattern={FormRules.MOBILE_PHONE_NUMBER}
                                        initialValue={lxxxFormData ? lxxxFormData.gx : ""}
                                    >
                                        <Radio.Group onChange={this.jjlxrgxOnChange} style={{ display: "flex", margin: "10px" }}>
                                            {/* <Radio value={1}>父子</Radio>
                                                <Radio value={2}>母子</Radio>
                                                <Radio value={3}>父女</Radio>
                                                <Radio value={4}>母女</Radio> */}
                                            {
                                                jjlxrData && jjlxrData.map((item, index) => {
                                                    return <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                })
                                            }
                                        </Radio.Group>
                                    </NHFormItem>
                                </div>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>与紧急联系人关系：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.gxmc_dmk : ''}
                                    </div></div>
                            }
                        </div>

                        {this.state.jjlxsValue == "08" && <div>
                            {jbxx ?
                                <NHFormItem id={'gxmc'}
                                    label={<span styleName="item-label">其他关系：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={lxxxFormData ? lxxxFormData.gxmc : ""}
                                >
                                    <Input style={{ width: 180 }} placeholder="请填写其他紧急联系人" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>其他关系：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.gxmc : ''}
                                    </div></div>
                            }
                        </div>}

                        <div styleName="detail_adrress">
                            <span styleName="item-label" style={{ lineHeight: '32px' }}><span style={{ color: 'red' }}>*</span>现详细居住住址：</span>
                            {jbxx ?
                                <Flex>
                                    <NHFormItem id={'jtszdq'}
                                        label={''}
                                        colon={false}
                                        required={true}
                                        form={form}
                                        initialValue={lxxxFormData ? lxxxFormData.jtszdqArray : []}
                                    >
                                        <NHCascader style={{ width: 432.63 }} sign={"zhxg_ssq"} onChange={(value, selectedOptions) => this.onNHCascaderChange(value, selectedOptions, 'jt')}

                                        />

                                    </NHFormItem>
                                    <NHFormItem id={'jtjddz'}
                                        label={''}
                                        colon={false}
                                        required={true}
                                        form={form}
                                        pattern={{ max: 100, message: "家庭住址长度不能超过100字符！" }}
                                        initialValue={lxxxFormData ? lxxxFormData.jtjddz : ''}>
                                        <Input style={{ width: 346.07 }} placeholder="请填写详细家庭地址，如：xx街道、xx小区、门牌号" />
                                    </NHFormItem>
                                </Flex>

                                :
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jtdzDetail ? jtdzDetail : ''}{jtjddz ? jtjddz : ''}
                                </div>
                            }
                        </div>

                        <div styleName="jbxx_item">
                            {jbxx ?
                                <NHFormItem id={'yzbm'}
                                    label={<span styleName="item-label">邮政编码：</span>}
                                    colon={false}
                                    form={form}
                                    required={true}
                                    // hasFeedback={true} 
                                    // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                    initialValue={lxxxFormData ? lxxxFormData.yzbm : ""}
                                >
                                    <Input style={{ width: 150 }} placeholder="请填写邮政编码" />
                                </NHFormItem>
                                : <div>
                                    <span styleName="item-label"><span style={{ color: 'red' }}>*</span>邮政编码：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {lxxxFormData ? lxxxFormData.yzbm : ''}
                                    </div></div>
                            }
                        </div>

                    </Flex.Item>
                </Flex>
                <style jsx="true" global="true">
                    {`
                          .ant-col-md-7 {  
                            width: 110px; 
                           }
                `}
                </style>
            </Form>
        );
    }
}


const JbxxForm = Form.create()(JbxxInitForm);
export default JbxxForm;