import React from "react";
import { Form, Input, Table } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import { Flex } from '@components';
import NHFormItem from '@components/common/NHFormItem/index';
import NHCascader from "@components/common/NHCascader"; 
import { createUuid } from '@utils/NHCore';
import NHFetch from '@utils/NHFetch';
import NHModal from "@components/common/NHModal";
import './index.css';

import { getLoginUser } from "@utils/NHCore";

export default class ZyzxxInitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xsid: '',
            textData: '',
            zyzData: '',
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        this.loadZyzxx(userId);
    }

    // 获取志愿者信息
    loadZyzxx = (userId) =>{
        NHFetch('/newStudent/'+userId+'/getZyzInfoByXsid','get').then(res=>{
            if(res && res.code == 200){
               let zyzData = res.data;
                this.setState({
                    zyzData: zyzData
                })
            }
        });
    }

    render() {
        const { form, getChildComponent } = this.props;
        const { zyzData } = this.state;

        const zyzxxColumns = [
            {
                title: '姓名',
                key:'xm',
                dataIndex: 'XM',
                render: (text, record, index) => {
                    return text;
                }
            },
            {
                title: '性别',
                dataIndex: 'XB',
                render: (text, record, index) => {
                    return text;
                }
            },
            {
                title: '联系电话',
                dataIndex: 'LXDH',
                render: (text, record, index) => {
                    return text;
                }
            },
            {
                title: '微信号',
                dataIndex: 'WX',
                render: (text, record, index) => {
                    return text;
                }
            }
        ];

        return (
            <div>
                 <TitleTop
                    title="志愿者信息"
                    extra={false}
                />

                <Form>
                <div className="p-sw">
                    <Table
                        bordered
                        size="middle"
                        pagination={false}
                        dataSource={zyzData}
                        columns={zyzxxColumns}
                    />
                </div>
                </Form>
            </div>
        );
    }
}