import React from 'react';
import './index.css';
import SiderMenu from '@pages/Assembly/siderMenu';
import { Flex } from '@components';
import { Button, Table, Icon, InputNumber, Input, Modal, message } from 'antd';
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid, getLoginUser } from '@utils/NHCore';
import NHFetch from "../../../../utils/NHFetch";
import CommonTip from "../common";
import NextButton from '@pages/SelfHelp/common/nextIndex'

const {confirm} = Modal;

export default class dormitory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD023',
            hjmc: '',
            operable: true,//是否显示环节
            isAllPay: true,
            xsssruYwxx: [],
            jgfsx:''
        };
    }

    componentDidMount() {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        this.getUserCjById();
        this.getJgfsx();
        this.queryQztjAndYlhj();
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    //查询学生入学教育考试成绩

    getUserCjById = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const zzbdhjid = this.getUrlParams("pkid");
        const taskcsbz = "YXXT_RXJYKS_TASKID";
        const jgfcsbz = "YXXT_RXJYKS_JGF";
        NHFetch('/questionnaire/getUserCjById', 'GET', {
            zzbdhjid:zzbdhjid,
            taskcsbz:taskcsbz,
            jgfcsbz:jgfcsbz,
            xsid: xsid
        })
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        xsssruYwxx: res.data
                    });
                    this.refs.siderMenu.reloadMenuList()
                }
            });
    };


    //环节pkid
    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    getJgfsx =()=>{
        let params = {csbz: "YXXT_RXJYKS_JGF"};
        NHFetch('/zzbdCommon/getCssz', 'GET', params)
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        jgfsx:res.data
                    })
                } else {
                    message.error('未获取及格分数线，请联系管理员！');
                }
            });
    }

    startStudy =()=>{
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const taskcsbz = "YXXT_RXJYKS_TASKID";
        const ksrkcsbz = "YXXT_KSRK_HBDX";
        let params = {ksrkcsbz: ksrkcsbz,taskcsbz: taskcsbz,xsid: xsid};
        NHFetch('/questionnaire/getKsrk', 'GET', params)
            .then(res => {
                if (res && res.data) {
                    if(res.data != "-1"){
                        window.open(res.data);
                        const getUserCjById = this.getUserCjById;

                        confirm({
                            title: '是否完成入学教育考试?',
                            okText: '已完成',
                            okType: 'success',
                            cancelText: '未完成',
                            onOk() {
                                getUserCjById(true);
                            },
                            onCancel() {
                                getUserCjById(true);
                            },
                        });
                    }else{
                        message.error('你没有参与考试资格，请联系管理员！');
                    }
                } else {
                    message.error('未获取考试入口，请联系管理员！');
                }
            });


    }

    render() {
        const { xsssruYwxx,jgfsx } = this.state;

        const columns = [
            {
                title: '考试时间',
                dataIndex: 'START_TIME',
            },
            {
                title: '考试成绩',
                dataIndex: 'RESULT_SCORE',
            }
        ];

        return (
            <div styleName="page_buy">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            /*       active={1}*/
                            menuType="自助报到"
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                {this.state.jgfsx ?
                                <TitleTop title={this.state.hjmc+` （及格分数： `+this.state.jgfsx+` 分及以上）`} extra={false} />
                                :<TitleTop title={this.state.hjmc+` （未设置及格标准）`} extra={false} />}
                                <div className="p-sw">
                                    <Table
                                        bordered
                                        size="middle"
                                        pagination={false}
                                        columns={columns}
                                        dataSource={xsssruYwxx}
                                    />
                                    <div className="text-center mt-50">
                                        <Button onClick={this.startStudy}
                                                type="primary"
                                                className={"ml-20"}
                                                htmlType="submit">考试入口</Button>
                                        <NextButton nextUrl={this.state.nextUrl} before={this.checkNext}
                                                    search={this.state.search}/>
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex> : <CommonTip hjmc={"入学考试"} key={"GD023"} mkbz='GD023' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        );
    }
}
