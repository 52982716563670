import React from "react";
import { Card, Form, Button, Divider, message,Row, Col,Modal } from 'antd';
import PropTypes from 'prop-types';
import NHFetch from "../../../../utils/NHFetch";
import NHInputRender from "../../../components/common/NHInputPicker/render";
import moment from 'moment';
import {getLoginUser} from "@utils/NHCore";
import {getHashParam} from "../../../../utils/globalUtil";
import styles from './index.css';
const FormItem = Form.Item;
class Common extends React.Component {
    constructor() {
        super()
        this.state = {
            hjblztData: {}, //环节办理状态信息
            hjywxData: [], //环节业务项信息
            editable: true, //是否可编辑
            previewVisible: false,
            dmkmcList:{},
            loading:false
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('hjblztData' in nextProps) {
            const hjblztData = nextProps.hjblztData;
            if (hjblztData) {
                let editable = hjblztData.blzt == '1' ? false : true;
                this.setState({ hjblztData: hjblztData, editable: editable });
            } else {
                this.setState({ hjblztData: hjblztData, editable: true });
            }
        }
    }

    //确认办理
    handleSubmit = () => {
        this.setState({
            loading:true
        })
        let zzbdhjid = getHashParam('pkid');
        const { pkid } = this.state.hjblztData;
        const form = this.props.form;
        //校验
        form.validateFields((err, formData) => {

            if (err) {
                return;
            }

              //处理保存到数据库中的数据 
              Object.keys(formData).forEach(function(key){ 
                if(formData[key]!=null && formData[key]!=undefined){
                      if(formData[key].constructor.name == 'Array'){ 
                        if (typeof formData[key] === 'object') { 
                            let isFileUpload = false;
                            let array = [];
                            for (let item of formData[key]) {
                                if (item.hasOwnProperty('uuid') && item['uuid']) {
                                    isFileUpload = true;
                                    array.push(item['uuid']) 
                                }
                            }
                            if (isFileUpload) {
                                formData[key]= array.join(',');
                            }else{
                                formData[key] =  formData[key].join(',');
                            }
                        }else{ 
                            formData[key] =  formData[key].join(',');
                        } 
                    }  
                 }else{
                    formData[key]='';
                 } 
            });

            let userId = getLoginUser() ? getLoginUser().userId : '';
            if(!userId){
                message.error("登录已过期，请重新登录！");
                return;
            }
            let params = { xsid: userId, lchjid: pkid, formData: formData, yhid: userId ,zzbdhjid:zzbdhjid};

            NHFetch('/zdybl/saveCustomLinkData', 'POST', params)
                .then(res => {
                    if (res) {
                        if (res.data == '1') {
                            this.setState({
                                loading:false
                            })
                            message.success('环节办理成功！');
                            //办理完成回调
                            const onFinish = this.props.onFinish;
                            if (onFinish) {
                                onFinish();
                            }
                        } else {
                            this.setState({
                                loading:false
                            })
                            message.error('环节办理失败，请重试！');
                        }
                    }
                })
        });
    }

    //取消办理
    handleCancel = () => {
        const { pkid } = this.state.hjblztData;
        let zzbdhjid = getHashParam('pkid');
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let params = { xsid: xsid, lchjid: pkid ,zzbdhjid:zzbdhjid};
        NHFetch('/zdybl/cancelCustomLinkData', 'POST', params)
            .then(res => {
                if (res) {
                    if (res.data == '1') {
                        message.success('环节办理取消成功！');
                        //取消办理完成回调
                        const onFinish = this.props.onFinish;
                        if (onFinish) {
                            onFinish();
                        }
                    } else {
                        message.error('环节办理取消失败，请重试！');
                    }
                }
            })
    }

    //上一步
    handleLast = () => {
        let onLast = this.props.onLast;
        if (onLast) {
            onLast();
        }
    }

    //下一步
    handleNext = () => {
        let onNext = this.props.onNext;
        if (onNext) {
            onNext();
        }
    }
    //获取下拉列表value对应的名称
    getDmkMc = (dmbz, zdbz) => {
        NHFetch("/proData/selectDataList", "GET", { sign: dmbz })
            .then(res => {
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[zdbz] = res.data;
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }

       //获取联级下拉列表value对应的名称
       getCascaderDmkMc = (level,sign, cascaderValue,zdbz) => { 
        NHFetch("/proData/selectCascaderList", "GET", { level:level,sign: sign ,cascaderValue:cascaderValue})
            .then(res => {  
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[level+zdbz] = res.data; 
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }
      
     // 点击文件链接或预览图标时的回调
     handlePreview = (fjmc, fjid) => {  
        if (fjmc==="isImage") {
            this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };

    //打开图片弹窗
    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }
    // 关闭图片弹窗
    closeModal = () => this.setState({ previewVisible: false });
    // 文件展示
    fileLists = (fileLists) => {
        {
            let items=[];
            for(let key in fileLists){
                let fileList = fileLists[key].split(";"); 
                items.push (
                    <div>
                        <a onClick={() => {
                        this.handlePreview("file",fileList[0]);
                        }}>{fileList[1]} </a> 
                    </div>
                )
            }
        return items;
        }
    };
    //图片展示
    imageLists = (imageLists) =>{
        {
            let items=[];
            for(let key in imageLists){
                let imageList = imageLists[key].split(";");
                items.push (
                    <div>
                    <img style={{height:"86px" ,width:"86px"}} src={"anonymity/docrepo/download/file?attachmentId=" + imageList} 
                     onClick={() => {
                        this.handlePreview("isImage",imageList[0]);
                        }}/>
                    </div>
                )
            }
            return items;
        }
    }
    render() {
        const { form, hjywxData, hjblztData, hasLast, hasNext } = this.props;
        const { editable ,previewVisible,previewImage} = this.state;

        let formItems = [];
        let formItemLayout = { //表单项布局
         /*    labelCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 10 },
                lg: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 10 },
                lg: { span: 8 },
            } */
        }

        if (hjywxData && hjywxData.length > 0) {
            for (let key in hjywxData) {
                let hjywx = hjywxData[key];
                let kjcsjson = JSON.parse(hjywx.kjcs);
                let ywxz = hjywx.ywxz;
                let zdbz = hjywx.zdbz;
                let formItem = "";  
                if (editable) {
                    formItem = (
                        <div styleName='kzxx_item_textarea'>
                            <div styleName="kzxx_label"><span style={{ color: 'red',display:hjywx.sfbt == '1' ? '' : 'none' }}>*</span>{hjywx.zdmc}：</div>
                            <div styleName="kzxx_name_textarea">
                            <NHInputRender id={hjywx.pkid}
                                label=''
                                defaultValue={hjywx.mrz}
                                value={kjcsjson.validationValueType === 'array' ? (ywxz && typeof ywxz == 'string'? ywxz .split(",") : ywxz ? ywxz:[]) : ywxz}
                                required={hjywx.sfbt == '1' ? true : false}
                                form={form}
                                feature={hjywx.kjcs}
                                formItemLayout={formItemLayout} />
                            </div>
                        </div> 
                    );
                } else {  
                    if(kjcsjson.type === 'datepicker'||kjcsjson.type === 'datetimepicker'||kjcsjson.type === 'timepicker'||kjcsjson.type === 'monthpicker'||kjcsjson.type === 'rangepicker'){    //日期值展示阶段，从moment转为文字 
                      
                        if(ywxz){
                            ywxz = moment(ywxz).format(kjcsjson.format);  
                        }
                    }else if (kjcsjson.type === "select" ) { 
                        if (kjcsjson.dataTag && !this.state.dmkmcList[zdbz]) {
                            this.getDmkMc(kjcsjson.dataTag, zdbz);
                        }
                        let list = this.state.dmkmcList[zdbz];
                        list && list.map(item => {
                            if (item.VALUE === ywxz) {
                                ywxz = item.LABEL;
                            }
                        })
                    }else if (kjcsjson.type === "multiselect") { 
                        if (kjcsjson.dataTag && !this.state.dmkmcList[zdbz]) {
                            this.getDmkMc(kjcsjson.dataTag, zdbz);
                        }
                        let list = this.state.dmkmcList[zdbz]; 
                        if(ywxz){  
                            let multiselect=  typeof ywxz == 'string'? ywxz .split(","): ywxz;
                            let multiselectList=[];
                            for(let i in multiselect){
                                list && list.map(item => {
                                    if (item.VALUE === multiselect[i]) {
                                        multiselectList.push(item.LABEL);
                                    }
                                })
                            }
                            ywxz=multiselectList.join(",");
                     }
                    }else if (kjcsjson.type === "nhcascader"&& ywxz ) {    
                            let nhcascaderList=typeof ywxz == 'string'? ywxz .split(","): ywxz;  
                            let nhcascaderLists=[];    
                            for(let i in nhcascaderList){  
                                let level=i;
                                let c=i;
                                let d=i;
                                let a=++level;
                                let b=--d;
                                let flag = false;
                                if (kjcsjson.dataTag && !this.state.dmkmcList[a+zdbz] ) {
                                    if(i == "0"){
                                        this.getCascaderDmkMc(a,kjcsjson.dataTag,'',zdbz) 
                                    }
                                    else{
                                        this.getCascaderDmkMc(a, kjcsjson.dataTag,nhcascaderList[b],zdbz) 
                                    } 
                                }
                                    let list=this.state.dmkmcList[level+++zdbz];
                                    
                                    // 第一次循环，判断下拉选中的值是否在dmkmcList，如果不在，还需要重新请求getCascaderDmkMc接口
                                    list && list.map(item => {   
                                        if (item.value === nhcascaderList[c]) { 
                                            flag = true
                                        }
                                    }) 
                                    if(!flag){
                                        // this.changeIsReload(nhcascaderList[b]);
                                        if(i == "0"){
                                            this.getCascaderDmkMc(a,kjcsjson.dataTag,'',zdbz) 
                                        }
                                        else{
                                            this.getCascaderDmkMc(a, kjcsjson.dataTag,nhcascaderList[b],zdbz) 
                                        } 
                                    }
                                    
                                    list && list.map(item => {   
                                        if (item.value === nhcascaderList[c]) { 
                                            nhcascaderLists.push(item.label);
                                        }
                                    }) 
                            }
                            ywxz=nhcascaderLists.join("/"); 
                     }else if (kjcsjson.type === "switch"&& ywxz ) {
                        ywxz = (ywxz === "true") ? kjcsjson.checkedText : kjcsjson.uncheckedText;
                    } else if (kjcsjson.type === "radio"&& ywxz ) {
                        kjcsjson.options && kjcsjson.options.map((item, keys) => {
                            if (item.value === ywxz) {
                                ywxz = item.label;
                            }
                        })
                    }else if(kjcsjson.fileType === "file" && ywxz ){  
                        
                        let fileLists = typeof ywxz == 'string' ? ywxz.split(",") : ywxz;   
                        ywxz=(
                            this.fileLists( fileLists)  
                        )
                    }else if(kjcsjson.fileType === "image" && ywxz){
                        let imageLists = typeof ywxz == 'string' ? ywxz.split(",") : ywxz; 
                        if (typeof ywxz === 'object') { 
                            let array = [];
                            for (let item of ywxz) {
                                if (item.hasOwnProperty('uuid') && item['uuid']) {
                                    array.push(item['uuid']) 
                                }
                            }
                            imageLists= array
                        } 
                        ywxz=(
                            this.imageLists(imageLists)  
                        )   
                    }
                    formItem = 
                    <FormItem {...formItemLayout} label={hjywx.zdmc}>
                    <span>{ywxz}</span>
                    </FormItem> 
                }
                formItems.push(formItem);
            } 
        } else {
            let formItem = (<span>暂无需要填写的业务项信息</span>);
            formItems.push(formItem);
        }
        return (
            <div>
                <Card type="inner" title={hjblztData && hjblztData.hjmc ? `${hjblztData.hjmc}业务信息` : '业务信息'}>
                    <Form>
                        {formItems}
                    </Form>
                </Card>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.closeModal}
                >
                <img
                    alt="example"
                    style={{ width: '100%' }}
                    src={previewImage}
                />
                </Modal>
                <div style={{position:'relative',textAlign:'center'}}>
                {
                    editable ?
                        <Button  type="primary" style={{position: 'absolute',top: '74px',left: '308px'}}
                           onClick={this.handleSubmit} loading={this.state.loading}>确认办理</Button>
                        :<Button type="primary" style={{position: 'absolute',top: '74px',left: '308px'}}
                                 onClick={this.handleCancel}>取消办理</Button>
                }
                </div>
            </div>
        )
    }
}

const common = Form.create()(Common);

Common.propTypes = {
    xsData: PropTypes.object, //学生信息
    hjblztData: PropTypes.object, //环节办理状态数据
    hjywxData: PropTypes.array, //环节业务项数据
}
export default common