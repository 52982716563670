import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Row, Col, Form, DatePicker, Input, InputNumber, Button, Select, Table, Icon, TimePicker, Upload, Spin, Modal } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import { message } from "antd/lib/index";
import moment from 'moment';
import NHSelect from "@components/common/NHSelect";
import { createUuid, getLoginUser } from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
// import 'moment/src/locale/zh-cn';
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const Dragger = Upload.Dragger;
import NHUpload from '../../../components/common/NHUpload';
import NHModal from "@components/common/NHModal";
import TextArea from 'antd/lib/input/TextArea';

const history = createHistory();
const format = 'HH:mm';


const basicUrl = "/zzbd";
const commonUrl = '/zzbdCommon';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class JfxxHzczy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_8',
            hjmc: '',
            editable: true,
            update: false,
            initPOvalue: {
                nd: '',
                xsid: '',
                sfasbd: '',
                basbdyy: '',
                jtgj: '',
                ddz: '',
                dxsj: '',
                bc: '',
                ptrs: 0
            },
            value: null,
            options: [],
            dqddz: '',
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            previewVisible: false,
            nonOperationalData: {},//不可操作的数据
            isHide: false,
            sfjf: '',//是否缴费
            zswfjfyy: '',// 暂时无法缴费原因
            visibleEwm: false, //上传附件
            jfzmid: '',
            pkzmid: '',//说明书id

        };
    }


    componentWillMount() {

    }

    //渲染前获取信息
    componentDidMount() {
        this.queryQztjAndYlhj();
        this.getQkpo();
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            console.log('hjdm: ' + hjdm)
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                console.log('gdurl:' + gdUrl)
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    getQkpo() {
        const xsid = getLoginUser().userId;
        NHFetch(basicUrl + "/getHzczyJfxxpoByXsidAndNd", "GET", { xsid: xsid })
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({ initPOvalue: res.data, editable: false });
                        this.setState({
                            isHide: res.data.sfjf != 1,
                            sfjf: res.data.sfjf,
                            jfpzid: res.data.jfpzid,
                            pkzmid: res.data.pkzmid,
                            zswfjfyy: res.data.zswfjfyy
                        });
                    }
                }
            });
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            let sUrl = '/saveHzczyJfxxData';
            if (this.state.update) {
                sUrl = '/updateHzczyJfxxData';
            }
            //修改 可编辑状态，并可以update数据
            if (!this.state.editable) {
                this.setState({ update: true, editable: true });
                return
            }

            const xsid = getLoginUser().userId;
            const zzbdhjid = this.getUrlParams("pkid");

            let params = { xsid: xsid, zzbdhjid: zzbdhjid, formData: values };


            //请求保存或修改数据
            NHFetch(basicUrl + sUrl, 'POST', params)
                .then(res => {
                    if (res) {
                        if (res.data == '1') {
                            message.success('信息保存成功！');
                            this.reloadMenu();
                            this.getQkpo();
                        } else {
                            message.error('信息保存失败，请重试！');
                        }
                    }
                })
        });
    };

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };


    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        NHFetch(basicUrl + "/getDmkMc", "GET", { dmbz: dmbz })
            .then(res => {
                if (res) {
                    if (res.data) {
                        // if (dmbz === "YXWZ_LXJTGJ") {
                        //     this.setState({ lxjtgjmcList: res.data });
                        // }
                    }
                }
            });
    }

    //动态显示报到情况填写
    onSfjfChange = (value) => {
        this.setState({
            isHide: value != '1',
            sfjf: value,
            sfxzjfShow: '0'
        });
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //点击上传证明材料 
    handleBtnClick = () => {
        this.setState({
            visibleEwm: true
        });
    }

    // 关闭模态框
    handleCancel = () => {
        this.setState({
            visibleEwm: false
        });
    };

    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => {
        console.log('photo pop:', fjid)
        if (fjmc === "isImage") {
            this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };

    //打开图片弹窗
    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }
    // 关闭图片弹窗
    closeModal = () => this.setState({ previewVisible: false });

    //图片展示
    imageLists = (imageLists) => {
        {
            let items = [];
            for (let key in imageLists) {
                let imageList = typeof imageLists[key] == 'string' ? imageLists[key].split(";") : imageLists[key];
                items.push(
                    <span>
                        <img style={{ height: "80px", width: "80px" }} src={"anonymity/docrepo/download/file?attachmentId=" + imageList}
                            onClick={() => {
                                this.handlePreview("isImage", imageList[0]);
                            }} />
                    </span>
                )
            }
            return items;
        }
    }

    // open = () => {
    //     this.refs.nhModal.show();  //通过ref调用已经封装好的show方法显示
    // }
    handleOk = () => {
        console.log("确认回调方法")
    }
    onCancel2 = () => {
        console.log("取消回调事件")
    }

    render() {

        const { getFieldDecorator } = this.props.form;
        const { editable, initPOvalue, isHide, zswfjfyy, jfpzid, pkzmid } = this.state;
        return (
            <div>{this.state.operable ?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        setMenuList={this.setMenuList}
                        title="自助报到"
                        /* active={3}*/
                        mkbz={this.state.mkbz}
                        menuType='自助报到'
                    />
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint />
                            <TitleTop title={this.state.hjmc} extra={false} />
                            <Form onSubmit={this.handleSubmit}>
                                <div className="p-sw">
                                    <Flex align="start" wrap="wrap">

                                        <div className="form_item">
                                            <FormItem
                                                {...formItemLayout}
                                                label="是否已缴费"
                                            >
                                                {getFieldDecorator('sfjf', {
                                                    initialValue: initPOvalue.sfjf, placeholder: '请选择（必选）',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                '请选择是否已缴费'
                                                        }
                                                    ]
                                                })(
                                                    editable ?
                                                        <Select placeholder="请选择（必选）" onChange={this.onSfjfChange}>
                                                            <Option value="1">
                                                                是
                                                            </Option>
                                                            <Option value="0">
                                                                否
                                                            </Option>
                                                        </Select> : <span>{initPOvalue.sfjf == '1' ? '是' : '否'}</span>
                                                )}
                                            </FormItem>
                                        </div>

                                        
                                            <div className="form_item">
                                            </div>
                                        
                                        {!isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="上传缴费凭证"
                                                >
                                                    {getFieldDecorator('jfpzid', {
                                                        initialValue: jfpzid,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请上传缴费凭证'
                                                            }
                                                        ]
                                                    })
                                                        (
                                                            <NHUpload
                                                                serviceMark={"ly-sm-yxwz-svc"}
                                                                moduleName={"缴费凭证"}
                                                                multiple={true}
                                                                value={jfpzid}
                                                                fileCountLimit={3}
                                                                isEdit={editable}
                                                                defaultEffective={true}
                                                                fileTypeLimit={['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG']}
                                                                onChange={(value) => {
                                                                    this.setState({ jfpzid: value })
                                                                }}
                                                            />
                                                        )}

                                                </FormItem>
                                            </div>
                                        }
                                        {isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="暂时无法缴费原因"
                                                >
                                                    {getFieldDecorator('zswfjfyy', {
                                                        initialValue: zswfjfyy,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请填写无法缴费原因'
                                                            }
                                                        ]
                                                    })(
                                                        editable ? <TextArea rows={3} placeholder="请填写无法缴费原因" /> :
                                                            <span>{zswfjfyy ? (zswfjfyy.length > 15 ? zswfjfyy.slice(0, 15) + "......" : zswfjfyy) : ''}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {isHide &&
                                            <div className="form_item">
                                            </div>
                                        }

                                        {isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="上传贫困证明"
                                                >
                                                    {getFieldDecorator('pkzmid', {
                                                        initialValue: pkzmid,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请上传缴费凭证'
                                                            }
                                                        ]
                                                    })
                                                        (
                                                            <NHUpload
                                                                serviceMark={"ly-sm-yxwz-svc"}
                                                                moduleName={"贫困证明"}
                                                                multiple={true}
                                                                value={pkzmid}
                                                                fileCountLimit={5}
                                                                isEdit={editable}
                                                                defaultEffective={true}
                                                                fileTypeLimit={['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG']}
                                                                onChange={(value) => {
                                                                    this.setState({ pkzmid: value })
                                                                }}
                                                            />
                                                        )}

                                                </FormItem>
                                            </div>
                                        }
                                    </Flex>
                                    <div className="text-center mt-50">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {editable ? '保存' : '修改'}
                                        </Button>
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Flex.Item>
                </Flex>
                :
                <CommonTip hjmc={"缴费信息"} key={'GD008_8'} mkbz='GD008_8' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        );
    }
}
