import React from 'react';
import {Button,message} from 'antd';
import createHistory from 'history/createHashHistory';
import {getLoginUser} from "@utils/NHCore";
import NHFetch from "../../../../utils/NHFetch";
const history = createHistory();

export default class NextIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bddSchool:'',
        };
    }

    isLogin=()=>{
        let userId = getLoginUser() ? getLoginUser().userId : '';
        if(!userId){
            message.warning("未获取登录信息。");
            history.push({
                pathname:'/index',
            });
            return false;
        }
        return true;
    }

    //获取报到单学校模板参数设置
    getBddCssz = () => {
        const {nextUrl,search} = this.props;
        NHFetch('/zzbdCommon/getCssz', 'GET', { csbz: 'YXXT_BDD_SCHOOL' }).then(res => {
            if (res ) {
                if(res.data){
                    console.log('res.data', res.data)
                    this.setState({ bddSchool: res.data },()=>{
                        this.next(nextUrl,search);
                    });
                }else{
                    this.setState({ bddSchool: 'lydx' },()=>{
                        this.next(nextUrl,search);
                    });
                }
            }else{
                this.setState({ bddSchool: 'lydx' },()=>{
                    this.next(nextUrl,search);
                });
            }
        })
    }

    next=(nextUrl,search)=>{
        const bddSchool = this.state.bddSchool;
        if(this.isLogin()){
            history.push({
                    pathname:nextUrl=='/print' ? '/' + bddSchool + 'Index': nextUrl,
                    search:search,
                }
            );
        }
    }

    render() {
        const {nextUrl,search} = this.props;
        return (
            nextUrl?<Button onClick={()=>{ nextUrl=='/print' ?this.getBddCssz() : this.next(nextUrl,search)}} className="ml-20" type="primary">
                下一步
            </Button>:""
               /* <Button className="ml-20" type="primary"> 完成  </Button>*/
        );
    }
}
