import React from 'react';
import { Result } from 'antd';
import QRCode from 'qrcode.react';

export default class QrCodeIndex extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount(){

    }

    render() {
        const { qrCode } = this.props;
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                <div className="result-example">
                    <QRCode className="spe" value={qrCode} renderAs='canvas'
                            style={{
                                height: '160px',
                                width: '160px',
                                padding:'1.0rem'
                            }}
                    />
                    <p style={{fontSize:'18px'}}>请扫描二维码进行缴费</p>
                </div>
                <style jsx="true" global="true">
                    {`
                          .result-example .spe {
                            width: 160px;
                            height: 160px;
                          }
                         .am-result .am-result-pic{
                            width: 160px;
                            height: 160px;
                         }
                        
                    `}
                </style>
            </div>
        );
    }
}
