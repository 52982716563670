import React from 'react';
import  { message,Table,Button,Modal } from 'antd';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import { getLoginUser } from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";
import CommonTip from "@pages/SelfHelp/common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import SiderMenu from "@pages/Assembly/siderMenu";
import {Flex} from "@components/index";
import Hint from "@pages/SelfHelp/common/hint";
import TitleTop from "@pages/Assembly/titleTop";
import NextButton from "@pages/SelfHelp/common/nextIndex";
import PayModal from './pay';

export default class NonTaxPayIndex extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD0081',
            hjmc: '',
            feeDetailList:[],  //缴费项目明细list
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            merchantInfo:{},  //收费商户信息
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            visible:false,
            params:{
                jfbh:'',    //缴费编号（即通知书编号）
                xmmc:'',    //项目名称
                qfje:0,     //欠费金额
            }
        }
    }

    componentDidMount(){
        this.queryQztjAndYlhj((operable) => {
            if (operable) {
                //办理状态根据 关联现场环节和是否反向关联修改 不根据现场状态
                // this.updateZzbdBlzt();
            }
        });
        this.loadMerchantInfo();
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }else if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }else if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }else  if (nohj) {
                        this.setState({ operable: false });
                    }else{
                        this.loadFeeDetailList();
                    }if (callback) {
                        callback(this.state.operable);
                    }
                }
            });
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        }, () => {
            this.loadFeeDetailList();
        });
    };

    loadFeeDetailList =() =>{
        if (getLoginUser().userId){
            NHFetch('/jfxt/getFeeDetailList','GET').then(res =>{
                if (res && res.code ===200){
                    this.setState({
                        feeDetailList:res.data
                    })
                }
            })
        } else {
            message.error("获取不到登录人信息");
        }
    }

    loadMerchantInfo =() =>{
        NHFetch('/jfxt/getJfcsInfo','GET').then(res =>{
            if (res && res.data) {
                this.setState({
                    merchantInfo: res.data,
                });
            }
        })
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    handlePaySubmit =(recode)=>{
        const { JFBH,SFXMMC,QFJE } = recode;
        this.setState({
            visible:true,
            params:{
                jfbh:JFBH,
                xmmc:SFXMMC,
                qfje:QFJE
            }
        },()=>{this.saveOrderInfo()})
    }

    saveOrderInfo =() =>{
        const { jfbh } = this.state.params;
        NHFetch('/jfxt/saveOrderInfo','GET',{jfbh:jfbh}).then(res =>{
            if (res && res.code ===200){
                if (res.data === 1){
                    return null;
                } else {
                    message.error("新增订单失败，请稍后再试！")
                }
            }else {
                message.error("新增订单失败，请稍后再试！")
            }
        })
    }

    handleShowPaper =(recode) =>{
        const { JFBH,QFJE } = recode;
        const { merchantInfo } = this.state;
        if (QFJE >0){
            message.error("该项目费用未缴完，无法查看缴费票据！");
        } else {
            NHFetch('/jfxt/getBillAddress','GET',{jfbh:JFBH}).then(res =>{
                if (res && res.data){
                    let billInfo = res.data;
                    if (billInfo.success){
                        window.open(`${billInfo.publicUrl}${billInfo.path}`,"_blank");
                    } else {
                        message.error("获取票据失败，请稍后再试！")
                    }
                } else {
                    message.error("系统异常，请稍后再试！")
                }
            })
        }
    }

    render() {
        const { feeDetailList,merchantInfo,params } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'ROW_ID'
            },
            {
                title: '收费项目',
                dataIndex: 'SFXMMC',
            },
            {
                title: '应缴金额（元）',
                dataIndex: 'YSJE',
            },
            {
                title: '实收金额（元）',
                dataIndex: 'SSJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '欠费金额（元）',
                dataIndex: 'QFJE',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            },
            {
                title:'操作',
                key:'operation',
                render:(text, record)=>{
                    return(
                        <div>
                            <Button style={{background:'rgb(1, 176, 241)',color:'white'}} onClick={()=>this.handlePaySubmit(record)}>在线缴费</Button>
                            <Button style={{marginLeft:'10px',background:record.QFJE >0 ? '#7a7a90':'rgb(1, 176, 241)',color:'white'}} onClick={()=>this.handleShowPaper(record)}>查看票据</Button>
                        </div>
                    )
                }
            }
        ];

        return (
            <div className="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            /*active={7}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw mt-20">
                                    <Table
                                        bordered
                                        size="middle"
                                        pagination={false}
                                        columns={columns}
                                        dataSource={feeDetailList}
                                    />
                                    <div className="text-center mt-50">
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"网上缴费（非税平台）"} key={'GD0081'} mkbz='GD0081' nonOperationalData={this.state.nonOperationalData} />}
                    <Modal
                        title={params.xmmc+"支付界面"}
                        visible={this.state.visible}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                关闭
                            </Button>]
                        }
                    >
                        <PayModal merchantInfo={merchantInfo} params={params}/>
                    </Modal>
            </div>
        );
    }

}